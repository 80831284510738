
import { createSlice } from "@reduxjs/toolkit";

// utils
import axios, { baseUrl } from '../../../utils/axios';

//
import { dispatch } from '../../store';

import { showSnackbar } from '../snackbar'


const userID = localStorage.getItem('userID')
const authID = localStorage.getItem('authID')



const initialState = {
  isLoading: false,
  keyLoading: false,
  error: null,
  contactDataList: {},
  contactDataDetails: {},
  contactDeleteResponse: {},
  contactCustomFieldData: {},
  contactCustomFieldPostData: {},
  contactCustomFieldPutData: {},
  contactCustomFieldDeleteResponse: {},
  contactTagTableResponse: {},
  callsConversationData: {},
  contactSearchData: {},
  statusResponse: {},
  updateColSettingsResponse: {},
  getDetailsByNumberResponse: {},
  getSearchByNameResponse: {},
  keyAvailabilityResponse: {},
  contactsDeleteManyResponse:{},
  bulkAssignUserResponse: {},
  contactFilterResponse: {},
  filterLoading: false,

}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,

  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING
    startFilterLoading(state) {
      state.filterLoading = true;
    },
    // START LOADING
    stopFilterLoading(state) {
      state.filterLoading = false;
    },
    startKeyLoading(state) {
      state.keyLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
            
    },
        
    // GET all contacts
    getAllContactsSuccess(state, action) {
      state.contactDataList = action.payload;
      state.isLoading = false;
    },

    // contact details
    getContactsDetailSuccess(state, action) {
      state.contactDataDetails = action.payload;
      state.isLoading = false;
    },

    // delete contact
    deleteContactSuccess(state, action) {
      state.contactDeleteResponse = action.payload;
      state.isLoading = false;
    },

    // post custom fields
    postCusomFieldsSuccess(state, action) {
      state.contactCustomFieldPostData = action.payload;
      state.isLoading = false;
    },

    // put custom fields
    putCusomFieldsSuccess(state, action) {
      state.contactCustomFieldPutData = action.payload;
      state.isLoading = false;
    },

    // get all custom fields
    getAllCusomFieldsSuccess(state, action) {
      state.contactCustomFieldData = action.payload;
      state.isLoading = false;
    },

    // put tag
    putTagSuccess(state, action) {
      state.contactTagTableResponse = action.payload
      state.isLoading = false;
    },

    // put assign bulk
    putBulkAssignSuccess(state, action) {
      state.bulkAssignUserResponse = action.payload
      state.isLoading = false;
    },

    // put status
    putStatusSuccess(state, action) {
      state.statusResponse = action.payload
      state.isLoading = false;
    },

    // delete contact
    deleteCustomFieldSuccess(state, action) {
      state.contactCustomFieldDeleteResponse = action.payload;
      state.isLoading = false;
    },

    contactDeleteManySuccess(state, action){
      state.contactsDeleteManyResponse = action.payload,
      state.isLoading= false
    },

    // contact details
    getConversationCalls(state, action) {
      state.callsConversationData = action.payload;
      state.isLoading = false;
    },

    // GET Contact search.
    getConatactSearchSuccess(state, action) {
      state.contactSearchData = action.payload;
      state.isLoading = false;
    },

    updateColSettingsSuccess(state, action){
      state.updateColSettingsResponse = action.payload
      state.isLoading = false
    },

    getContactsDetailByNumberSuccess(state,action){
      state.getDetailsByNumberResponse = action.payload
      state.isLoading = false
    },

    getConatactByNameSuccess(state,action){
      state.getSearchByNameResponse = action.payload
      state.isLoading = false
    },

    getKeyAvailabilityResponse(state,action){
      state.keyAvailabilityResponse = action.payload
      state.keyLoading = false
    },

    filterSuccess(state,action){
      state.contactFilterResponse = action.payload
      state.filterLoading = false
    }
    


  }
})



export const selectAllContactsData = (state) => state.contacts;

export default contactsSlice.reducer


// Actions

export function getAllContacts(offsetCount, rowsPerPage) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contacts?limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
      dispatch(contactsSlice.actions.getAllContactsSuccess(response.data));
      console.log('get All Contacts Success', response)
 
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
    }
  };
}

export function getContactDetail(id) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contacts/${id}`);
      dispatch(contactsSlice.actions.getContactsDetailSuccess(response.data));
      console.log('get Contacts Detail Success', response)

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));

    }
  };
}

export function getContactDetailByNumber(number) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contacts?phoneNumber=${number}`);
      dispatch(contactsSlice.actions.getContactsDetailByNumberSuccess(response.data));
      console.log('get Contacts Detail Success', response)

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));

    }
  };
}

export function editContact(payload, contact_id) {
  return new Promise((resolve, reject) => {
      axios({
        method: "PUT",
        url: `${baseUrl}/contacts/${contact_id}`,
        headers: {
          authorization: localStorage.getItem("redux-token"),
          "Content-Type": "multipart/form-data"
        },
        data: payload
      }).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      })
  })
}

export function deleteContact(id) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.delete(`/contacts/${id}`);
      dispatch(contactsSlice.actions.getContactsDetailSuccess(response.data));
      console.log('get delete Success', response)
      dispatch(getAllContacts(0,10))
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));

    }
  };
}

export function deleteContactMany(data) {

  dispatch(showSnackbar('Delete on Progress'));

  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.post(`/contacts/deleteMany`, data);
      dispatch(contactsSlice.actions.contactDeleteManySuccess(response.data));
      console.log('delete All Success',response.data.data, response.data.status)
      if (response.data.status === true) {
        
        dispatch(showSnackbar(`Hurray.. ${response.data.data} Contacts Successfully Deleted`));
      } else {
        dispatch(showSnackbar('Oops.. Deleting Failed'));
      }
      dispatch(getAllContacts(0, 10))
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));

    }
  };
}


export function postCustomFields(data) {

  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.post(`/contact/custom_field`, data);
      dispatch(contactsSlice.actions.postCusomFieldsSuccess(response.data));
      console.log('Cusom Fields redux response', response)
      if (response.data.status === true) {
        dispatch(showSnackbar('Hurray.. Field Successfully Added'));
      } else {
        dispatch(showSnackbar('Oops.. Field Adding Failed'));
      }
      dispatch(getAllCustomFields(authID, userID))
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Field Adding Failed'));
    }
  };
}

export function putCustomFields(id, data) {
 
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.put(`/contact/custom_field/${id}`, data);
      dispatch(contactsSlice.actions.putCusomFieldsSuccess(response.data));
      console.log('Cusom Fields redux response', response)
      if (response.data.status === true) {
        dispatch(showSnackbar('Hurray.. Field Successfully Updated'));
      } else {
        dispatch(showSnackbar('Oops.. Field Update Failed'));
      }
      dispatch(getAllCustomFields(authID, userID))
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Field Update Failed'));
    }
  };
}

        
export function updateTag(id, data) {

  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.patch(`/contacts/${id}`, data);
      dispatch(contactsSlice.actions.putTagSuccess(response.data));
      console.log('Cusom Fields redux response', response)
      if (response.data.status === true) {
        dispatch(showSnackbar('Hurray.. Tag Successfully Updated'));
      } else {
        dispatch(showSnackbar('Oops.. Tag Update Failed'));
      }
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Tag Update Failed'));
    }
  };
}

export function updateBulkAssignUser(id, data) {

  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.patch(`/contacts/${id}`, data);
      dispatch(contactsSlice.actions.putBulkAssignSuccess(response.data));
      console.log('Bulk Assign response', response)
      if (response.data.status === true) {
        dispatch(showSnackbar('Hurray.. Bulk Assign Successfully Updated'));
      } else {
        dispatch(showSnackbar('Oops.. Bulk Assign Update Failed'));
      }
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Bulk Assign Update Failed'));
    }
  };
}

export function updateStatus(id, data) {


  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.patch(`/contacts/${id}`, data);
      dispatch(contactsSlice.actions.putStatusSuccess(response.data));
      console.log('status redux response', response)
      if (response.data.status === true) {
        dispatch(showSnackbar('Hurray.. Status Successfully Updated'));
      } else {
        dispatch(showSnackbar('Oops.. Status Update Failed'));
      }
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Status Update Failed'));
    }
  };
}



export function getAllCustomFields(accountSid, id, rowsPerPage, offsetCount) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      var link = `/contact/custom_field?AccountSid=${accountSid}&user_id=${id}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`;
      if (rowsPerPage === undefined || offsetCount === undefined || rowsPerPage === null || offsetCount === null) {
        link = `/contact/custom_field?AccountSid=${accountSid}&user_id=${id}&sort=-_id`
      }
      const response = await axios.get(link);
      dispatch(contactsSlice.actions.getAllCusomFieldsSuccess(response.data));
      console.log('get Contacts Detail Success', response);

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));

    }
  };
}


export function deleteCustomField(id) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.delete(`/contact/custom_field/${id}`);
      dispatch(contactsSlice.actions.deleteCustomFieldSuccess(response.data));
      console.log('deleteCustomField Success', response)
      dispatch(getAllCustomFields(authID, userID))
      if (response.data.status === true) {
        dispatch(showSnackbar('Hurray.. Field Successfully Deleted'));
      } else {
        dispatch(showSnackbar('Oops.. Field Delete Failed'));
      }
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Field Delete Failed'));
    }
  };
}


export function getCalls(number, rowsPerPage, offsetCount) {
  console.log('getCalls contacts redux', number, rowsPerPage, offsetCount)
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contact/cdr?contactNumber=${number}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
      dispatch(contactsSlice.actions.getConversationCalls(response.data));
      console.log('getCalls contact Success', response)

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));

    }
  };
}


export function SearchCalls(number, rowsPerPage, offsetCount) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contacts?phoneNumber=${number}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
      dispatch(contactsSlice.actions.getConatactSearchSuccess(response.data));
      console.log('getContact Success', response)

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      console.log('getIvrVoiceSearch trigger error', error)
    }
  };
}

export function SearchCallsByName(name, rowsPerPage, offsetCount) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contacts?fullName=${name}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
      dispatch(contactsSlice.actions.getConatactByNameSuccess(response.data));
      console.log('getContact Success', response)

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      console.log('getIvrVoiceSearch trigger error', error)
    }
  };
}


export function updateColSettings(id, dragData, showData) {
  console.log('update drag redux request', dragData, showData)
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.put(`/users/col/${id}`, {"contactColumnSettings": dragData, "contactColumn": showData} );
        dispatch(contactsSlice.actions.updateColSettingsSuccess(response.data));
        console.log('update drag redux', response)
        if(response.data.status === true){
          dispatch(showSnackbar('Column State Saved'));
        }else{
          dispatch(showSnackbar('Oops.. Trouble to Save Change'));
        }
       
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Trouble to Save Change'));
    }
  };
}

export function getKeyAvailability(key) {
  return async () => {
    dispatch(contactsSlice.actions.startKeyLoading());
    try {
      const response = await axios.get(`/contacts/custom_field/isKeyExist?keyName=${key}`);
      dispatch(contactsSlice.actions.getKeyAvailabilityResponse(response.data));
      console.log('getKeyAvailability Success', response)

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      console.log('getKeyAvailability error', error)
    }
  };
}


export function contactFilter(assignUser,status, rowsPerPage, offsetCount, tags, custom_variable) {
  return async () => {
    dispatch(contactsSlice.actions.startFilterLoading());
    var url = `/contacts?AssignUser=${assignUser}&isActive=${status}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id&customVariable=${JSON.stringify(custom_variable)}`;
    if (custom_variable === undefined || custom_variable === null) {
      url = `/contacts?AssignUser=${assignUser}&isActive=${status}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`;
    }
    try {
      const response = await axios.get(url);
      dispatch(contactsSlice.actions.filterSuccess(response.data));
      console.log('contactFilter Success', response)

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      dispatch(contactsSlice.actions.stopFilterLoading());
      console.log('getIvrVoiceSearch trigger error', error)
    }
  };
}


export const { 
    
  stopFilterLoading,
  
} = contactsSlice.actions
