import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import isTokenReducer from './slices/token';
import inboxCommentsReducer from './slices/Inbox/comments'
import loginReducer from './slices/login'
import whatsappSocketReducer from './slices/WhatsappSocketSlice';
import buyNumberReducer from './slices/numbers/buy'
import manageNumberReducer from './slices/numbers/manage';
import { userReducer } from './userReducer';
import cancelledNumberReducer from './slices/numbers/cancelled'
import getAuthReducer from './slices/getAuth'
import snackbarReducer from './slices/snackbar'
import whatsappConversationListSlice from './slices/WhatsappConversationListSlice'
import ivrStudioReducer from './slices/IvrStudio'
import audioPackageReducer from './slices/audiopackage'
import inboxVoiceReducer from './slices/Inbox/voice'
import makeCallReducer from './slices/cloudPhone/makeCall'
import phoneNumberReducer from './slices/cloudPhone/phoneNumber'
import fromNumberReducer from './slices/cloudPhone/setFromNumber'
import clickToCallReducer from './slices/cloudPhone/ClickToCall'
import renderCounterReducer from "./slices/cloudPhone/renderCounter";
import contactsReducer from './slices/contacts/contacts';
import dashboardReducer from './slices/dashboard/dashboardCallDurationStats';
import companyReducer from './slices/company'
import tagsReducer from './slices/tags'
import voiceMailReducers from './slices/voicemail/voicemailbox'
import fileimageupload from './fileimageupload';
import fileuploadpathSlice from './uploadpath';
import fileuploadnameSlice from './uploadname'
import fileuploadpreviewSlice from './uploadpreview';
import fileuploadsizeSlice from './uploadsize';
import fileuploadtypeSlice from './uploadtype';
import contactsGroupReducer from './slices/contacts/contactGroup'
import fileuploadmodifySlice from './uploadmodified'
import fileuploadlastmodifySlice from './uploadlastmodify'
import userRoleSlice from './slices/profile/role'

import ChannelchooseSlice from './Channelchoose'
import ChannelinboxSlice from './CreateChannelInbox'
import ChanneladduserSlice from './ChannelAdduser'
import voicenumberSlice from './Voicenumber'
import refreshinboxslice from './Refreshinbox'
import Selectedbuttonslice from './Selectbutton'
import Selectedvoicenumberslice from './Selectedvoicenumber'
import whatsappnumberSlice from './Whatsappnumber'
import whatsappnumberidSlice from './Whatsappnumberid'
import whatsappauthkeySlice from './Whatsappauthkey'
import whatsappwabaidSlice from './Whatsappwabaid'
import smssenderidslice from './SmsSenderId'
import smspeidslice from './Smspeid'
import UserstatusSlice from './User_status'
import userdatashowSlice from './UserDatashow'
import useralldatashowSlice from './UserAllDatashow'
import inboxCommentReducer from './slices/Setting/Create'
import assignedidSlice from './Assignedid'
import CreatedbyidSlice from './CreatedbyId'
import useridcheckSlice from './Useridcheck'
import Refreshpageslice from './Refreshpage'
import telegrambottokenSlice from './Telegrambottoken'
import ViberbottokenSlice from './Viberbottoken'
import iconpickerslice from './Iconpickerimg'
import facebookacessSlice from './Facebookaceestoken'
import poweronslice from './Poweron'
import PowerdialerStatusSlice from './Powerdialerstatuschange'
import Powerdialerdata from './Powerdialerdata'
import Inboxuseridslice from './InboxUserid'
import authidSlice from './Authidslice'
import deleteidSlice from './Deleteid'
import showsnackbarSlice from './snackbarslice'
import showsnackbaroopsSlice from './showsnackoops'
import userAuthDataSlice from './slices/getAuth/userAuthData'
import inboxOnboardingSlice from './slices/Inbox/onboarding'
import ticketsSlice from './slices/Tickets/Tickets'

import whatsappSlice from './slices/whatsapp/whatsapp'
import { navigationReducer } from './navigation';
import {Ticket} from './ticket';

import TicketStatesSlice from './slices/Tickets/TicketStates'
import ContactStatesSlice from './slices/contacts/contactStates';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  ticket: Ticket,
  user: userReducer,
  navigation: navigationReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  login: loginReducer,
  product: persistReducer(productPersistConfig, productReducer),
  isToken: isTokenReducer,
  WhatsappSocketMessages: whatsappSocketReducer,
  buyNumber: buyNumberReducer,
  manageNumber: manageNumberReducer,
  getAuth: getAuthReducer,
  snackbar: snackbarReducer,
  cancelledNumber: cancelledNumberReducer,
  WhatsappConversationList: whatsappConversationListSlice,
  ivrStudio: ivrStudioReducer,
  inboxVoice: inboxVoiceReducer,
  makeCallSwitch: makeCallReducer,
  phoneNumber: phoneNumberReducer,
  fromNumber: fromNumberReducer,
  clickToCall: clickToCallReducer,
  renderCounter: renderCounterReducer,
  contacts: contactsReducer,
  dashboard: dashboardReducer,
  getCompany: companyReducer,
  tags: tagsReducer,
  voiceMail: voiceMailReducers,
  fileimageupload: fileimageupload,
  fileuploadpathSlice: fileuploadpathSlice,
  fileuploadnameSlice: fileuploadnameSlice,
  fileuploadpreviewSlice: fileuploadpreviewSlice,
  fileuploadsizeSlice: fileuploadsizeSlice,
  fileuploadtypeSlice: fileuploadtypeSlice,
  contactsGroup: contactsGroupReducer,
  fileuploadmodifySlice: fileuploadmodifySlice,
  fileuploadlastmodifySlice: fileuploadlastmodifySlice,
  userRole: userRoleSlice,
  ChannelchooseSlice: ChannelchooseSlice,
  ChannelinboxSlice: ChannelinboxSlice,
  ChanneladduserSlice: ChanneladduserSlice,
  voicenumberSlice: voicenumberSlice,
  refreshinboxslice: refreshinboxslice,
  Selectedbuttonslice: Selectedbuttonslice,
  Selectedvoicenumberslice: Selectedvoicenumberslice,
  whatsappnumberSlice: whatsappnumberSlice,
  whatsappnumberidSlice: whatsappnumberidSlice,
  whatsappauthkeySlice: whatsappauthkeySlice,
  whatsappwabaidSlice: whatsappwabaidSlice,
  smssenderidslice: smssenderidslice,
  smspeidslice: smspeidslice,
  UserstatusSlice: UserstatusSlice,
  userdatashowSlice: userdatashowSlice,
  useralldatashowSlice: useralldatashowSlice,
  inboxComments: inboxCommentReducer,
  assignedidSlice: assignedidSlice,
  CreatedbyidSlice: CreatedbyidSlice,
  useridcheckSlice: useridcheckSlice,
  inboxComments: inboxCommentsReducer,
  Refreshpageslice: Refreshpageslice,
  telegrambottokenSlice: telegrambottokenSlice,
  ViberbottokenSlice: ViberbottokenSlice,
  iconpickerslice: iconpickerslice,
  facebookacessSlice: facebookacessSlice,
  poweronslice: poweronslice,
  PowerdialerStatusSlice: PowerdialerStatusSlice,
  Powerdialerdata: Powerdialerdata,
  Inboxuseridslice: Inboxuseridslice,
  authidSlice: authidSlice,
  deleteidSlice: deleteidSlice,
  showsnackbarSlice: showsnackbarSlice,
  showsnackbaroopsSlice: showsnackbaroopsSlice,
  userAuthDataSlice: userAuthDataSlice,
  inboxOnboardingSlice: inboxOnboardingSlice,
  tickets: ticketsSlice,
  whatsapp: whatsappSlice,
  ticketStates: TicketStatesSlice,
  contactStates: ContactStatesSlice,
});

export { rootPersistConfig, rootReducer };
