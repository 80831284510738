import React from 'react';
import './callWidget.css';
import MakeCallComponent from './MakeCallComponent';
import { useSelector } from 'react-redux';

export default function CallMain(){

    const {show_cloudphone} = useSelector((state) => state.user);

    return(
        <div>
          {show_cloudphone === true ? <MakeCallComponent/> : null}
        </div>
    )
}