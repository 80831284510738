import axios from "axios";
import navConfig, { navConfigForWhatsappAdmin, navConfigOfSubUser, navConfigtrail } from "src/layouts/dashboard/navbar/NavConfig";
import { baseUrl } from "src/utils/axios";

export const SET_NAV_CONFIG = "SET_NAV_CONFIG";
export const SET_NAV_CONFIG_TRIAL = "SET_NAV_CONFIG_TRIAL";
export const SET_NAV_CONFIG_SUB_USER = "SET_NAV_CONFIG_SUB_USER";
export const SET_NAV_CONFIG_WHATSAPP_CONFIG = "SET_NAV_CONFIG_WHATSAPP_CONFIG";

const initialState = {
    navConfig: navConfig,
    navConfigtrail: navConfigtrail,
    navConfigOfSubUser: navConfigOfSubUser,
    navConfigForWhatsappAdmin: navConfigForWhatsappAdmin
}

export function navigationReducer(state = initialState, action) {
    switch(action.type) {
        case SET_NAV_CONFIG: 
           return { ...state, navConfig: action.payload };

        case SET_NAV_CONFIG_TRIAL:
            return { ...state, navConfigtrail: action.payload };

        case SET_NAV_CONFIG_SUB_USER:
            return { ...state, navConfigOfSubUser: action.payload };

        case SET_NAV_CONFIG_WHATSAPP_CONFIG:
            return { ...state, navConfigForWhatsappAdmin: action.payload };

        default:
            return state;
    }
}

export const getAuth = (token) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${baseUrl}/getAuth`,
            method: "GET",
            headers: {
                authorization: token
            }
        }).then((res) => {
            resolve(res);
        }).catch((err) => {
            reject(err);
        })
    })
}

export const list_app_config = (userID, token,app_name) => {
    return new Promise((resolve, reject) => {
        var url = `${baseUrl}/app-config?populate=cloudNumber_allow&user_id=${userID}`;
        if(app_name !== undefined){
            url=`${baseUrl}/app-config?populate=cloudNumber_allow&user_id=${userID}&app_name=${app_name}`
        }
        axios({
            method: "GET",
            url: url,
            headers: {
                authorization: token
            }
        }).then((res) => {
           resolve(res);
        }).catch((err) => {
            reject(err);
        })
    })
}