import { createSlice } from '@reduxjs/toolkit'

export const TicketStatesSlice = createSlice({
    name: 'ticketStates',
    initialState: {
        selectedContact: '',
        selectedContactNumber: '',
        selectedAssignee: {},
        selectedAssigneeData: {},
        subject: '',
        customFields: {},
        conversation: {},
        updateId: '',
        timeLine: {},
        createdAt: '',
        updatedAt: '',
        newCustom: {},
        ticketFor: 'voice',
        liveCallSId: '',
        contactName: '',
        statusExports: {},

    },
    reducers: {

        incrementSelectedContact: (state, { payload }) => {
            state.selectedContact = payload
        },

        incrementSelectedNumber: (state, { payload }) => {
            state.selectedContactNumber = payload
        },

        incrementSelectedAssignee: (state, { payload }) => {
            state.selectedAssignee = {...payload}
        },

        incrementSelectedAssigneeData: (state, { payload }) => {
            state.selectedAssigneeData = {...payload}
        },

        incrementSubject: (state, { payload }) => {
            state.subject = payload
        },

        incrementCustomFields: (state, { payload }) => {
            state.customFields = {...payload}
            console.log('incrementCustomFields payload', payload)
        },

        incrementConversation: (state, { payload }) => {
            state.conversation = {...payload}
        },

        incrementUpdateId: (state, { payload }) => {
            state.updateId = payload
        },

        incrementTimeLine: (state, { payload }) => {
            state.timeLine = {...payload}
        },

        incrementCreatedAt: (state, { payload }) => {
            state.createdAt = payload
        },

        incrementUpdatedAt: (state, { payload }) => {
            state.updatedAt = payload
        },

        incrementNewCustom: (state, { payload }) => {
            state.newCustom = {...payload}
        },

        incrementTicketFor: (state, { payload }) => {
            state.ticketFor = payload
        },

        incrementLiveCallSId: (state, { payload }) => {
            state.liveCallSId = payload
        },

        incrementContactName: (state, { payload }) => {
            state.contactName = payload
        },

        incrementStatusExports: (state, { payload }) => {
            state.statusExports = {...payload}
        },

        

       
    },
})

// Action creators are generated for each case reducer function
export const { 
    incrementSelectedContact,
    incrementSelectedAssignee,
    incrementSubject,
    incrementCustomFields,
    incrementConversation,
    incrementUpdateId,
    incrementTimeLine,
    incrementCreatedAt,
    incrementUpdatedAt,
    incrementNewCustom,
    incrementSelectedNumber,
    incrementSelectedAssigneeData,
    incrementTicketFor,
    incrementLiveCallSId,
    incrementContactName,
    incrementContactNumber,
    incrementStatusExports,
} = TicketStatesSlice.actions

export default TicketStatesSlice.reducer