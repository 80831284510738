import { createSlice } from "@reduxjs/toolkit";
import { useSnackbar } from 'notistack';
// utils
import axios, { REACT_APP_BASE_URL } from '../../../utils/axios';
//
import { dispatch, missed_call, callHistory } from '../../store';
import { getUser } from '../getAuth';
import { useSelector } from "react-redux"
import { showSnackbar } from '../snackbar'
import { SET_CALL_HISTORY, SET_MISSED_CALL, SET_NOTES } from "src/redux/userReducer";


let userID = localStorage.getItem("userID")

const initialState = {
  isLoading: false,
  isLoadingFilter: false,
  isLoadingExport: false,
  isLoadingNotes: false,
  isStartLoadingStatus: false,
  error: null,
  inboxVoiceData: {},
  inboxVoiceSearchData: {},
  inboxVoiceFilterData: {},
  contactData: {},
  addNotesResponse: {},
  updateNotesResponse: {},
  deleteNotes: {},
  updateStatusResponse: {},
  exportDataResponse: {},
  updateInboxColumn: {},
  updateInboxDragColumn: {},
  inboxtrailData: {},
  isRefresh: false,
  statusUpdateResponse: {},
  getFlowByIdResponse: {},
  filterShowExport: false,

  callDetailsDrawer: false,
  voiceId: '',

}

const inboxVoiceSlice = createSlice({
  name: 'inboxVoice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    filterShowReset(state) {
      state.filterShowExport = false
    },


    filterShowTrue(state) {
      state.filterShowExport = true
    },

    // START LOADING
    startLoadingFilter(state) {
      state.isLoadingFilter = true;
    },

    // START LOADING
    startLoadingExport(state) {
      state.isLoadingExport = true;
    },

    // START LOADING
    startLoadingNotes(state) {
      state.isLoadingNotes = true;
    },

    // START LOADING
    startLoadingStatus(state) {
      state.isStartLoadingStatus = true;
    },

    // START LOADING
    stopLoadingStatus(state) {
      state.isStartLoadingStatus = false;
    },

    // STOP LOADING
    stopLoadingFilter(state) {
      state.isLoadingFilter = false;
    },

    // STOP LOADING
    stopLoadingExport(state) {
      state.isLoadingExport = false;
    },

    // STOP LOADING
    stopLoadingNotes(state) {
      state.isLoadingNotes = false;
    },



    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;

    },



    // GET IVR VOICE
    getIvrSuccess(state, action) {
      state.inboxVoiceData = action.payload;
      state.isLoading = false;
    },

    // GET IVR search VOICE
    getIvrSearchSuccess(state, action) {
      state.inboxVoiceSearchData = action.payload;
      state.isLoading = false;
    },
    // gettrailSuccess,
    gettrailSuccess(state, action) {
      state.inboxtrailData = action.payload;
      state.isLoading = false;
    },
    // GET CONTACT USING NUMBER
    getContactSuccess(state, action) {
      state.contactData = action.payload;
      state.isLoading = false;
    },

    // PUT ADD NOTES
    addNotesSuccess(state, action) {
      state.addNotesResponse = action.payload;
      state.isLoadingNotes = false;
    },

    // PUT ADD NOTES
    deleteNotesSuccess(state, action) {
      state.deleteNotes = action.payload;
      state.isLoadingNotes = false;
    },

    // PUT STATUS
    updateStatusSuccess(state, action) {
      state.updateStatusResponse = action.payload;
      state.isLoading = false;
    },

    // GET FILTER IVR VOICE
    getFilterExportSuccess(state, action) {
      state.exportDataResponse = action.payload;
      state.isLoading = false;
    },

    // GET FILTER IVR VOICE
    getFilterApplySuccess(state, action) {
      console.log('inboxVoiceData redux')
      state.inboxVoiceData = action.payload;
      state.isLoadingFilter = false;
    },

    // GET FILTER IVR VOICE
    updateInboxColumnSuccess(state, action) {
      state.updateInboxColumn = action.payload;
      state.isLoading = false;
    },

    // GET FILTER IVR VOICE
    updateInboxDragColumnSuccess(state, action) {
      state.updateInboxDragColumn = action.payload;
      state.isLoading = false;
    },

    //get export data
    getExportDataSuccess(state, action) {
      state.exportDataResponse = action.payload
      state.isLoading = false
    },


    // STOP LOADING
    isRefreshData(state) {
      state.isRefresh = true;
    },

    // STOP LOADING
    isRefreshDataCancel(state) {
      state.isRefresh = false;
    },

    putAddStatusResponse(state, action) {
      state.statusUpdateResponse = action.payload
      state.isLoading = false
    },

    getFlowByIdSuccess(state, action) {
      state.getFlowByIdResponse = action.payload
      state.isLoading = false
    },

     
    setCallDetailsDrawer(state, action) {
      state.callDetailsDrawer = action.payload
    },
    setVoiceId(state, action) {
      state.voiceId = action.payload
    },

   
  }
})



export const selectAllInboxData = (state) => state.inboxVoice;

export const { 
  setCallDetailsDrawer,
  setVoiceId
} = inboxVoiceSlice.actions

export default inboxVoiceSlice.reducer
// Actions

export function getIvrVoice(offsetCount, rowsPerPage) {
  console.log('getIvrVoice trigger', offsetCount)
  return async () => {
    console.log('getIvrVoice trigger in', offsetCount)
    dispatch(inboxVoiceSlice.actions.startLoading());
    try {

      let stringifyAssignedNumber = localStorage.getItem('assignedNumbervoice')

      
      
      let correctCloudNumberAccordingTofilterApplied = stringifyAssignedNumber.includes('+') ? (stringifyAssignedNumber.replace('+', '%2B')) : ('%2B'+stringifyAssignedNumber)
      console.log("number in filter : ", correctCloudNumberAccordingTofilterApplied)
      let link = `/ivrstudios/flowChart?limit=${rowsPerPage}&offset=${offsetCount}&sort=-createdAt&populate=userID`
      if (stringifyAssignedNumber === '[]') {
        link = `/ivrstudios/flowChart?limit=${rowsPerPage}&offset=${offsetCount}&sort=-createdAt&populate=userID`
      } else {
        link = `/ivrstudios/flowChart?limit=${rowsPerPage}&offset=${offsetCount}&number=["${correctCloudNumberAccordingTofilterApplied}"]&sort=-createdAt&populate=userID`
      }
      console.log('getIvrVoice trigger try', offsetCount)
      const response = await axios.get(link);
      dispatch(inboxVoiceSlice.actions.getIvrSuccess(response.data));
      console.log('getIvrVoice', response)
      dispatch(inboxVoiceSlice.actions.isRefreshDataCancel());

    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
      console.log('getIvrVoice trigger error', error)
    }
  };
}

export function getContactDetails(number) {
  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contacts?phoneNumber=${number}`);
      dispatch(inboxVoiceSlice.actions.getContactSuccess(response.data));
      console.log('getContactDetails', response)
    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));

    }
  };
}


export function addNotes(id, data, ParentCallSid) {
  return async () => {
    const token = localStorage.getItem("redux-token")
    dispatch(inboxVoiceSlice.actions.startLoadingNotes());
    console.log("ParentCallSid", ParentCallSid)
    try {
      const response = await axios.put(`/ivrstudios/flowChart/${id}`, { "Notes": data });
      dispatch(inboxVoiceSlice.actions.addNotesSuccess(response.data));
      dispatch(inboxVoiceSlice.actions.stopLoadingNotes());
      if (response.data.status === true) {
        dispatch(showSnackbar('Notes Updated Successfully'));
      } else {
        dispatch(showSnackbar('Oops.. Trouble while adding notes'));
      }



      if (ParentCallSid !== undefined && ParentCallSid !== null && ParentCallSid !== "") {
        axios({
          method: "GET",
          url: `${REACT_APP_BASE_URL}/api/ivrstudios/flowchart?ParentCallSid=${ParentCallSid}`,
          headers: {
            authorization: token
          }
        }).then((res) => {
          console.log("response_data", res.data);
          const response_data = res.data.data.ivrFlow;
          if (response_data.length > 0) {
            dispatch({
              type: SET_NOTES,
              payload: response_data[0].Notes
            })
          }
        }).catch((err) => {

          console.log("error_response", err.response)
        })
      }

    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Trouble while adding notes'));
    }
  }
}

export function removeNotes(id, data) {

  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoadingNotes());
    try {
      const response = await axios.put(`/ivrstudios/flowChart/${id}`, { "Notes": data });
      dispatch(inboxVoiceSlice.actions.deleteNotesSuccess(response.data));
      dispatch(inboxVoiceSlice.actions.stopLoadingNotes());
      if (response.data.status === true) {
        dispatch(showSnackbar('Notes Deleted Successfully'));
      } else {
        dispatch(showSnackbar('Oops.. Trouble while Deleting notes'));
      }

    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Trouble while Deleting notes'));
    }
  };
}



export function updateTicket(id, data) {

  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoadingStatus());
    try {
      const dataToSend = { "ticketId": data }
      const response = await axios.put(`/ivrstudios/flowChart/${id}`, dataToSend);
      dispatch(inboxVoiceSlice.actions.updateStatusSuccess(response.data));
      console.log('update status redux', response)
      dispatch(inboxVoiceSlice.actions.stopLoadingStatus());
      if (response.data.status === true) {
        dispatch(showSnackbar('Ticket Assigned Successfully'));
      } else {
        dispatch(showSnackbar('Oops.. Trouble while updating Status'));
      }

    } catch (error) {
      dispatch(inboxVoiceSlice.actions.stopLoadingStatus());
      dispatch(inboxVoiceSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Trouble while updating Status'));
    }
  };
}
export function updateStatus(id, data) {

  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoadingStatus());
    try {
      const response = await axios.put(`/ivrstudios/flowChart/${id}`, { "Status": data });
      dispatch(inboxVoiceSlice.actions.updateStatusSuccess(response.data));
      console.log('update status redux', response)
      dispatch(inboxVoiceSlice.actions.stopLoadingStatus());
      if (response.data.status === true) {
        dispatch(showSnackbar('Status Changed Successfully'));
      } else {
        dispatch(showSnackbar('Oops.. Trouble while updating Status'));
      }

    } catch (error) {
      dispatch(inboxVoiceSlice.actions.stopLoadingStatus());
      dispatch(inboxVoiceSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Trouble while updating Status'));
    }
  };
}

export function getFilter(flowName, ivrName, digit, callStatus, caller, reciever, number, startDate, endDate, tags, CallBoundType, status, offset, rowsPerPage) {


  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoadingFilter());
    try {
      console.log("number in filter : ", number)
      const response = await axios.get(`/ivrstudios/flowChart?flowName=${flowName}&ivrName=${ivrName}&digit=${digit}&callStatus=${callStatus}&caller=${caller}&reciever=${reciever}&number=${number === '' ? '' : `["%2b${number}"]`}&startDate=${startDate}&endDate=${endDate}&tags=${tags.lenght === 0 ? '' : tags}&CallType=${CallBoundType}&documentStatus=${status}&limit=${rowsPerPage}&offset=${offset}&sort=-_id`);

      dispatch(inboxVoiceSlice.actions.getFilterApplySuccess(response.data));

      dispatch(inboxVoiceSlice.actions.stopLoadingFilter());
      console.log('inbox filter', response)
      // dispatch(inboxVoiceSlice.actions.getIvrSuccess(response.data));
      dispatch(inboxVoiceSlice.actions.isRefreshDataCancel());
    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
    }
  };

}



export function getFilterExport(flowName, ivrName, digit, callStatus, caller, reciever, number, startDate, endDate, tags, CallBoundType, status, offset, rowsPerPage) {

  console.log('redux filter', CallBoundType)

  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoadingExport());
    try {
      const response = await axios.get(`/ivrstudios/flowChart?flowName=${flowName}&ivrName=${ivrName}&digit=${digit}&callStatus=${callStatus}&caller=${caller}&reciever=${reciever}&number=${number.lenght === 0 ? '' : `["${number}"]`}&startDate=${startDate}&endDate=${endDate}&tags=${tags.lenght === 0 ? '' : tags}&CallType=${CallBoundType}&documentStatus=${status}&sort=-_id`);
      dispatch(inboxVoiceSlice.actions.getFilterExportSuccess(response.data));
      dispatch(inboxVoiceSlice.actions.stopLoadingExport());
      console.log('inbox filter redux', response)
      dispatch(inboxVoiceSlice.actions.isRefreshDataCancel());
    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
    }
  };

}


export function getExportData(flowName, ivrName, digit, callStatus, caller, reciever, number, startDate, endDate, tags, CallBoundType, status, outputParams) {

  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoadingExport());
    try {
      const response = await axios.get(`/download_with_filter?flowName=${flowName}&ivrName=${ivrName}&digit=${digit}&callStatus=${callStatus}&caller=${caller}&reciever=${reciever}&number=${Object.keys(number).length === 0 ? '' : JSON.stringify(number)}&startDate=${startDate}&endDate=${endDate}&tags=${tags.lenght === 0 ? '' : tags}&CallType=${CallBoundType}&documentStatus=${status}&output=${outputParams}&type=IVR&sort=-_id`);
      dispatch(inboxVoiceSlice.actions.getExportDataSuccess(response.data));
      dispatch(inboxVoiceSlice.actions.stopLoadingExport());
      console.log('inbox export redux', response)

    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
    }
  };

}



export function updateColSettings(id, dragData, showData) {
  console.log('update drag redux request', dragData, showData)
  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoading());
    try {
      const response = await axios.put(`/users/col/${id}`, { "ivrColumnSettings": dragData, "ivrColumn": showData });
      dispatch(inboxVoiceSlice.actions.updateInboxDragColumnSuccess(response.data));
      console.log('update drag redux', response)
      if (response.data.status === true) {
        dispatch(showSnackbar('Column State Saved'));
      } else {
        dispatch(showSnackbar('Oops.. Trouble to Save Change'));
      }

    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Trouble to Save Change'));
    }
  };
}



export function getIvrVoiceSearch(searchValue, offset, rowsPerPage) {
  console.log('getIvrVoiceSearch trigger', offset)
  const AccountSid = localStorage.getItem('authID');
  return async () => {

    dispatch(inboxVoiceSlice.actions.startLoading());
    try {

      const response = await axios.get(`/ivrstudios/search_call?number=${searchValue}&AccountSid=${AccountSid}&limit=${rowsPerPage}&offset=${offset}&sort=-_id`);
      dispatch(inboxVoiceSlice.actions.getIvrSearchSuccess(response.data));
      console.log('getIvrVoiceSearch', response)
      dispatch(inboxVoiceSlice.actions.isRefreshDataCancel());

    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
      console.log('getIvrVoiceSearch trigger error', error)
    }
  };
}


export function refreshVoiceData() {
  console.log('refreshVoiceData trigger')
  dispatch(inboxVoiceSlice.actions.isRefreshData());

  console.log('refreshVoiceData trigger state')
}







export function gettrailapi(searchValue, offset, rowsPerPage) {
  console.log('getIvrVoiceSearch trigger', offset)
  const AccountSid = localStorage.getItem('authID');
  return async () => {

    dispatch(inboxVoiceSlice.actions.startLoading());
    try {

      const response = await axios.get(`/users-plan?customer_id=860773000000358119`);
      dispatch(inboxVoiceSlice.actions.gettrailSuccess(response.data));
      console.log('getIvrVoiceSearch', response)
      dispatch(inboxVoiceSlice.actions.isRefreshDataCancel());

    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
      console.log('getIvrVoiceSearch trigger error', error)
    }
  };
}





// export function getIvrVoiceSearch(searchValue, offset, rowsPerPage) {
//   console.log('getIvrVoiceSearch trigger', offset)
//   const AccountSid = localStorage.getItem('authID');
//   return async () => {

//     dispatch(inboxVoiceSlice.actions.startLoading());
//     try {

//       const response = await axios.get(`/ivrstudios/search_call?number=${searchValue}&AccountSid=${AccountSid}&limit=${rowsPerPage}&offset=${offset}&sort=-_id`);
//       dispatch(inboxVoiceSlice.actions.getIvrSearchSuccess(response.data));
//       console.log('getIvrVoiceSearch', response)
//       dispatch(inboxVoiceSlice.actions.isRefreshDataCancel());

//     } catch (error) {
//       dispatch(inboxVoiceSlice.actions.hasError(error));
//       console.log('getIvrVoiceSearch trigger error', error)
//     }
//   };
// }


// export function refreshVoiceData() {
//   console.log('refreshVoiceData trigger')
//   dispatch(inboxVoiceSlice.actions.isRefreshData());

//   console.log('refreshVoiceData trigger state')
// }







// export function gettrailapi(searchValue, offset, rowsPerPage) {
//   console.log('getIvrVoiceSearch trigger', offset)
//   const AccountSid = localStorage.getItem('authID');
//   return async () => {

//     dispatch(inboxVoiceSlice.actions.startLoading());
//     try {

//       const response = await axios.get(`/users-plan?customer_id=860773000000358119`);
//       dispatch(inboxVoiceSlice.actions.gettrailSuccess(response.data));
//       console.log('getIvrVoiceSearch', response)
//       dispatch(inboxVoiceSlice.actions.isRefreshDataCancel());

//     } catch (error) {
//       dispatch(inboxVoiceSlice.actions.hasError(error));
//       console.log('getIvrVoiceSearch trigger error', error)
//     }
//   };
// }



export function addStatus(id, data) {
  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoading());
    try {
      const response = await axios.put(`/users/${id}`, { "inboxVoiceStatus": data });
      dispatch(inboxVoiceSlice.actions.putAddStatusResponse(response.data));
      if (response.data.status === true) {
        dispatch(showSnackbar('Status Added'));

      } else {
        dispatch(showSnackbar('Oops.. Trouble to Save Change'));
      }
    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Trouble to Save Change'));
    }
  };
}

export function updateUserStatus(id, data) {
  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoading());
    try {
      const response = await axios.put(`/users/${id}`, { "inboxVoiceStatus": data });
      dispatch(inboxVoiceSlice.actions.putAddStatusResponse(response.data));
      if (response.data.status === true) {
        dispatch(showSnackbar('Status Updated'));

      } else {
        dispatch(showSnackbar('Oops.. Trouble to Save Change'));
      }
    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Trouble to Save Change'));
    }
  };
}

export function deleteUserStatus(name) {
  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoading());
    try {
      const response = await axios.put(`/users/deleteStatus/${name}`);
      dispatch(inboxVoiceSlice.actions.putAddStatusResponse(response.data));
      // if (response.data.status === true) {
      //   dispatch(showSnackbar('Status Deleted'));
      // } else {
      //   dispatch(showSnackbar('Oops.. Trouble to Save Change'));
      // }
    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Trouble to Save Change'));
    }
  };
}

export function deleteUserStatusList(id, data) {
  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoading());
    try {
      const response = await axios.put(`/users/${id}`, { "inboxVoiceStatus": data });
      dispatch(inboxVoiceSlice.actions.putAddStatusResponse(response.data));
      if (response.data.status === true) {
        dispatch(showSnackbar('Status Deleted'));
        dispatch(getUser(userID));
      } else {
        dispatch(showSnackbar('Oops.. Trouble to Delete'));
      }
    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
      dispatch(showSnackbar('Oops.. Trouble to Delete'));
    }
  };
}





export function getFlowById(id) {
  return async () => {
    dispatch(inboxVoiceSlice.actions.startLoading());
    try {
      const response = await axios.get(`/ivrstudios/flowChart/${id}`);
      dispatch(inboxVoiceSlice.actions.getFlowByIdSuccess(response.data));

    } catch (error) {
      dispatch(inboxVoiceSlice.actions.hasError(error));
    }
  };
}


export function getfilterShowExport() {
  return async () => {
    dispatch(inboxVoiceSlice.actions.filterShowTrue());
  };
}

export function getfilterResetExport() {
  return async () => {
    dispatch(inboxVoiceSlice.actions.filterShowReset());
  };
}


