import { CircularProgress, FormControl, FormControlLabel, RadioGroup, Radio, Button, Menu, MenuItem, Snackbar, Paper, Select } from '@mui/material';
import React, {useEffect, useState} from 'react'
import { edit_app_config, get_inbox, get_number, SET_INBOX } from 'src/redux/userReducer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch } from 'react-redux';


function InboxAllowed({ props, setProps,setDrawerOpen }) {
  const authId = localStorage.getItem("authID");
  const dispatch = useDispatch();
  const token = localStorage.getItem("redux-token");
  const [id, setId] = useState("")
  const [anchorEl, setAnchorEl] = useState(null);
  const [inbox, setInbox] = useState([])
  const [value, setValue] = useState("")
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fetch_allowed, setFetchAllowed] = useState(false)

  useEffect(() => {
    setLoading(true);
    get_inbox(authId, token, "voice").then(async(res) => {
      var response = res;
      dispatch({
        type: SET_INBOX,
        payload: response
      });
      if (response.length > 0) {
        setErrorMessage(false);
        for (let i=0; i < response.length; i++) {
          response[i].allowed = false;
          var allowed_number = [];
          for (let j=0; j < response[i].data.length; j++) {
            const number = response[i].data[j].number;
            if (number !== null && number !== undefined && number !== "") {
              await get_number(number.slice(-10), token).then((resp) => {
                if (resp.length > 0) {
                  allowed_number.push(resp[0]);
                }
              }).catch((err) => {
                console.log("error", err)
              })
            }
          }
          response[i].data = allowed_number;
        }
        setInbox(response);
        console.log("response", response);
        
          setTimeout(() => {
            setFetchAllowed(true)
          }, 5000)
      } else {
        setLoading(false);
        setErrorMessage(true)
      }
    }).catch((err) => {
      setErrorMessage(true)
    })
  }, []);

  useEffect(() => {
    if (fetch_allowed === true) {
      if (inbox.length > 0) {
        for (let i = 0; i < props.cloudNumber_allow.length; i++) {
          const cloud_number = props.cloudNumber_allow[i];
          console.log("cloud_number", cloud_number)
          for (let j = 0; j < inbox.length; j++) {
            const number_data = inbox[j].data;
            console.log("number_data", number_data);
            const findIndex = number_data.findIndex((value) => { return value._id === cloud_number });
            console.log("findIndex", findIndex)
            if (findIndex !== -1) {
              var formValues = [...inbox];
              formValues[j].allowed = true;
              setInbox(formValues)
            }
          }
          setLoading(false)
        }
      } else {
        setLoading(false);
        setErrorMessage(true)
      }
    }
  }, [fetch_allowed]);

  console.log("inbox", inbox);

  const allow_inbox = (is_allowed, id) => {
    console.log("id", id)
    const index = inbox.findIndex((value) => { return value._id === id });
    console.log("index", index)
    if  (index !== -1) {
      var inbox_arr = [...inbox];
      if (is_allowed === "Allowed") {
        inbox_arr[index].allowed = true;
      } else {
        inbox_arr[index].allowed = false;
      }
      setInbox(inbox_arr);
      setAnchorEl(null)
      console.log("inbox_arr", inbox_arr)
      get_allowed_number(inbox_arr);
    }
  }

  const get_allowed_number = (inbox_arr) => {
    var allowed_number = [];
    for (let i=0; i < inbox_arr.length; i++) {
      const allowed = inbox_arr[i].allowed;
      console.log("allowed", allowed);
      if (allowed === true) {
        for (let j = 0; j < inbox_arr[i].data.length; j++) {
          const id = inbox_arr[i].data[j]._id;
          console.log("id", id);
          allowed_number.push(id)
        }
      }


      if (i+1 === inbox_arr.length) {
        console.log("allowed_number", allowed_number);
        var app_detail = props;
        app_detail.cloudNumber_allow = allowed_number;
        setProps(app_detail)
        edit_app(allowed_number);
      }
    }
  }

  const edit_app = (cloud_numbers) => {
    const config_id = props._id;
    const token = localStorage.getItem("redux-token");
    const body = {
      "cloudNumber_allow": cloud_numbers
    }
    edit_app_config(config_id, body, token).then((res) => {
      setDrawerOpen(false)
      setErrorMessage("CloudPhone config is updated successfully");
      setShowError(true)
    }).catch((err) => {
      console.log("error", err);
      setErrorMessage("CloudPhone config update is failed");
      setShowError(true)
    })
  }

  const Radio_label = ({ inbox_data, index }) => {
    console.log("index", index)
    return (
      <div style={{ display: "flex", flexDirection: "row", width: 480, alignItems: "center", justifyContent: "space-between"}}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 20 }}>
          <i className={`bi bi-${inbox_data.InboxIcon}`}></i>
          <div style={{ marginLeft: 10, color: "#000", fontSize: 17, }}>
            {inbox_data.InboxName}
          </div>
        </div>
        <Select
            labelId="demo-select-small"
            id="demo-select-small"
            sx={{ minHeight: 20, height: 40 }}
            onChange={(event) => allow_inbox(event.target.value, inbox_data._id)}
            value={inbox_data.allowed === true ? "Allowed" : "Not Allowed"}
            >
                <MenuItem value={"Allowed"}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                            Allowed
                        </div>
                    </div>
                </MenuItem>
                <MenuItem value={"Not Allowed"}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                        <div style={{ marginLeft: 10, marginRight: 10}}>
                            Not Allowed
                        </div>
                    </div>
                </MenuItem>
            </Select>
      </div>
    )
  }
  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column", marginLeft: 10 }}>
      {inbox.length === 0 ? (
        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          {loading === true ? (
            <CircularProgress style={{ width: 30, height: 30, color: "#0046BB", marginTop: 20}} />
          ): (
            <div style={{ marginTop: 20, color: "#0046BB", fontSize: 17, fontWeight: "700"}}>
              No Inbox are found
            </div>
          )}
        </div>
      ): (
        <FormControl sx={{ width: "100%"}}>
          <RadioGroup 
          sx={{ width: "100%" }}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={(event) => setValue(event.target.value)}>
            {inbox.map((value, index) => (
              <FormControlLabel value={value.InboxName} key={index} control={<Radio/>} sx={{ width: "100%", height: 50, }} label={<Radio_label inbox_data={value} index={value._id}/>}/>
            ))}
          </RadioGroup>
        </FormControl>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        message={errorMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => setShowError(false)}>
              OK
            </Button>

          </React.Fragment>
        }
      />
    </div>
  )
}

export default InboxAllowed