import React, { useState, useRef, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import UpdateIcon from '@mui/icons-material/Update';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';
import BoltIcon from '@mui/icons-material/Bolt';
import {SET_COMPANY, SET_SELECTED_COUNTRY, saveNoteAndTags} from "src/redux/userReducer"
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { StatusComponent } from "./StatusComponent";
import { createSearchParams, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import {
  Button,
  IconButton,
  Typography,
  Paper,
  Card,
  AppBar,
  Toolbar,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Fab,
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Input,
  Divider,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListItemIcon,
  Backdrop,
  Snackbar,
  Autocomplete,
  CircularProgress,
  MenuItem,
  Menu,
} from '@mui/material';

import PhoneInput from 'react-phone-input-2';
import interact from 'interactjs'

import 'react-phone-input-2/lib/material.css';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CallIcon from '@mui/icons-material/Call';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import DialpadOutlinedIcon from '@mui/icons-material/DialpadOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SettingsPhoneOutlinedIcon from '@mui/icons-material/SettingsPhoneOutlined';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';

import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import {MicNoneOutlined } from '@mui/icons-material';
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import CallEndIcon from '@mui/icons-material/CallEnd';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import MicOffRoundedIcon from '@mui/icons-material/MicOffRounded';
import DialpadRoundedIcon from '@mui/icons-material/DialpadRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import VoicemailRoundedIcon from '@mui/icons-material/VoicemailRounded';

import StopIcon from '@mui/icons-material/Stop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import phoneCallImg from './../../assets/phone-call.png';
import MissedCall from './../../assets/telephone-plus.svg';

import axios from 'axios';
import { io } from "socket.io-client";
import { socket } from "../../utils/socketInst";
import { useSelector, useDispatch } from "react-redux";


import axiosInstance, { baseUrl, REACT_APP_BASE_URL } from '../../utils/axios';

import IncomingCall from './IncomingCall';
import Conversations from './Conversations';
import { SET_AGENT_CONTACT, SET_CALL_HISTORY, SET_CALL_OFFSET, SET_MISSED_CALL, SET_MISSED_OFFSET, SET_POWER_DIALER_INDEX, SET_USER } from 'src/redux/userReducer';
import TeamComponent from './TeamComponent';
import BlacklistComponent from './BlacklistComponent';
import AddBlacklistNumberComponent from './AddBlacklistNumberComponent';
import CallingPattern from './CallingPattern';
import PowerDialerComponent from './PowerDialerComponent';
import { SET_POWER_DIALER } from '../../redux/userReducer';



export default function ContactAddComponent() {

  // const localStorage.getItem('redux-token') = localStorage.getItem('redux-token')
  let dispatch = useDispatch();
  const navigate = useNavigate()
  const [conversation, setConversation] = useState(true)
  const [completed_call, setCompletedCall] = useState(false)
  const { user, agent_contact, callHistory, missed_call, show_all_list, call_offset, missed_offset, power_dialer_index, power_dialer, app, allowed_country, selected_country, cloud_numbers, company } = useSelector((state) => state.user)
  //Danish----
  let CallSid = localStorage.getItem("CallSid")
  const [account, setAccount] = useState(false)
  let id = localStorage.getItem('id');
  const [show_country_dropdown, setShowCountryDropDown] = useState(false)
  let agentID = localStorage.getItem('agentID');
  let authId = localStorage.getItem('authID');
  let inprogressTimestamp = localStorage.getItem('inprogressTimestamp');
  const [username, setusername] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [userMobile, setuserMobile] = React.useState("");
  const [authSecretId, setauthSecretId] = React.useState("");
  const [purchasedNumber, setpurchasedNumber] = React.useState("");
  const [refresh, setRefresh] = React.useState(0)
  const [power_dialer_total_count, setPowerDialerTotalCount] = useState(0)
  const [add_blacklist, setAddBlacklist] = useState(false)
  const [hold, setHold] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  //intial value of call tags
  const [value, setValue] = React.useState(" ");

  //inputValue changes all the time when we made changes in Auto-cpmplete of Call tags
  const [inputValue, setInputValue] = React.useState('');
  //----------


  //timer----------
  const [timer, setTimer] = useState(3595)
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [country_input, setCountryInput] = useState("")
  const increment = useRef(null)
  const [webrtc_call, setWebrtcCall] = useState(false)


  const handleStart = () => {
    setIsActive(true)
    setIsPaused(true)
    window.clearInterval(increment.current)
    increment.current = window.setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000)

    return () => window.clearInterval(increment.current);
  }

  const handlePause = () => {
    window.clearInterval(increment.current)
    setIsPaused(false)
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setShowError(false);
  };

  const handleResume = () => {
    setIsPaused(true)
    increment.current = setTimerout(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  const [tags_input, setTagsInput] = useState("")

  const handleReset = () => {
    window.clearInterval(increment.current)
    setIsActive(false)
    setIsPaused(false)
    setTimer(0)
  }

  const formatTime = () => {
    const getSeconds = `0${(timer % 60)}`.slice(-2)
    const minutes = `${Math.floor(timer / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

    return `${getHours} : ${getMinutes} : ${getSeconds}`
  }







  const [parentCallSidOfCall, setParentCallSid] = useState('')
  const [open, setOpen] = useState(false);
  const [bottomNav, setBottomNav] = useState(true);
  const [incoming_sip, setIncomingSip] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('');
  const [callForm, setCallFrom] = useState(purchasedNumber);
  const [purchased_number_of_strapi, setPurchasedNumberStrapi] = useState([]);
  const [selected_number, setSelectedNumber] = useState('');
  //age is use to as a varialbe to render Call-From numbers
  const [age, setAge] = React.useState('');
  const [friendlyName, setFriendlyName] = useState("Room_dummy")
  const [callFormPhoneBrowser, setCallFormPhoneBrowser] = useState('Phone');
  const [dialToggle, setDialToggle] = useState(false);
  const [dialingToggle, setDialingToggle] = useState(false);
  const [allConversation, setAllConversation] = useState(true);
  const [phoneDetails, setPhoneDetails] = useState([])
  const [settings, setSettings] = useState(false);
  const [defaultCallingNumber, setDefaultCallingNumber] = useState(localStorage.getItem("defaultCallingNumber") ? localStorage.getItem("defaultCallingNumber") : null);
  const [defaultCallingPattern, setDefaultCallingPattern] = useState('Phone Call');
  const [defaultCallingCountry, setDefaultCallingCountry] = useState(localStorage.getItem('defaultCountry') ? localStorage.getItem('defaultCountry') : 'IN');
  const [widjetMainToggle, setWidjetMainToggle] = useState(false);
  const [power_dialer_refreshing, setPowerDialerRefreshing] = useState(false)
  const [minimizeMaximize, setMinimizeMaximize] = useState(false);
  const [expandContract, setExpandContract] = useState(false);
  const [expandContractClass, setExpandContractClass] = useState(true);
  const [expandContractBtn, setExpandContractBtn] = useState(false);
  const [callOnProgress, setCallOnProgress] = useState(false); //false
  const [callNotes, setCallNotes] = useState('');
  const [callConnected, setCallConnected] = useState(false); //false
  // console.log("callConnected : ",callConnected)

  const [conversationHistoryShow, setConversionHistoryShow] = useState(false);
  const [conversationHistoryToggle, setConversionHistoryToggle] = useState(false);
  const [showHistoryBtn, setShowHistoryBtn] = useState(true);

  const [showAddNotes, setShowAddNotes] = useState(false);

  const [showAssign, setShowAssign] = useState(false);

  const [showTags, setShowTags] = useState(false);
  const [tags, setTags] = useState({});
  // console.log("selected tags : ", tags)
  const [assignTo, setAssignTo] = useState('abhishek');

  const [showEditNotes, setShowEditNotes] = useState(false);
  const [call_status, setCallStatus] = useState(false)

  const [editNotes, setEditNotes] = useState('');

  const [enableEditNotes, setEnableEditNotes] = useState(true);

  const [dialPadBackDrop, setDialPadBackDrop] = useState(false);

  const [isCallIncomingDirection, setIsCallIncomingDirection] = React.useState(true)
  const [incomingCustomerNumber, setIncomingCustomerNumber] = React.useState('')
  const [search_tag, setSearchTags] = useState([]);

  const assignToList = [
    { name: 'Abhishek', value: 'abhishek' },
    { name: 'Danish', value: 'danish' },
    { name: 'Rahul', value: 'rahul' },
    { name: 'Sreeju', value: 'sreeju' }
  ]

  const handleShowAddNotes = () => {
    setShowAddNotes(true)
    setShowTags(false)
    setShowAssign(false)
  }
  const handleAddNotes = () => {
    setShowAddNotes(false)
    if (callNotes !== "" && callNotes !== undefined && callNotes !== null) {
      const body = {
        "parentCallSid": parentCallSidOfCall,
        "Notes": [{
          name: "Notes",
          value: callNotes
        }]
      }
      const token = localStorage.getItem("redux-token");
      saveNoteAndTags(friendlyName, token, body).then((res) => {
        
      }).catch((err) => {
        console.log("error", err);
      })
    }
  }

  const handleShowTags = () => {
    setShowTags(true)
    setShowAssign(false)
    setShowAddNotes(false)
  }
  const handleSaveTags = () => {
    setShowTags(false)
    var selected_tags = [];
    for (let i = 0; i < tagsPresentInUserAccount.length; i++) {
      const checked = tagsPresentInUserAccount[i].checked;
      if (checked === true) {
        selected_tags.push(tagsPresentInUserAccount[i])
      }
    }
    const body = {
      "parentCallSid": parentCallSidOfCall,
      "Tags": selected_tags
    }
    const token = localStorage.getItem("redux-token");
    saveNoteAndTags(friendlyName, token, body).then((res) => {
        
    }).catch((err) => {
      console.log("error", err);
    })
  }
  const handleShowAssign = () => {
    setShowAssign(true)
    setShowTags(false)
    setShowAddNotes(false)
  }
  const handleAssign = () => {
    setShowAssign(false)
  }
  const [noteId, setNoteId] = useState('');
  // console.log("noteId : ", noteId)
  const handleShowEditNotes = (notes) => {
    setEditNotes(notes.Notes)
    setNoteId(notes._id)
    // console.log("notes in edit ", notes)
    setShowEditNotes(true)
  }

  const secondsToMinutes = (secs) => {
    const min = secs / 60;
    const roundedMin = Math.floor(min);
    const sec = secs % 60;
    return `${roundedMin}:${sec}`;
  }

  const submitEditedNotes = async () => {
    axiosInstance.put(`/edit/cloudphone/${noteId}`, { Notes: editNotes })
      .then(res => {
        
      })
      .catch(err => {
        
      })
  }

  const handleBackEditNotes = () => {
    setShowEditNotes(false)
  }
  const handleEnableEditNotes = () => {
    setEnableEditNotes(false)
  }
  const handleSaveEditNotes = () => {
    setEnableEditNotes(true)
  }



  const handleTagsChange = (event) => {
    setTags(event.target.value)
  }
  const handleAssignChange = (event) => {
    setTags(event.target.value)
  }

  const [showError, setShowError] = useState(false);
  const [dialingIconShow, setDialingIconShow] = useState(false);
  const [ringingIconShow, setRingingIconShow] = useState(false);
  const [number_of_webrtc_connection, setNumberOfWebrtcConnection] = useState(0)
  const [call_data, setCallData] = useState("")
  const [incomingCallToggle, setIncomingCallToggle] = useState(false);
  const [DirectionCall, setDirection] = useState("inbound");
  const [ringing_sip, setRingingSip] = useState(false)
  const [search_cloud_number, setSearchCloudNumber] = useState("")
  const [showCallType, setShowCallType] = useState(false);
  const [browserCallActive, setBrowserCallActive] = useState('');
  const [phoneCallActive, setPhoneCallActive] = useState('active-radio-call');
  const [showSearchNumbers, setShowSearchNumbers] = useState(false);
  const [mute, setMute] = useState(false);

  useEffect(() => {
    if (app.length > 0) {
      const call_type = app[0].type_allow;
    if (call_type === "phone") {
      setCallFormPhoneBrowser("Phone");
    } else if (call_type === "sip") {
      setCallFormPhoneBrowser('Browser')
    }
    }
  }, [app])

  const handleChange = (value) => {
    setAge(value);
    localStorage.setItem("default_cloud_number", value);
    setShowSearchNumbers(false)
  };

  const handleSearchNumbers = () => {
    setShowSearchNumbers(true)
  }


  const handleCallUsing = () => {
    setShowCallType(true)
  }
  const handleBrowserCall = () => {
    setBrowserCallActive('active-radio-call');
    setPhoneCallActive('');
    setCallFormPhoneBrowser('Browser')
    setShowCallType(false)
  }

  const handlePhoneCall = () => {
    setPhoneCallActive('active-radio-call');
    setBrowserCallActive('');
    setCallFormPhoneBrowser('Phone')
    setShowCallType(false)
  }




  const incomingCall = () => {
    if (incomingCallToggle === true) {
      setWidjetMainToggle(true);
      setMinimizeMaximize(true);
      setExpandContractBtn(true);

    }
  }

  useEffect(() => {
    incomingCall()
  }, [incomingCallToggle]) 

  const [tagsPresentInUserAccount, setTagsPresentInUserAccount] = useState([])
  const [user_status, setUserStatus] = useState("Available")
  const [killing, setKilling] = useState(false)
  const authID = localStorage.getItem("authID")

  const [country_list1, setCountryList1] = useState(allowed_country)
  const [removed_dulicate_array, setRemovedArray] = React.useState(cloud_numbers)
  
  useEffect(() => {
    if (authId !== undefined && authId !== null && authId !== "undefined" && minimizeMaximize === true && tagsPresentInUserAccount.length === 0) {
      axios({
        method: "GET",
        url: `${baseUrl}/users/getTags/${authId}`,
        headers: {
          authorization: localStorage.getItem('redux-token')
        }
      }).then(async(res) => {
        const response = res.data;
        if (response.status === "success") {
          setTagsPresentInUserAccount(response.tags)
          setSearchTags(response.tags)
        }
  
        await axios({
          method: "GET",
          url: `${baseUrl}/user/status`,
          headers: {
            "Content-Type": "application/json",
            "authorization": localStorage.getItem('redux-token')
          },
          data: {
            "authId": authId,
            "userId": user._id
          }
        }).then(async(res) => {
          const response = res.data.data.data;
          if (response.length > 0) {
            const status = response[0].status;
            setUserStatus(status);
          }
  
          const phone_number = user.phone.slice(-10)
  
          if (agent_contact.length === 0) {
            get_contact(phone_number)
          }
  
          if (user.user_type === "company" && user.is_admin === true && company.length === 0) {
            await axios({
              method: "GET",
              url: `${baseUrl}/companies/${user.company_id}`,
              headers: {
                authorization: localStorage.getItem('redux-token')
              }
            }).then((company) => {
              const response = company.data;
              if (response.status === true) {
                dispatch({
                  type: SET_COMPANY,
                  payload: response.data
                })
              }
  
            }).catch((err) => {
            })
          }
        }).catch((err) => {
  
        })
      }).catch((err) => {
  
      })
    }
  }, [user, minimizeMaximize, authId])

  const [WebState, setWebState] = React.useState("");

  function dialFromExternal() {
    setWidjetMainToggle(true);
    setMinimizeMaximize(true);
    setExpandContractBtn(true);
    setDialingToggle(true);
    setRingingIconShow(true);
    setBottomNav(false)
  }


  const openDialing = () => {
    setDialToggle(false);
    setRingingIconShow(true);
    setDialingIconShow(false);
    setBottomNav(false)
    setDialPadBtn(true)
  }

  const openDialingIncoming = () => {
    setWidjetMainToggle(true);
    setMinimizeMaximize(true);
    setExpandContractBtn(true);
    setDialingToggle(true);
    setRingingIconShow(true);
    setBottomNav(false)

    //new
    setDialingIconShow(false);
    setshowDisconnect(0)
  }

  const openWidget = () => {
    setCallConnected(true);
    handleStart()
    //timer
    setRingingIconShow(false);
    setDialingToggle(false);
    setCallOnProgress(true);
    setDialToggle(false);
    setDialPadBtn(true)

  }

  const storePhoneRecord = async () => {
    const toSaveCloudPhone = {
      ParentCallId: WebState,
      User: username,
      Notes: callNotes,
      // Tags: inputValue,
      Tags: tags,
      Receiver: phoneNumber,
      StartTime: initiated_time
    }
    setIsCallIncomingDirection(false)

    // await axiosInstance.post('/ide/save', toSave).then(resp => resp.data).then(data => console.log(data.status)).catch(err => console.log(err))
    await axiosInstance.post('/cp/phone/save', toSaveCloudPhone).then(resp => resp.data).then(data => {

    }).catch(err => {
       
    })

    setAlertOfCompleted(0);
    setDirection(" ")
    setPhoneNumber('')
    //this to re-render so that the incoming call will work after out-bound call
    setRefresh(refresh + 1)
    setshowDisconnect(0)
    setCallNotes('')
    // window.location.href = "/crm-ui/portal/MyServices";
  }

  const after_call_activities = () => {
    setKilling(false)
            setBottomNav(true)
            setCallOnProgress(false);
            setDialingToggle(false);
            setParentCallSid('')
            setFriendlyName('')
            setBottomNav(true)
            setCallOnProgress(false);
            setConversionHistoryShow(false)
            setCallConnected(false)
            setCallOnProgress(false);
            storePhoneRecord();
            handlePause()
            setIsActive(false)
            setIsPaused(false)
            setTimer(0)
            setCompletedCall(false)
            setCallData("")
            setTagsInput("");
            search_tags("");
        var formData = [...tagsPresentInUserAccount];
        setCallNotes("")
        for (let i = 0; i < tagsPresentInUserAccount.length; i++) {
          const checked = tagsPresentInUserAccount[i].checked;
          console.log("formData", formData, checked);
          if (checked === true) {
            formData[i].checked = false;
          }
        }
        setTagsPresentInUserAccount(formData)
  }


  const disconnectPhoneCall = (exit) => {
    return new Promise((resolve, reject) => {
      setShowTags(false)
    setShowAddNotes(false)
    setKilling(true)
    showAllConversation()
    if (call_data.Direction !== "inbound") {
      if (call_data.CallStatus === "in-progress" && call_data.Called.slice(-10) === phoneNumber.slice(-10)) {
        axios({
          method: "POST",
          url: `${baseUrl}/vibconnect/killConference`,
          headers: {
            authorization: localStorage.getItem('redux-token'),
            'Access-Control-Allow-Origin' : '*'
          },
          data: {
            "roomId": friendlyName
          }
        }).then((kill_call) => {
          //console.log("kill_call", kill_call)
          if (power_dialer === false) {
            after_call_activities();
            dispatch({
              type: SET_POWER_DIALER_INDEX,
              payload: 0
            })
          }

          if (exit === true) {
            after_call_activities();
            dispatch({
              type: SET_POWER_DIALER,
              payload: false
            })
            dispatch({
              type: SET_POWER_DIALER_INDEX,
              payload: 0
            })
          }
          resolve(kill_call)
        }).catch((err) => {

          //console.log("error_response", err)
        })
      } else if (call_data.CallStatus === "ringing" && call_data.Called.slice(-10) === phoneNumber.slice(-10)) {
        axios({
          method: "POST",
          url: `${baseUrl}/vibconnect/killCall`,
          headers: {
            authorization: localStorage.getItem('redux-token')
          },
          data: {
            CallSid: call_data.ParentCallSid
          }
        }).then((res) => {
          //console.log("response", res.data);
          axios({
            method: "POST",
            url: `${baseUrl}/vibconnect/killCall`,
            headers: {
              authorization: localStorage.getItem('redux-token')
            },
            data: {
              CallSid: call_data.CallSid
            }
          }).then((response) => {
            const response_data = response.data;
            //console.log("response_data", response_data)
            if (power_dialer === false) {
              after_call_activities();
              dispatch({
                type: SET_POWER_DIALER_INDEX,
                payload: 0
              })
            }

            if (exit === true) {
              after_call_activities();
              dispatch({
                type: SET_POWER_DIALER,
                payload: false
              })
              dispatch({
                type: SET_POWER_DIALER_INDEX,
                payload: 0
              })
            }
            resolve(response)
          }).catch((err) => {
            //console.log("error_response", err.response)
          })
        }).catch((err) => {
          //console.log("error_response", err.response)
        })
      } else if (call_data.CallStatus === "in-progress" && call_data.Called.slice(-10) !== phoneNumber.slice(-10)) {
        axios({
          method: "POST",
          url: `${baseUrl}/vibconnect/killCall`,
          headers: {
            authorization: localStorage.getItem('redux-token')
          },
          data: {
            CallSid: call_data.ParentCallSid
          }
        }).then((res) => {
          //console.log("response", res.data)
          if (power_dialer === false) {
            after_call_activities();
            dispatch({
              type: SET_POWER_DIALER_INDEX,
              payload: 0
            })
          }

          if (exit === true) {
            after_call_activities();
            dispatch({
              type: SET_POWER_DIALER,
              payload: false
            })
            dispatch({
              type: SET_POWER_DIALER_INDEX,
              payload: 0
            })
          }
          resolve(res)
        }).catch((err) => {
          //console.log("error_response", err.response)
        })
      } else if (call_data.CallStatus === "completed") {
        axios({
          method: "POST",
          url: `${baseUrl}/vibconnect/killConference`,
          headers: {
            authorization: localStorage.getItem('redux-token'),
            'Access-Control-Allow-Origin' : '*'
          },
          data: {
            "roomId": friendlyName
          }
        }).then((kill_call) => {
          //console.log("kill_call", kill_call)
          if (power_dialer === false) {
            after_call_activities();
            dispatch({
              type: SET_POWER_DIALER_INDEX,
              payload: 0
            })
          }

          if (exit === true) {
            after_call_activities();
            dispatch({
              type: SET_POWER_DIALER,
              payload: false
            })
            dispatch({
              type: SET_POWER_DIALER_INDEX,
              payload: 0
            })
          }
          resolve(kill_call)
        }).catch((err) => {

          //console.log("error_response", err)
        })
      } else {
        axios({
          method: "POST",
          url: `${baseUrl}/vibconnect/killCall`,
          headers: {
            authorization: localStorage.getItem('redux-token')
          },
          data: {
            CallSid: call_data.ParentCallSid
          }
        }).then((res) => {
          //console.log("response", res.data)
          if (power_dialer === false) {
            after_call_activities();
            dispatch({
              type: SET_POWER_DIALER_INDEX,
              payload: 0
            })
          }

          if (exit === true) {
            after_call_activities();
            dispatch({
              type: SET_POWER_DIALER,
              payload: false
            })
            dispatch({
              type: SET_POWER_DIALER_INDEX,
              payload: 0
            })
          }
          resolve(res)
        }).catch((err) => {
          //console.log("error_response", err.response)
        })
      }
    } else {
      axios({
        method: "POST",
        url: `${baseUrl}/vibconnect/killConference`,
        headers: {
          authorization: localStorage.getItem('redux-token'),
          'Access-Control-Allow-Origin' : '*'
        },
        data: {
          "roomId": friendlyName
        }
      }).then((kill_call) => {
        //console.log("kill_call", kill_call)
        if (power_dialer === false) {
          after_call_activities();
          dispatch({
            type: SET_POWER_DIALER_INDEX,
            payload: 0
          })
        }

        if (exit === true) {
          after_call_activities();
          dispatch({
            type: SET_POWER_DIALER,
            payload: false
          })
          dispatch({
            type: SET_POWER_DIALER_INDEX,
            payload: 0
          })
        }
        resolve(kill_call)
      }).catch((err) => {

        //console.log("error_response", err)
      })
    }
    })
  }


  const [alertOfCompleted, setAlertOfCompleted] = useState(0);
  // console.log("alert value 1 ", alertOfCompleted)

  const ReturnNull = () => {
    return null
  }

  //created to make the green screen on call widget to red while we disconnect
  const [showDisconnect, setshowDisconnect] = useState(0);

  const afterCallComplete = () => {
    setAlertOfCompleted(alertOfCompleted + 1)
    handlePause();
    setshowDisconnect(1)
    // send_Corresponding_Sid_for_api_calling();
    // alert("Call Completed Click on disconnect Button")

  }
  //un-used function created to make green color widget red while call get disconnected
  const controlDisconnect = () => {
    if (showDisconnect === 0) {
      return (
        <div className="connected-call">
          <Typography variant="subtitle1">
            Connected Call
          </Typography>
        </div>

      )
    }
    if (showDisconnect === 1) {
      return (
        <div className="disconnected-call">
          <Typography variant="subtitle1">
            Call Disconnected from Receiver
          </Typography>
        </div>

      )
    }

  }

  { alertOfCompleted == 10 ? afterCallComplete() : ReturnNull() }


  //un-used function but it is used to send username and sid corresponding to the call to filter the data according to username
  const send_Corresponding_Sid_for_api_calling = async () => {
    // await axios.post('http://localhost:5000/sid', {username : username ,sid: WebState })
    await axios.post('https://vibtreenamecollection.herokuapp.com/sid', { username: username, sid: CallSid })
      .then(response => {
        // console.log("response of sending username and sid ", response);
        // alert(0)
      })
      .catch(error => {
        //console.log("error from sid ", error);
      })
  }


  const [parentSid, setparentSid] = useState("");




  //this function is call in the submit function where it recieve an sid to create a room
  const handleRoom = (sid) => {
    // alert("handle room runs", sid)
    //console.log("handle room runs", sid)
    connectToRoom2(sid, "outbound");
  }

  const [initiated_time, setInitiatedTime] = React.useState('')
  //it is a function which is call in handle room to active the socket connection 
  //we dont call it directly in the final because the sid is undefined at that time



   //socket.on('test', (data)=>{
   //console.log("data from socket ", data)
   //})
  const [refreshing, setRefreshing] = useState(false)
  const callerFromClickToCall = useSelector(state => state.clickToCall.caller)
  const cloudnumberFromClickToCall = useSelector(state => state.clickToCall.cloudnumber)
  const originFromWhereCallRequestIsComing = useSelector(state => state.clickToCall.makeCallTrigerValue)
  const sourceTableFromWhereCallRequestIsComing = useSelector(state => state.clickToCall.sourceFromWhereCallerIsComing)
  const dependencyForMakingClickToCall = useSelector(state => state.clickToCall.value)
  const [calling_pattern, setCallingPattern] = useState(false)

  const connectToRoom2 = (sid, Direction) => {

    // console.log("connect to room 2 runs", sid , Direction)
    socket.emit('join', sid)
    socket.on("roomData", (data) => {

      let correctCloudNumber = age ? age : cloudnumberFromClickToCall;
      //console.log("Child Call correctCloudNumber ", correctCloudNumber)
      //console.log("Child Call data from socket ", data)
      // console.log("data outside the if ", data , Direction , sid  )
      // console.log("Direction in api outbound ", Direction)
      if (data !== null && Direction === "outbound") {
        localStorage.setItem("CallSid", data.ParentCallSid)
        // console.log("data inside the if  ", data , phoneNumber , phoneNumber.slice(-12) , data.To ,  data.To.slice(-12) ,  Direction , sid , age , isCallIncomingDirection )

        { data.AccountSid === authId ? setWebState(data.ParentCallSid) : ReturnNull() };
        { data.CallStatus == "ringing" ? openDialing() : ReturnNull() }
        { data.CallStatus == "ringing" ? localStorage.setItem("inprogressTimestamp", data.Timestamp) : ReturnNull() }
        if (data.CallSid !== data.ParentCallSid) {
          { data.CallStatus == "in-progress" && data.Caller.slice(-8) == correctCloudNumber.slice(-8) ? openWidget() : ReturnNull() }
          { data.CallStatus == "in-progress" && data.Caller.slice(-8) == correctCloudNumber.slice(-8) ? setInitiatedTime(data.Timestamp) : ReturnNull() }
        }
        { data.CallStatus == "completed" ? setAlertOfCompleted(10) : ReturnNull() }
        { data.AccountSid === authId && data.CallStatus == "failed" ? disconnectPhoneCall() : ReturnNull() };
        { data.AccountSid === authId && data.CallStatus == "busy" ? disconnectPhoneCall() : ReturnNull() };
        { data.AccountSid === authId && data.CallStatus == "no-answer" ? disconnectPhoneCall() : ReturnNull() };
      }
    })
  }



  const openDailingWidget = () => {

    setWidjetMainToggle(true);
    setMinimizeMaximize(!minimizeMaximize);
    if (minimizeMaximize == true) {
      setExpandContractBtn(false);
      setExpandContractClass(true);
    } else {
      setExpandContractBtn(true);
    }


    setBottomNav(false)
    setDialToggle(false);
    setDialingToggle(true);
    setCallOnProgress(false);
    setRingingIconShow(false);
    setDialingIconShow(true);
    setDialPadBtn(true)
  }

  const openRingingWidget = () => {
    setDialToggle(true);
    setDialPadBackDrop(true)
    setDialingToggle(true);
    setCallOnProgress(false);
    setRingingIconShow(false);
    setDialingIconShow(true);
    setBottomNav(false);
  }

  const handleOpenDialing = () => {
    // setDirection("outbound-api")
    // getConversationHistory(phoneNumber);
    setConversionHistoryShow(true)
    // from material 

    setBottomNav(false)
    setDialToggle(false);
    setDialingToggle(true);
    setCallOnProgress(false);
    setRingingIconShow(false);
    setDialingIconShow(true);
    setDialPadBtn(true)

  }

  const handleClickToCallFromTable = async (data) => {
    	    //console.log("data", data);
          if (app[0].call_allow === "inb") {
            setErrorMessage("Oops! Outbound Not Allowed");
            setShowError(true)
            return;
          }
    	    setIsCallIncomingDirection(false)
    	    setPhoneNumber(data.Destination.replace("+", ""))
    	
    	    if (data.Destination.length < 10) {
    	      return;
    	    }
    	    const phone_number = data.Destination;
    	    get_contact(phone_number.slice(-10))
    	    // handleOpenDialing()
    	    openDailingWidget()
    	    const responseOfApiCall = await axiosInstance.post('/cloudphone/make-call', data)
    	    let Direction = 'outbound';
    	    let sid = responseOfApiCall.data.data.FriendlyName
    	    setParentCallSid(responseOfApiCall.data.data.sid)
    	    const cloud_number = data.From.slice(-10);
    	    get_phone_details(cloud_number)
    	    //console.log("friendly name in click to call : ", sid)
    	    setFriendlyName(responseOfApiCall.data.data.FriendlyName)
    	    socket.emit('join', sid)
    	    socket.on("roomData", (socket_data) => {
    	
    	      if (socket_data !== null && socket_data !== undefined && socket_data !== "undefined") {
    	        //console.log("Child Call data from socket ", socket_data)
    	        if (call_data.CallStatus !== "completed") {
    	          setCallData(socket_data)
    	        }
    	
    	        if (socket_data.AccountSid === authId) {
    	          setWebState(socket_data.ParentCallSid)
    	        }
    	
    	        //console.log("called", socket_data.Called);
    	        //console.log("phone_number", phoneNumber)
    	
    	        if (socket_data.CallStatus === "in-progress" && socket_data.Called.slice(socket_data.Called.length - 10, socket_data.Called.length) === phone_number.slice(-10)) {
    	          openWidget()
    	        } else if (socket_data.CallStatus === "ringing" && socket_data.Called.slice(-10) === phone_number.slice(-10)) {
    	          openDialing();
    	          setCompletedCall(false)
    	        } else if (socket_data.CallStatus === "completed") {
    	          window.clearInterval(increment.current)
    	          //console.log("call is completed")
    	          setCompletedCall(true)
    	          handlePause()
    	
    	        } else if (socket_data.CallStatus === "no-answer") {
    	          disconnectPhoneCall()
    	        } else if (socket_data.CallStatus === "busy" && socket_data.AccountSid === authId) {
    	          disconnectPhoneCall();
    	        } else if (socket_data.CallStatus === "failed" && socket_data.AccountSid === authId) {
    	          disconnectPhoneCall();
    	        } else if (socket_data.CallStatus === "ringing" && socket_data.Called.slice(-10) !== phone_number.slice(-10)) {
    	          setDialToggle(false);
    	          setRingingIconShow(false);
    	          setDialingIconShow(true);
    	          setBottomNav(false)
    	          setDialPadBtn(true)
    	          setCompletedCall(false)
    	        }
    	      }
    	
    	    })
    	  }

  useEffect(() => {

    

    if (originFromWhereCallRequestIsComing === "clickToCall" && dependencyForMakingClickToCall > 0) {

      

      // const data = { 
      //   From: cloudnumberFromClickToCall ,
      //   authID: authId, 
      //   authSecretID: authSecretId, 
      //   To: userMobile , 
      //   Method : "GET", 
      //   Url :`${baseUrl}/ide/success_phone/${callerFromClickToCall}`
      // }
      const data = {
        From: cloudnumberFromClickToCall,
        To: userMobile,
        Source: "Web",
        Destination: callerFromClickToCall
      }

      //console.log("Click to call useEffect runs", callerFromClickToCall, cloudnumberFromClickToCall, originFromWhereCallRequestIsComing, sourceTableFromWhereCallRequestIsComing, dependencyForMakingClickToCall)
      handleClickToCallFromTable(data)
      //console.log(data)


      //   console.log("Click to call useEffect runs" , callerFromClickToCall , cloudnumberFromClickToCall , originFromWhereCallRequestIsComing , sourceTableFromWhereCallRequestIsComing , dependencyForMakingClickToCall , data)

      //  axiosInstance.post('/ide/final', data)
      //   .then(response=> {
      //       // setparentSid(response.data.data.sid);
      //       // handleRoom(response.data.data.sid);
      //       let sid = response.data.data.sid || '';
      //       let Direction = "outbound";
      //       let calledNumber = callerFromClickToCall.slice(-10);
      //       openDailingWidget()
      //       socket.emit('join', sid)
      //       socket.on("roomData", (data)=>{

      //         let correctCloudNumber = age ? age : cloudnumberFromClickToCall.slice(-8);
      //         console.log("Click to Call correctCloudNumber ", correctCloudNumber , calledNumber, data.Called.slice(-12))

      //         // console.log("data outside the if ", data , Direction , sid  )
      //         // console.log("Direction in api outbound ", Direction)
      //         if(data !== null && data.Called.slice(-10) === calledNumber){
      //             localStorage.setItem("CallSid", data.ParentCallSid )
      //             console.log("Click to  Call data from socket ", data)
      //            // console.log("data inside the if  ", data , phoneNumber , phoneNumber.slice(-12) , data.To ,  data.To.slice(-12) ,  Direction , sid , age , isCallIncomingDirection )

      //             {data.AccountSid === authId ? setWebState(data.ParentCallSid) : ReturnNull() };
      //             {data.CallStatus == "ringing" ? openDialing(): ReturnNull()}
      //             {data.CallStatus == "ringing" ? localStorage.setItem("inprogressTimestamp", data.Timestamp) : ReturnNull()}
      //             // if(data.CallSid !== data.ParentCallSid){
      //               {data.CallStatus == "in-progress" && data.Caller.slice(-8) == correctCloudNumber ? openWidget(): ReturnNull()}
      //               {data.CallStatus == "in-progress" && data.Caller.slice(-8) == correctCloudNumber ? setInitiatedTime(data.Timestamp): ReturnNull()}
      //             // }
      //             {data.CallStatus == "completed"? setAlertOfCompleted(10) : ReturnNull()}
      //             {data.AccountSid === authId && data.CallStatus == "failed" ? disconnectPhoneCall() : ReturnNull() };
      //         }
      //       })



      //   })
      //   .catch(error=> console.log(error))

    }
  }, [dependencyForMakingClickToCall])



  //this function makes call after gathering all the data required for making call
  //it is called inside the handle submit function -line:627
  const handleCall = async () => {
    if (app[0].call_allow === "inb") {
      setErrorMessage("Oops! Outbound Not Allowed");
      setShowError(true)
      return;
    }
    if (age === "") {
      if (defaultCallingNumber ===  null) {
        setErrorMessage("Please select cloud number");
        setShowError(true)
        return;
      }
    }
    if (phoneNumber.length < 10) {
      return;
    }
    setCalling(true)
    setTimer(0)

    const number = age ? age : defaultCallingNumber;


    const phone_data = {
      "From": number,
      "To": userMobile,
      "Destination": `+${selected_country.phone}${phoneNumber}`,
      "Source": "Web"
    }
    const Caller = phone_data.From.slice(-10)
    get_phone_details(Caller);


    const phone_number = phoneNumber.slice(-10);
    axios({
      method: "GET",
      url: `${baseUrl}/contacts?phoneNumber=${phone_number}`,
      headers: {
        authorization: localStorage.getItem('redux-token')
      }
    }).then(async (contact_data) => {
      const response = contact_data.data.data.data;
      console.log("response contact", response)
      if (response.length > 0) {
        setContact(response);
        window.clearInterval(increment.current)
        const responseOfApiCall = await axiosInstance.post('/cloudphone/make-call', phone_data)
        let Direction = 'outbound';
        let sid = responseOfApiCall.data.data.FriendlyName
        setParentCallSid(responseOfApiCall.data.data.sid)
        setCalling(false)
        setFriendlyName(responseOfApiCall.data.data.FriendlyName)
        setBottomNav(false)
        setDialToggle(false);
        setDialingToggle(true);
        setDialPadBackDrop(false)
        setCallOnProgress(false);
        setRingingIconShow(false);
        setDialingIconShow(true);
        setDialPadBtn(true)
        setIsCallIncomingDirection(false)
        setConversionHistoryShow(true)
        // from material 
        get_socket_data(sid)
        
      } else {
        const data = new FormData();
        const form_data = {
          "firstName": "Unknown",
          "lastName": "",
          "phoneNumber": `${selected_country.phone}${phoneNumber}`,
          "isActive": true,
          "CustomVariables": [],
          "Notes": [],
          "Tasks": []
        }

        data.append("payload", JSON.stringify(form_data));

        axios({
          method: "POST",
          url: `${baseUrl}/contacts`,
          headers: {
            authorization: localStorage.getItem('redux-token'),
            "Content-Type": "multipart/form-data"
          },
          data: data

        }).then((res) => {
          const contact_data = res.data;
          axios({
            method: "GET",
            url: `${baseUrl}/contacts?phoneNumber=${phone_number}`,
            headers: {
              authorization: localStorage.getItem('redux-token')
            }
          }).then(async (contact) => {
            const contact_data = contact.data;
            setContact(contact_data.data.data);
            window.clearInterval(increment.current)
            const responseOfApiCall = await axiosInstance.post('/cloudphone/make-call', phone_data)
            let Direction = 'outbound';
            let sid = responseOfApiCall.data.data.FriendlyName
            setParentCallSid(responseOfApiCall.data.data.sid)

            setFriendlyName(responseOfApiCall.data.data.FriendlyName)
            setIsCallIncomingDirection(false)
            setConversionHistoryShow(true)
            setBottomNav(false)
            setDialToggle(false);
            setCalling(false)
            setDialingToggle(true);
            setDialPadBackDrop(false)
            setCallOnProgress(false);
            setRingingIconShow(false);
            setDialingIconShow(true);
            setDialPadBtn(true)
            get_socket_data(sid)
          }).catch((err) => {
            //console.log("error_response", err)
          })
        }).catch((err) => {
          //console.log("error_response", err)
        })
      }
    }).catch((err) => {
      //console.log("error_response", err)
    })

  }

  const get_power_dialer_socket_data = (sid, phone_number) => {
    console.log("phone_number",phone_number)
    
    socket.emit('join', sid)
    socket.on("roomData", (data) => {
      console.log("power_dialer_data", data)

      if (data !== null && data !== undefined && data !== "undefined") {
        //console.log("Child Call data from socket ", data)
        setCallData(data)

        if (data.AccountSid === authId) {
          setWebState(data.ParentCallSid)
        }

        //console.log("called", data.Called);
        //console.log("phone_number", phoneNumber)
        
        console.log("caled", data.Called.slice(-10), phone_number.slice(-10))
        if (data.CallStatus === "in-progress" && data.Called.slice(-10) === phone_number.slice(-10)) {
          openWidget()
        } else if (data.CallStatus === "ringing" && data.Called.slice(-10) === phone_number.slice(-10)) {
          openDialing();
        } else if (data.CallStatus === "completed") {
          window.clearInterval(increment.current)
          //console.log("call is completed")
          setCompletedCall(true)
          handlePause()

        } else if (data.CallStatus === "no-answer") {
          disconnectPhoneCall()
        } else if (data.CallStatus === "busy") {
          disconnectPhoneCall();
        } else if (data.CallStatus === "failed") {
          disconnectPhoneCall();
        } else if (data.CallStatus === "ringing" && data.Called.slice(-10) !== phone_number.slice(-10)) {
          setDialToggle(false);
          setRingingIconShow(false);
          setDialingIconShow(true);
          setBottomNav(false)
          setDialPadBtn(true)
        }
      }

    })
  }

  //console.log("increment", increment.current)

  const get_socket_data = (sid) => {
    socket.emit('join', sid)
    socket.on("roomData", (data) => {

      if (data !== null && data !== undefined && data !== "undefined") {
        // console.log("Child Call data from socket ", data)
        if (call_data.CallStatus !== "completed") {
          setCallData(data)
        }
        

        if (data.AccountSid === authId) {
          setWebState(data.ParentCallSid)
        }

        //console.log("called", data.Called);
        //console.log("phone_number", phoneNumber)

        if (data.CallStatus === "in-progress" && data.Called.slice(data.Called.length - 10, data.Called.length) === phoneNumber.slice(phoneNumber.length - 10, phoneNumber.length)) {
          openWidget()
        } else if (data.CallStatus === "ringing" && data.Called.slice(-10) === phoneNumber.slice(-10)) {
          openDialing();
          setCompletedCall(false)
        } else if (data.CallStatus === "completed") {
          window.clearInterval(increment.current)
          
          //console.log("call is completed")
          setCompletedCall(true)
          handlePause()

        } else if (data.CallStatus === "no-answer") {
          disconnectPhoneCall()
        } else if (data.CallStatus === "busy") {
          disconnectPhoneCall();
        } else if (data.CallStatus === "failed") {
          disconnectPhoneCall();
        } else if (data.CallStatus === "ringing" && data.Called.slice(-10) !== phoneNumber.slice(-10)) {
          setDialToggle(false);
          setRingingIconShow(false);
          setDialingIconShow(true);
          setBottomNav(false)
          setDialPadBtn(true)
          setCompletedCall(false)
        }
      }

    })
  }

  

  //console.log("call_data", call_data)
  console.log("country_list1", country_list1)
  const [numberHistory, setNumberHistory] = useState([]);
  const getConversationHistory = async (number) => {
    await axiosInstance.get(`conversation_details?number=${number}`)
      .then(response => {
        // console.log("getConversationHistory response ", response.data.data)
        setNumberHistory(response.data.data)
      })
      .catch(error => console.log(error))
  }

  const getColorDot = (tags) => {
    switch (tags) {
      case "Not Interested":
        return <StopIcon className="notinterested" />
        break;

      case "Interested":
        return <StopIcon className="interested" />
        break;

      case "No Answer":
        return <StopIcon className="busy" />
        break;

      default:
        return <StopIcon className="wrongnumber" />
        break;
    }
  }



  // React.useEffect(()=>{
  //   console.log("Call the number coming from call history ", phone_number_from_call_history)
  //   setPhoneNumber(phone_number_from_call_history)
  //   handleSubmit();
  // },[makeCallSwitch])

  //this function helps to gennerate all the required data for making call
  const handleSubmit = async () => {
    setDirection("outbound-api")
    handleCall();
    const Number = phoneNumber.slice(phoneNumber.length - 10, phoneNumber.length);
    get_contact(Number);
    getConversationHistory(phoneNumber);
  }


  const options = [
    'Not Interested',
    'Interested',
    'No Answer',
    'Follow Up',
    'Do Not Call',
  ];


  //WebRTC call start from here-------------------
  // let g_client 
  if (app.length > 0) {
    if (app[0].sip_active === true) {
      var g_client = new SiprtcWebRTCSDK("phone.vibconnect.io")
    }
  }
  //console.log("webrtc ", g_client)


  var incomingCallAudio = new window.Audio('/dist/media/phonerng.wav');
  incomingCallAudio.loop = true;
  incomingCallAudio.crossOrigin = "anonymous";



  var outgoingProgressCallAudio = new window.Audio('/dist/media/6rings.wav');
  // var outgoingProgressCallAudio = new Audio('https://ivraudiofiles.s3.eu-central-1.amazonaws.com/public/sorry_conference.mp3');
  outgoingProgressCallAudio.loop = true;
  outgoingProgressCallAudio.crossOrigin = "anonymous";

  var outgoingRBTAudio = new window.Audio('/dist/media/rbt.ogg');
  outgoingRBTAudio.loop = true;
  outgoingRBTAudio.type = 'audio/ogg'
  outgoingRBTAudio.crossOrigin = "anonymous";

  const [incoming_number, setIncomingNumber] = React.useState('')
  const callback = (status, event) => {
    //console.log("event", event)
    let our_event = JSON.stringify(event);
    console.log("Event: ", our_event);
    //console.log("Status :" + status);
    //console.log("Status :" + our_event);


    switch (status) {
      /* Server status */
      case 'connected':
        // sip user websocket connected.
        console.log("sip user websocket connected.")

        // setCallConnected(true) //wrong decleration
        break;

      case 'disconnected':
        // sip user websocket disconnected
        console.log("sip user websocket disconnected")
        // setDialToggle(false);
        // setDialPadBtn(true)
        // setCallOnProgress(false);
        // setCallConnected(false)
        setTimeout(() => {
          register();
        }, 30000);
        break;

      case 'connecting':
        // sip user registration websdk is connecting websocket.
        console.log("sip user registration websdk is connecting websocket.")
        break;

      case 'registration_failed':
        // sip registraion failed.
        console.log("sip registraion failed.")
        break;

      case 'registered':
        // sip user registered. User can make voice call. 
        console.log("sip user registered. User can make voice call.")
        // updateUI();
        break;

      /* Call status */
      case 'outgoingcall':
        // outgoing call is in progress. caller is not yet ringing. get remote user name using `event.remoteuser`.
        console.log("call info outgoing ", event.remoteuser)
        console.log("outgoing call is in progress. caller is not yet ringing. get remote user name using `event.remoteuser`.")
        outgoingProgressCallAudio.play()

        break;

      case 'incomingcall':
        openDialingIncoming()
        window.clearInterval(increment.current)
        setIncomingSip(true)
        setRingingSip(true)
        setCompletedCall(false)
        setIsCallIncomingDirection(true)
        setIncomingNumber(event.remoteuser)
        setBottomNav(false)
        setDialToggle(false);
        setDialingToggle(true);
        setDialPadBackDrop(false)
        setCallOnProgress(false);
        setRingingIconShow(true);
        setDialingIconShow(false);
        setDialPadBtn(true)
        setPhoneNumber(event.remoteuser);

        get_contact(event.remoteuser.slice(-10)).then(async(res) => {
          //console.log("response_data", res)
          if (res.length === 0) {
            await create_contact(event.remoteuser).then(async(res) => {
              get_contact(event.remoteuser.slice(-10)).then((res) => {
                //console.log("response_data", res)
              }).catch((err) => {
                //console.log("error_response", err)
              })
            }).catch((err) => {
              //console.log("error_response", err)
            })
          }
        }).catch((err) => {
          //console.log("error_response", err)
        })
        // incomingCall()

        //console.log("call info incoming ", event.remoteuser)
        // incoming call handling. `event.remoteuser` gives you remote user / callee name. 
        console.log("incoming call handling. `event.remoteuser` gives you remote user / callee name.")
        break;

      case 'progress':
        console.log("For the outgoing call remote user / caller is ringing. Play Ring back tone for the callee who makes outgoing call.")
        // For the outgoing call remote user / caller is ringing. Play Ring back tone for the callee who makes outgoing call.
        outgoingProgressCallAudio.pause();
        outgoingRBTAudio.play();
        // setDialToggle(false);
        // // setDialingToggle(true);
        // setRingingIconShow(true);
        // setDialingIconShow(false);
        // setBottomNav(false)
        // setDialPadBtn(true)

        break;

      case 'failed':
        setCallOnProgress(false)
        setRingingSip(false);
        setIncomingSip(false);
        setDialingToggle(false)
        setBottomNav(true);
        incomingCallAudio.pause();
        outgoingProgressCallAudio.pause();
        outgoingRBTAudio.pause();
        break;
      case 'ended':

        // setDialToggle(false);
        // setCallOnProgress(false);
        // setBottomNav(true);
        incomingCallAudio.pause();
        outgoingProgressCallAudio.pause();
        outgoingRBTAudio.pause();
        console.log("call is ended or failed")
        setPhoneNumber('')
        window.clearInterval(increment.current)
          //console.log("call is completed")
          setCompletedCall(true)
          handlePause()
        break;

      case 'confirmed':

        incomingCallAudio.pause();
        outgoingProgressCallAudio.pause();
        outgoingRBTAudio.pause();
        console.log("call is connected. voice should be heard to and from in both direction. ")
        break;

      case 'accepted':
        // inbound call is answered and accepted. 
        openWidget()
        console.log("inbound call is answered and accepted.")
        // setRingingSip(false)
        openWidget();
        break;

      case 'ringing':
        // incoming call is there. Play ringing for the local user.
        openDialingIncoming()
        incomingCallAudio.play();
        setIncomingSip(true)
        setCompletedCall(false);
        setRingingSip(true)
        setIsCallIncomingDirection(true)
        setIncomingSip(true)
        setIncomingNumber(event.remoteuser)
        setBottomNav(false)
        setDialToggle(false);
        setDialingToggle(true);
        setDialPadBackDrop(false)
        setCallOnProgress(false);
        setRingingIconShow(true);
        setDialingIconShow(false);
        setDialPadBtn(true)
        setPhoneNumber(event.remoteuser);

        console.log("incoming call is there. Play ringing for the local user.")
        get_contact(event.remoteuser.slice(-10)).then(async(res) => {
          //console.log("response_data", res)
          if (res.length === 0) {
            await create_contact(event.remoteuser).then(async(res) => {
              get_contact(event.remoteuser.slice(-10)).then((res) => {
                //console.log("response_data", res)
              }).catch((err) => {
                //console.log("error_response", err)
              })
            }).catch((err) => {
              //console.log("error_response", err)
            })
          }
        }).catch((err) => {
          //console.log("error_response", err)
        })
        break;

      case 'unmuted':
        // user unmute success.
        //console.log("user unmute success.")
        break;

      case 'muted':
        // user mute success. 
        //console.log(" user mute success.")
        break;

      default:
      // this should never occur. contact support to fix the issue. 
    }
  }

  // updateUI();

  const create_contact = (phoneNumber) => {
    const userID = localStorage.getItem("userID")
    return new Promise((resolve, reject) => {
      const data = new FormData();
        const form_data = {
          "firstName": "Unknown",
          "lastName": "",
          "phoneNumber": `${selected_country.phone}${phoneNumber}`,
          "isActive": true,
          "CustomVariables": [],
          "Notes": [],
          "Tasks": [],
        }

        data.append("payload", JSON.stringify(form_data));

        axios({
          method: "POST",
          url: `${baseUrl}/contacts`,
          headers: {
            authorization: localStorage.getItem('redux-token'),
            "Content-Type": "multipart/form-data"
          },
          data: data

        }).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        })
    })
  }

  //console.log("ringing_sip", ringing_sip, "incoming_sip", incoming_sip)


  const answerCall = () => {
    // const ret = g_client.Answer()
    setIncomingCallToggle(false);
    setCallOnProgress(true);
    g_client.Answer();
  }
  const declineCall = () => {
    // const ret = g_client.Hangup()
    // g_client.Hangup();
    // setIncomingCallToggle(false);
    setDialingToggle(false)
    disconnectWebRTCCall();
  }



  const call = () => {
    window.clearInterval(increment.current)
    setCalling(true);
    get_phone_details(age).then(async(res) => {
      //console.log("phone_details", res)
    const phone_number = phoneNumber.slice(-10);
    await get_contact(phone_number).then(async(res) => {
      //console.log("response", res);
      const phone_number1 = `+${selected_country.phone}${phoneNumber}`
      if (res.length === 0) {
        //console.log("phone_number1", phone_number1)
        await create_contact(phone_number1).then(async(res) => {
          await get_contact(phone_number).then((res) => {
            //console.log("get_contact_response", res)
            const fromCallerID = age
    //console.log("fromCallerID", fromCallerID)

    // const ret = g_client.Call(destination, callback);
    // console.log("phone number ", phoneNumber , age)

    const ret = g_client.Call(phone_number1, callback, fromCallerID);
    //console.log("ret", ret)
    

    if (ret === "success") {
      setCalling(false)
      setRingingSip(true)
        setBottomNav(false)
        setDialToggle(false);
        setDialingToggle(true);
        setDialPadBackDrop(false)
        setCallOnProgress(false);
        setRingingIconShow(true);
        setDialingIconShow(false);
        setDialPadBtn(true)
        setIsCallIncomingDirection(false)
    }
          }).catch((err) => {
            //console.log("error", err)
          })
        }).catch((err) => {
          //console.log("error", err)
        })
      } else {
        const fromCallerID = age
    //console.log("fromCallerID", fromCallerID)

    // const ret = g_client.Call(destination, callback);
    // //console.log("phone number ", phoneNumber , age)

    const ret = g_client.Call(phone_number1, callback, fromCallerID);
    //console.log("ret", ret)
    

    if (ret === "success") {
      setCalling(false)
        setBottomNav(false)
        setDialToggle(false);
        setDialingToggle(true);
        setDialPadBackDrop(false)
        setCallOnProgress(false);
        setRingingIconShow(true);
        setDialingIconShow(false);
        setDialPadBtn(true)
        setIsCallIncomingDirection(false)
    }
      }
    }).catch((err) => {
      //console.log("error", err)
    })
    if (ret !== "success") {
      // $('#errorMessageId').html(ret)
      //console.log("if od call ", ret)
    }
    }).catch((err) => {
      console.error("error", err)
    })
    // const fromCallerID = "61291342789"

    //for india mart
    // const fromCallerID = "442039121282"
  }


  // const SipID = localStorage.getItem('sip_user');
  // const SipPassword = localStorage.getItem('sip_password');
  //this one is working 
  const SipID = user.sip_user
  const SipPassword = user.sip_password
  //this one working 
  // const SipID = "sip:vibtest1624533219892@phone.vibconnect.io"
  // const SipPassword = "vibtest"

  //this is for india mart 
  // const SipID = "sip:Subodh1630315475172@phone.vibconnect.io"
  // const SipPassword = "Tiger1234"


  const register = () => {
    if (app.length > 0) {
      if (app[0].sip_active === true) {
        if (number_of_webrtc_connection < 3) {
          const ret = g_client.Initialize(app[0].sip_id, app[0].sip_password, callback);
          setNumberOfWebrtcConnection(parseInt(number_of_webrtc_connection)+1);
        }
      }
    }
  }


  const set_Mute_type = () => {
    if (mute === 0) {
      return (
        <MicOffOutlinedIcon />
      )
    }
    if (mute === 1) {
      return (
        <MicNoneOutlined />
      )
    }

  }



  const muteCall = () => {
    g_client.Mute({
        audio: true
    });
    setMute(true)
  }

  const unMuteCall = () => {

    g_client.UnMute({
        audio: true
    });
    setMute(false)
  }

  let WebRTC_CallSid = localStorage.getItem("WebRTC_CallSid");







  const send_Corresponding_Sid = async () => {
    // await axios.post('http://localhost:5000/sid', {username : username ,sid: WebState })
    await axios.post('https://vibtreenamecollection.herokuapp.com/sid', { username: username, sid: WebState })
      .then(response => {
        //console.log("response of sending username and sid ", response);
      })
      .catch(error => {
        //console.log("error from sid ", error);
      })
  }


  const filter_sid_for_webrtc = () => {

    //this function grab the call sid from the webrtc socket but this is wrong process
    // io.connect("https://vibtree3.herokuapp.com").on('test', (data)=>{
    io.connect("https://crm-backend-non-strapi.herokuapp.com").on('test', (data) => {
      // console.log("data ", data)
      // {data.AccountSid === authId ? setWebState(data.CallSid) : ReturnNull() };
      { data.AccountSid === 'XAS1XW4GG36SBSFL864B' ? setWebState(data.CallSid) : ReturnNull() };
      // {data.CallStatus == "ringing" ? openDialing(): ReturnNull()};
      // {data.CallStatus == "in-progress" && data.To == phoneNumber ? openWidget():  ReturnNull()};
    })
  }




  const storeWebRTCRecord = async () => {
    // console.log("webrtc sid to be sent ", WebRTC_CallSid)
    setIsCallIncomingDirection(false)
    await axiosInstance.post('/ide/save',
      {


        username: username,
        To: userMobile,
        sid: WebState,
        notes: callNotes,
        tags: inputValue,
        dialedNo: phoneNumber,
        cloudNumber: "WebRTC Call"

      })
      .then(response => {
        //console.log("response of webRTC ", response);
        send_Corresponding_Sid();

      })
      .catch(error => {

      })
  }


  const disconnectWebRTCCall = () => {
    //set timer to zero
    if (completed_call === false) {
      g_client.Hangup();
    }
    if (incoming_sip === true) {
      setIncomingSip(false)
    }
    handleReset();
    setRingingSip(false)
    setCallConnected(false)
    setCompletedCall(false)
    setPhoneNumber('')
    // console.log("hangup working ")
    // storeWebRTCRecord();
    setDialToggle(false);
    setDialPadBtn(true)
    setCallOnProgress(false);
    setBottomNav(true);
    // send_Corresponding_Sid();
    // const ret = g_client.Hangup()
  }

  useEffect(() => {
    register();
    if (app.length > 0) {
      if (app[0].cloudNumber_allow.length > 0) {
        const default_cloud_number = localStorage.getItem("default_cloud_number");
        console.log("default_cloud_number", default_cloud_number);
        if (default_cloud_number === null || default_cloud_number === "" || default_cloud_number === undefined) {
          localStorage.setItem("default_cloud_number", app[0].cloudNumber_allow[0].phone_number)
          setAge(app[0].cloudNumber_allow[0].phone_number)
        } else {
          setAge(default_cloud_number)
        }
      }
    }
  }, [])
  // call();
  let [nameToBeDisplayed, setNameToBeDisplayed] = useState([])

  const [open_modal, setOpenModal] = useState(false);

  const render_counter = useSelector((state) => state.renderCounter.value)
  const [showPhoneCallType, setShowPhoneCallType] = React.useState(false)
  const [showBrowserCallType, setShowBrowserCallType] = React.useState(false)
  const [blacklist, setBlacklist] = useState(false)
  const [power_dialer_call_status, setPowerDialerCallStatus] = useState("All")
  const [status, setStatus] = useState(false)
  // console.log("allowed call type ", showBrowserCallType, showPhoneCallType)
  const [team, setTeam] = useState(false)

  const connectToInboundSocket = () => {
    if (app[0].call_allow === "out") {
      return;
    }
    const socket = io(`${REACT_APP_BASE_URL}/inbound`, {
      // const socket = io(`https://mibsback.vibtree.com/inbound`, {
      path: "/websockets",
      transports: ["polling", "websocket"],
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: "ApiKey <Key>",
          },
        },
      },
    });

    const phone = localStorage.getItem("phone")
    //console.log("phone in make-call : ", phone)
    socket.emit("join_room", phone)
    //console.log("socket join :")
    socket.on('realtime_inbound', (data) => {
      //console.log("realtime_inbound data :: ", data)

      if (data.length > 0) {
        if (data[0].Direction === 'inbound') {
          setCallData(data[0]);
          const realtime_data = data[0];
          const Number = realtime_data.Caller.slice(-10);
          if (contact.length === 0) {
            get_contact(Number);
          } else {
            const phoneNumber = contact[0].phoneNumber.slice(-10);
            if (phoneNumber !== Number) {
              get_contact(Number)
            }
          }
          if (phoneDetails.length === 0) {
            get_phone_details(realtime_data.Called.slice(-10));
          } else {
            if (phoneDetails[0].phone_number.slice(-10) !== realtime_data.Called.slice(-10)) {
              get_phone_details(realtime_data.Called.slice(-10));
            }
          }
          setPhoneNumber(data[0].Caller.slice(-12))
          setParentCallSid(data[0].ParentCallSid)
          setFriendlyName(data[0].FriendlyName)

        }
      }
    });
  }

  const [contact, setContact] = useState([])

  const get_contact = (number) => {
    //console.log("contact_number", number)
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `${baseUrl}/contacts?phoneNumber=${number}`,
        headers: {
          authorization: localStorage.getItem('redux-token')
        }
      }).then((res) => {
        const response = res.data.data.data;
        //console.log("contact_response", response)
        if (response.length > 0) {
          if (user.phone !== undefined) {
            if (number === user.phone.slice(-10)) {
              dispatch({
                type: SET_AGENT_CONTACT,
                payload: response
              })
            } else {
              setContact(response)
            }
          } else {
            setContact(response)
          }
        }
        resolve(response)
      }).catch((err) => {
        //console.log("error_response", err);
        reject(err)
      })
    })
  }

  //console.log("user", user)

  //console.log("contact", contact);

  useEffect(() => {
    if (app.length > 0) {
      connectToInboundSocket()
    }
  }, [socket])

  React.useEffect(() => {
    const phone = localStorage.getItem("phone")
    if (app.length === 0) {
      return;
    }
    if (app[0].call_allow === "out") {
      return;
    }
    //console.log("phone in make-call : ", phone)
    window.clearInterval(increment.current)
    socket.emit("join_incoming", phone)
    socket.on("roomData_incoming", (data) => {
      //console.log("data inside incoming ", data, isCallIncomingDirection, incomingCustomerNumber)
      setIsCallIncomingDirection(true)
      if (data !== null) {
        //console.log("user incoming roomData ", data)
        setIncomingCustomerNumber(data.Caller)
        setCallStatus(data.CallStatus)
        if (data.AccountSid === authId) {
          setWebState(data.ParentCallSid)
        }

        if (data.CallStatus === "initiated") {
          setCompletedCall(false)
          window.clearInterval(increment.current)
          openDialingIncoming();
        } else if (data.CallStatus === "in-progress" && data.To.slice(-12) == phone) {
          setCompletedCall(false)
          openWidget();
        } else if (data.CallStatus === "completed") {
          window.clearInterval(increment.current)
          handlePause()
          setCompletedCall(true)
        } else if (data.CallStatus == "failed") {
          disconnectPhoneCall()
        } else if (data.CallStatus == "canceled") {
          disconnectPhoneCall()
        } else if (data.CallStatus == "busy") {
          disconnectPhoneCall()
        } else if (data.CallStatus == "no-answer") {
          disconnectPhoneCall()
        } else if (data.CallStatus === "ringing") {
          setCompletedCall(false)
          window.clearInterval(increment.current)
        }
        // setIncomingCustomerNumber(data.From)


      }
    })




  }, [socket])

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handle_close = () => {
    setAnchorEl(null);
  };



  React.useEffect(() => {

    setDirection("inbound")
    setTimer(0);

    if (Object.keys(user).length > 0) {
      setusername(user.username);
      setpassword(user.password);
      setuserMobile(user.phone);
      setauthSecretId(user.auth_secret);
      setpurchasedNumber(user.purchasedNumber);
      setShowPhoneCallType(user.phone_allowed);
      setShowBrowserCallType(user.browser_allowed);
      localStorage.setItem("phone", user.phone)
    }

  }, [refresh, render_counter, user]);
  //console.log("removed_dulicate_array", removed_dulicate_array)

  const search_number = (value) => {
    var numbers = [];
    setSearchCloudNumber(value)
    for (let i = 0; i < cloud_numbers.length; i++) {
      if (cloud_numbers[i].phone_number.includes(value) === true) {
        numbers.push(cloud_numbers[i])
      }
    }
    setRemovedArray(numbers)
  }

  //----------------------------------------------------------------
  const get_phone_details = (Caller) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `${baseUrl}/search_by_number?number=${Caller}`,
        headers: {
          authorization: localStorage.getItem('redux-token')
        }
      }).then((res) => {
        const response = res.data;
        //console.log("phone_details", response)
        if (response.status === true) {
          setPhoneDetails(response.data)
        }
        resolve(response.data)
      }).catch((err) => {
        //console.log("error_response", err)
        reject(err)
      })
    })
  }

  useEffect(() => {
    if (call_data.Direction !== "inbound") {
      //console.log("CallStatus", call_data.CallStatus)
      if (call_data.CallStatus === "completed") {
        handlePause()
      }
    }
  }, [call_data.CallStatus])

  //console.log('increment', increment.current, timer)




  const CallType = () => {
    if (callFormPhoneBrowser === 'Browser') {
      //console.log("call from browser selected")
      if (age === "") {
        if (defaultCallingNumber ===  null) {
          setErrorMessage("Please select cloud number");
          setShowError(true)
          return;
        }
      }
      if (phoneNumber.length < 10) {
        return;
      }
      if (app.length === 0) {
        return;
      }
      if (app[0].call_allow === "inb") {
        setErrorMessage("Oops! Outbound Not Allowed");
        setShowError(true)
        return;
      }
      if (app[0].sip_active !== true) {
        setErrorMessage("Internet calling is inactive");
        setShowError(true);
        return;
      }
      call();
      setRingingSip(true)
      // register();

    }
    if (callFormPhoneBrowser === 'Phone') {
      // //console.log("call from phone selected")
      handleSubmit();

    }
    if (callFormPhoneBrowser === null) {
      //this is a temporary change of handle submit
      handleSubmit();
      // //console.log("nothing selected")
      // alert("selected call type")
    }
  }


  const disconnectBeforeConnect = () => {
    if (callFormPhoneBrowser === 'Browser') {
      setDialToggle(false)
      setDialingToggle(false)
      setDialingIconShow(false)
      setRingingIconShow(false)
      setBottomNav(true)
      setDialPadBtn(true)
      // const ret = g_client.Hangup()
      // disconnectWebRTCCall();

    }
    if (callFormPhoneBrowser === 'Phone') {
      // console.log("call from phone selected")
      disconnectPhoneCall();

    }
    if (callFormPhoneBrowser === null) {
      // console.log("nothing selected")
      disconnectPhoneCall();
    }
  }

  const disconnectType = () => {

    if (incoming_sip === true) {
      disconnectWebRTCCall();
    }
    
    if (callFormPhoneBrowser === 'Browser') {
      // console.log("call from browser selected")
      if (incoming_sip === false) {
        if (ringing_sip === false) {
          disconnectPhoneCall().then((res) => {
            setCompletedCall(false);
          }).catch((err) => {
            //console.log("error", err)
          });
        } else {
          disconnectWebRTCCall();
        }
      } else {
        disconnectWebRTCCall();
      }

    }
    if (callFormPhoneBrowser === 'Phone') {
      // console.log("call from phone selected")
      disconnectPhoneCall().then((res) => {
        setCompletedCall(false);
      }).catch((err) => {
        //console.log("error", err)
      });

    }
    if (callFormPhoneBrowser === null) {
      // console.log("nothing selected")
      disconnectPhoneCall();
    }
  }



  //----------------------------------




  const handleClose = () => {
    setOpen(false);
  };

  function handleClickBtn(btnValue) {
    //console.log("btnValue", btnValue)
    const invalue = phoneNumber + btnValue;
    //console.log("invalue", invalue.length, invalue)
    if (invalue.length > 10) {
      return;
    } else {
      setPhoneNumber(invalue)
    }
  };

  const [dialPadBtn, setDialPadBtn] = useState(true)

  const showDial = () => {
    setShowSearchNumbers(false)
    setShowCallType(false)
    setDialToggle(true)
    setDialPadBackDrop(true)
    setDialPadBtn(false)
  }

  const hideDial = () => {
    setShowSearchNumbers(false)
    setShowCallType(false)
    setDialToggle(false)
    setDialPadBackDrop(false)
    setDialPadBtn(true)
  }

  const showAllConversation = () => {
    setShowSearchNumbers(false)
    setShowCallType(false)
    setDialToggle(false);
    setConversation(true);
    setDialPadBackDrop(false)
    setAllConversation(true);
    setSettings(false);
    setDialPadBtn(true)
  }

  const show_power_dialer = () => {
    setShowSearchNumbers(false)
    setShowCallType(false)
    setDialToggle(false);
    setConversation(false);
    setDialPadBackDrop(false)
    setAllConversation(true);
    setSettings(false);
    setDialPadBtn(true)
  }
  const showSettings = () => {
    setShowSearchNumbers(false)
    setShowCallType(false)
    setDialToggle(false);
    setDialPadBackDrop(false)
    setAllConversation(false);
    setSettings(true);
    setDialPadBtn(true)
  }

  const expandWidjet = () => {
    setWidjetMainToggle(!widjetMainToggle);
    setMinimizeMaximize(!minimizeMaximize);
    if (minimizeMaximize == true) {
      setExpandContractBtn(false);
      setExpandContractClass(true);
    } else {
      setExpandContractBtn(true);
    }

  }

  const [load_more_data, setLoadMoreData] = useState(false)



  const [stepOne, setStepOne] = React.useState(true);
  const [stepTwo, setStepTwo] = React.useState(true)
  const [calling, setCalling] = useState(false)
  const setStepThree = () => {
    setStepTwo(!stepTwo);
  }


  const phone_number_from_call_history = useSelector(state => state.phoneNumber.value)
  const makeCallSwitch = useSelector(state => state.makeCallSwitch.value)

  React.useEffect(() => {

    
    if (phone_number_from_call_history !== null && app.length > 0) {

      console.log('refresh trigger call', makeCallSwitch)
      
      if (app[0].call_allow === "inb") {
        setErrorMessage("Oops! Outbound Not Allowed");
        setShowError(true)
        return;
      }
      if (age === "") {
        if (defaultCallingNumber ===  null) {
          setErrorMessage("Please select cloud number");
          setShowError(true)
          return;
        }
      }
    setTimer(0)
    setPhoneNumber(phone_number_from_call_history)
    window.clearInterval(increment.current)
    const number = age ? age : defaultCallingNumber;

    if (makeCallSwitch > 1) {
      
      const data = {
        "From": number,
        "To": userMobile,
        "Destination": phone_number_from_call_history,
        "Source": "Web"
      }

      get_phone_details(number.slice(-10))
      axiosInstance.post('/cloudphone/make-call', data)
        .then(response => {
          setIsCallIncomingDirection(false)
          
          setParentCallSid(response.data.data.sid)
          setparentSid(response.data.data.sid);
          const Number = phone_number_from_call_history.slice(-10);
          get_contact(Number)
          setFriendlyName(response.data.data.FriendlyName)
          socket.emit('join', response.data.data.FriendlyName)
          socket.on("roomData", (data) => {
           
            setCallData(data)

            if (data !== null && data !== undefined && data !== "undefined") {
            
              setCallData(data)
            
              if (data.AccountSid === authId) {
                setWebState(data.ParentCallSid)
              }

              if (data.CallStatus === "in-progress" && data.Called.slice(-10) === phone_number_from_call_history.slice(-10)) {
                openWidget()
                setCompletedCall(false)
              } else if (data.CallStatus === "ringing" && data.Called.slice(-10) === phone_number_from_call_history.slice(-10)) {
                openDialing();
                setCompletedCall(false)
              } else if (data.CallStatus === "completed") {
                window.clearInterval(increment.current)
                setCompletedCall(true)
                handlePause()

              } else if (data.CallStatus === "no-answer") {
                disconnectPhoneCall()
              } else if (data.CallStatus === "busy") {
                disconnectPhoneCall();
              } else if (data.CallStatus === "failed") {
                disconnectPhoneCall();
              }
            }
          })
        })
        .catch(error => console.log("error", error))

      getConversationHistory(phone_number_from_call_history);
      setConversionHistoryShow(true)
      // from material 

      setBottomNav(false)
      setDialToggle(false);
      setDialingToggle(true);
      setCallOnProgress(false);
      setRingingIconShow(false);
      setDialingIconShow(true);
      setDialPadBtn(true)
      }
    }
  }, [makeCallSwitch])




  // TOGGLE WIDGET

  const position = { x: 0, y: 0 }


  interact('.draggable')
    .draggable({
      listeners: {
        start(event) {
          //console.log(event.type, event.target)
        },
        move(event) {
          position.x += event.dx
          position.y += event.dy

          event.target.style.transform =
            `translate(${position.x}px, ${position.y}px)`
        },
      }
    })
    .resizable({
      edges: { top: true, left: true, bottom: true, right: true },
      listeners: {
        move: function (event) {
          let { x, y } = event.target.dataset

          x = (parseFloat(x) || 0) + event.deltaRect.left
          y = (parseFloat(y) || 0) + event.deltaRect.top

          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            height: `${event.rect.height}px`,
            transform: `translate(${x}px, ${y}px)`
          })

          Object.assign(event.target.dataset, { x, y })
        }
      }
    })

  const [widjetClass, setWidjetCalss] = useState('new-call-widget fixed')

  const refresh_calls = () => {
    setRefreshing(true)
    if (show_all_list === true) {
      axios
        .get(`${baseUrl}/api/voice?limit=10&fields=ParentCall&fields=contactId&fields=CallStatus&populate=contactId&fields=ContactNumber&fields=Notes&fields=createdAt&fields=CloudNumber&offset=0&sort=-_id`, {
          headers: {
            authorization: localStorage.getItem('redux-token')
          },
        })
        .then((response) => {
          let data = response.data.data.data
          dispatch({
            type: SET_CALL_OFFSET,
            payload: 0
          })
          let filterData = data.filter(item => { return item.ContactNumber !== undefined })
          dispatch({
            type: SET_CALL_HISTORY,
            payload: filterData
          })
          setRefreshing(false)
          // scrollRef.current.scrollIntoView({
          //   behavior: "smooth",
          //   block: "start",
          //   inline: 'nearest'
          // })
          scrollRef.current.onfocus()
          //console.log("scrollRef", scrollRef)
        }).catch((err) => {
          //console.log("error", err)
        })
    } else {
      axios({
        method: "GET",
        url: `${baseUrl}/api/voice?limit=10&fields=ParentCall&fields=contactId&fields=CallStatus&populate=contactId&fields=ContactNumber&fields=Notes&fields=createdAt&fields=CloudNumber&offset=0&sort=-_id&CallStatus=no-answer&CallStatus=failed&&CallStatus=cancelled`,
        headers: {
          authorization: localStorage.getItem('redux-token')
        }
      }).then((res) => {
        let missed_data = res.data.data.data;
        dispatch({
          type: SET_MISSED_OFFSET,
          payload: 0
        })
        let missed_filterData = missed_data.filter(item => { return item.ContactNumber !== undefined });
        dispatch({
          type: SET_MISSED_CALL,
          payload: missed_filterData
        })
        setRefreshing(false)
        // scrollRef.current.scrollIntoView({
        //   behavior: "smooth",
        //   block: "start",
        //   inline: 'nearest'
        // })
        scrollRef.current.onfocus()
      }).catch((error) => {
        //console.log("error_response", error.response.data)
      })
    }
  }

  const [loading_numbers, setLoadingNumbers] = useState(false);
  const [power_dialer_offset, setPowerDialerOffset] = useState(0)
  const [phone_dialer, setPhoneDialer] = useState([])
  useEffect(() => {
    if (minimizeMaximize === true && conversation === false && allConversation === true && phone_dialer.length === 0) {
      get_power_dialer_data(power_dialer_call_status); 
    }
  }, [power_dialer_call_status, minimizeMaximize, conversation ])


  const get_power_dialer_data = (call_status) => {
    setLoadingNumbers(true);
    var url = `${baseUrl}/power_dialer?populate=contactId&callStatus=${call_status}&limit=10&offset=0&authId=${authId}&userId=${user._id}`;
    if (call_status === "All") {
      url = `${baseUrl}/power_dialer?populate=contactId&limit=10&offset=0&authId=${authId}&userId=${user._id}`
    }
    axios({
      method: "GET",
      url: url,
      headers: {
        authorization: localStorage.getItem('redux-token')
      }
    }).then((res) => {
      const response = res.data;
      setPowerDialerOffset(0);

      //console.log("power_dialer_response", response)
      if (response.data.data.length > 0) {
        setPhoneDialer(response.data.data)
        setPowerDialerTotalCount(response.data.totalCount)
      }
      setLoadingNumbers(false)
    }).catch((err) => {
      //console.log("error_response", err);
    })
  }

  const refresh_power_dialer_data = (call_status) => {
    setPowerDialerRefreshing(true)
    axios({
      method: "GET",
      url: `${baseUrl}/power_dialer?populate=contactId&&callStatus=${call_status}&&limit=10&offset=0&authId=${authId}&userId=${user._id}`,
      headers: {
        authorization: localStorage.getItem('redux-token')
      }
    }).then((res) => {
      const response = res.data;
      setPowerDialerOffset(0);
      //console.log("power_dialer_response", response)
      setPhoneDialer(response.data.data)
      setPowerDialerRefreshing(false)
    }).catch((err) => {
      //console.log("error_response", err);
    })
  }

  const [modal_status, setModalStatus] = useState(false)
  useEffect(() => {
    if (widjetMainToggle) {
      setWidjetCalss('new-call-widget fixed mobile-full-screen')
    }
    if (widjetMainToggle != true) {
      setWidjetCalss('new-call-widget fixed')
    }

  }, [widjetMainToggle])

  const next_call = () => {
    if (app.length === 0) {
      return;
    }
    if (app[0].call_allow === "inb") {
      setErrorMessage("Oops! Outbound Not Allowed");
      setShowError(true)
      return;
    }
    const next_index = parseInt(power_dialer_index) + 1;
    console.log("next_index", next_index);
    disconnectPhoneCall().then((res) => {
      setTimer(0)
      var formData = [...tagsPresentInUserAccount];
        setCallNotes("")
        for (let i = 0; i < tagsPresentInUserAccount.length; i++) {
          const checked = tagsPresentInUserAccount[i].checked;
          console.log("formData", formData, checked);
          if (checked === true) {
            formData[i].checked = false;
          }
        }
        setTagsPresentInUserAccount(formData)
      //console.log("response", res);
      const current_power_dialer_id = phone_dialer[power_dialer_index]._id;
      var Status = call_data.CallStatus;
    if (call_data.CallStatus === "busy" || call_data.CallStatus === "failed" || call_data.CallStatus === "no-answer") {
        Status = "no-answer"
    } else if (call_data.CallStatus === "ringing" || call_data.CallStatus === "in-progress") {
        Status = "completed"
    }
      axios({
        method: "PATCH",
        url: `${baseUrl}/power_dialer/${current_power_dialer_id}`,
        data: {
          callStatus: Status
        },
        headers: {
          authorization: localStorage.getItem('redux-token')
        }
      }).then((res) => {
        //console.log("res_status", res);
        if (next_index < phone_dialer.length) {
          var number = age ? age : defaultCallingNumber
          const contactNumber = phone_dialer[next_index].contactId !== null && phone_dialer[next_index].contactId !== undefined && phone_dialer[next_index].contactId !== "" ? phone_dialer[next_index].contactId.phoneNumber.replace("+", "") : phone_dialer[next_index].contactNumber.replace("+", "");
          dispatch({
            type: SET_POWER_DIALER_INDEX,
            payload: next_index
          })
          setPhoneNumber(contactNumber)
          const phone_data = {
            "From": number,
            "To": user.phone,
            "Destination": contactNumber,
            "Source": "Web"
          }
          axios({
            method: "POST",
            url: `${baseUrl}/cloudphone/make-call`,
            headers: {
              authorization: localStorage.getItem('redux-token')
            },
            data: phone_data
          }).then((responseOfApiCall) => {
            setCallData("")
            if (phone_dialer[next_index].contactId !== undefined && phone_dialer[next_index].contactId !== null && phone_dialer[next_index].contactId !== "") {
              setContact([phone_dialer[next_index].contactId])
            }
            setKilling(false)
            let Direction = 'outbound';
        let sid = responseOfApiCall.data.data.FriendlyName
        setCallConnected(false);
        setCompletedCall(false)
        
        setParentCallSid(responseOfApiCall.data.data.sid)
        setCalling(false)
        setFriendlyName(responseOfApiCall.data.data.FriendlyName)
        setCalling(false)
        setBottomNav(false)
        setDialToggle(false);
        setDialingToggle(true);
        setRingingIconShow(false);
        setDialingIconShow(true);
        setDialPadBackDrop(false)
        setCallOnProgress(false);
        setDialPadBtn(true)
        setIsCallIncomingDirection(false)
        setConversionHistoryShow(true)
        // from material 
        get_power_dialer_socket_data(sid, contactNumber)
          }).catch((err) => {
            //console.log("error_response", err.response)
          })
        } else {
          dispatch({
            type: SET_POWER_DIALER,
            payload: false
          })
        }
      }).catch((err) => {
        //console.log("error_response", err)
      })
    }).catch((err) => {
      //console.log("error_response", err)
    })
  }

  const start_power_dialer = () => {
    if (phone_dialer.length > 0 && app.length > 0) {
      if (app[0].call_allow === "inb") {
        setErrorMessage("Oops! Outbound Not Allowed");
        setShowError(true)
        return;
      }
      setCalling(true)
      var number = age ? age : defaultCallingNumber
      const contactNumber = phone_dialer[power_dialer_index].contactId !== null && phone_dialer[power_dialer_index].contactId !== undefined && phone_dialer[power_dialer_index].contactId !== "" ? phone_dialer[power_dialer_index].contactId.phoneNumber.replace("+", "") : phone_dialer[power_dialer_index].contactNumber.replace("+", "");
      setPhoneNumber(contactNumber)
      const phone_data = {
        "From": number,
        "To": user.phone,
        "Destination": contactNumber,
        "Source": "Web"
      }

      const Caller = phone_data.From.slice(-10)
      get_phone_details(Caller);
      dispatch({
        type: SET_POWER_DIALER,
        payload: true
      })
      axios({
        method: "POST",
        url: `${baseUrl}/cloudphone/make-call`,
        headers: {
          authorization: localStorage.getItem('redux-token')
        },
        data: phone_data
      }).then((responseOfApiCall) => {
        let Direction = 'outbound';
        if (phone_dialer[power_dialer_index].contactId !== undefined && phone_dialer[power_dialer_index].contactId !== null && phone_dialer[power_dialer_index].contactId !== "") {
          setContact([phone_dialer[power_dialer_index].contactId])
        }
        let sid = responseOfApiCall.data.data.FriendlyName
        setParentCallSid(responseOfApiCall.data.data.sid)
        setCalling(false)
        setFriendlyName(responseOfApiCall.data.data.FriendlyName)
        setCalling(false)
        setBottomNav(false)
        setDialToggle(false);
        setDialingToggle(true);
        setDialPadBackDrop(false)
        setCallOnProgress(false);
        setRingingIconShow(false);
        setDialingIconShow(true);
        setDialPadBtn(true)
        setIsCallIncomingDirection(false)
        setConversionHistoryShow(true)
        // from material 
        get_power_dialer_socket_data(sid, contactNumber)
        setConversation(true)
      }).catch((err) => {
        //console.log("error_response", err)
      })
    }
  }

  useEffect(() => {
    window.addEventListener('click', function(e){
      if (document.getElementById('modal_button') !== null) {
        if (document.getElementById('modal_button').contains(e.target)){
          // Clicked in box
        } else {
          setModalStatus(false)
        }
      }
    });
  }, [])
  

  useEffect(() => {
    if (call_data.CallStatus === "no-answer" || call_data.CallStatus === "busy" || call_data.CallStatus === "failed") {
      setCallData("")
    }
  }, [call_data])

  useEffect(() => {
    window.addEventListener('click', function(e){
      if (document.getElementById('country-list') !== null) {
        if (document.getElementById('country-list').contains(e.target)){
          // Clicked in box
        } else {
          setShowCountryDropDown(false)
        }
      }
    });
  }, [])

  const search_tags = (value) => {
    setTagsInput(value)
    var avaialble_tags = [];
    for (let i = 0; i < tagsPresentInUserAccount.length; i++) {
      //console.log("tagsPresentInUserAccount[i].name", tagsPresentInUserAccount[i].name)
      //console.log('condition', tagsPresentInUserAccount[i].name.includes(value))
      if (tagsPresentInUserAccount[i].name.includes(value) === true) {
        avaialble_tags.push(tagsPresentInUserAccount[i])
      }
    }

    setSearchTags(avaialble_tags);
  }

  const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});



  // useEffect(() => {
  //   window.addEventListener('click', function(e){
	
  //     if (document.getElementById('modal').contains(e.target)){
         
  //     } else{
  //       setOpenModal(false)
  //     }
  //   })
  // }, [])

  const load_more_power_dialer = () => {
    setLoadMoreData(true)
    const Offset = parseInt(power_dialer_offset) + 10;
    axios({
      method: "GET",
      url: `${baseUrl}/power_dialer?populate=contactId&callStatus=${call_status}&&limit=10&offset=${Offset}&authId=${authId}&userId=${user._id}`,
      headers: {
        authorization: localStorage.getItem('redux-token')
      }
    }).then((res) => {
      const response = res.data.data.data;
      //console.log("dialer_response", response)
      setPowerDialerOffset(Offset);
      const previous_data = phone_dialer;
      Array.prototype.push.apply(previous_data, response)
      setPhoneDialer(previous_data);
      setLoadMoreData(false)
    }).catch((err) => {
      //console.log("error_response", err)
    })
  }

  const exit_from_call = () => {
    dispatch({
      type: SET_POWER_DIALER,
      payload: false
    })
    disconnectPhoneCall(true).then((res) => {
      setCallData("");
      setCallConnected(false);
      setCompletedCall(false);
      const current_power_dialer_id = phone_dialer[power_dialer_index]._id;
      var Status = call_data.CallStatus;
      if (call_data.CallStatus === "busy" || call_data.CallStatus === "failed" || call_data.CallStatus === "no-answer") {
        Status = "no-answer"
      } else if (call_data.CallStatus === "ringing" || call_data.CallStatus === "in-progress") {
        Status = "completed"
      }
      axios({
        method: "PATCH",
        url: `${baseUrl}/power_dialer/${current_power_dialer_id}`,
        data: {
          callStatus: Status
        },
        headers: {
          authorization: localStorage.getItem('redux-token')
        }
      }).then((res) => {
        //console.log("response_data", res.data);
        setTimer(0);

      }).catch((err) => {
        //console.log("error_response", err.response);
      })
      //console.log("res", res);
      setTimer(0)
    }).catch((err) => {
      //console.log("error_response", err.response)
    })
    
  }

  const scrollRef = useRef(null)

  const search_country = (value) => {
    var country = [];
    setCountryInput(value)
    const regex = /[0-9]/g;

    if (regex.test(value) === true) {
      for (let i = 0; i < allowed_country.length; i++) {
        if (allowed_country[i].phone.includes(value) === true) {
          country.push(allowed_country[i])
        }
      }
      setCountryList1(country)
    } else {
      const lower_case = value.toLowerCase();
      for (let i = 0; i < allowed_country.length; i++) {
        const regionName = regionNames.of(allowed_country[i].code.slice(0, 2)).toLowerCase();
        if (regionName.includes(lower_case) === true) {
          country.push(allowed_country[i])
        }
      }
      setCountryList1(country)
    }
  }

  const set_phone = (value) => {
    if (value.length > 10) {

    } else {
      setPhoneNumber(value)
    }
  }

  const scrollToRef = (target) => {
    window.scrollTo({
      top: target.current.offsetTop,
      behavior: "smooth"
    });
  }

  const navigateContactNumber = (number) =>{
    let sliced = number.slice(-10)
    const paramsData = { phone: sliced }
    navigate({
              pathname: '/dashboard/contacts/manage/account',
              search: `?${createSearchParams(paramsData)}`,
            });
   
  }





  return (
    <span className={callConnected === true && completed_call === false ? "connected-call" : ''} style={{ width: "100%", height: "100%", inset: "-1px", position: "relative", top: 0, left: 0, right: 0, bottom: 0, zIndex: "1199" }}>
      {/* <Card className={expandContractClass ? "new-call-widget fixed" : "new-call-widget expand draggable" || widjetMainToggle ? "mobile-full-screen" : ""}> */}
      {app.length > 0 ? ( <Card className={widjetClass}>

        {widjetMainToggle === true ? (
          <AppBar position="static" style={{ padding: "5px 10px", backgroundColor: callConnected === true && completed_call === true ? "#f50057" : "#0046BB" }}>
            <Toolbar>
              {settings === true && <Button style={{ minWidth: 20, width: 35, height: 35, display: "flex", alignItems: "center", justifyContent: "center", marginRight: 10 }} onClick={() => {
                if (settings === true && status === false && team === false && blacklist === false && add_blacklist === false && calling_pattern === false) {
                  setSettings(false)
                } else if (settings === true && status === false && team === false && blacklist === false && add_blacklist === false && calling_pattern === false) {
                  setAccount(false)
                } else if (settings === true && status === true && team === false && blacklist === false && add_blacklist === false && calling_pattern === false) {
                  setStatus(false)
                } else if (settings === true && status === false && team === true && blacklist === false && add_blacklist === false && calling_pattern === false) {
                  setTeam(false)
                } else if (settings === true && status === false && team === false && blacklist === true && add_blacklist === false && calling_pattern === false) {
                  setBlacklist(false)
                } else if (settings === true && status === false && team === false && blacklist === true && add_blacklist === true && calling_pattern === false) {
                  setAddBlacklist(false)
                } else if (settings === true && status === false && team === false && blacklist === false && add_blacklist === false && calling_pattern === true) {
                  setCallingPattern(false)
                }
              }}>
                <ArrowCircleLeftOutlinedIcon style={{ color: "#fff", width: 35, height: 35 }} />
              </Button>}
              {callConnected === true ? (
                <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }} >
                  {completed_call === true ? "Disconnected..." : "Connected..."}
                </Typography>
              ) : (
                <>
                  {ringing_sip === true ? (
                    <>
                      {incoming_sip === true ? (
                        <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }}>
                           Incoming Call...
                        </Typography>
                      ): (
                        <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }}>
                          Ringing...
                        </Typography>
                      )}
                    </>
                  ): (
                    <>
                      {call_data !== "" && call_data.CallStatus !== "completed" ? (
                    <>
                      {call_data.Direction !== "inbound" ? (
                        <>
                          {call_data.Called.slice(-10) !== user.phone.slice(-10) ? (
                            <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }}>
                              Ringing...
                            </Typography>
                          ) : (
                            <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }}>
                              Dialing...
                            </Typography>
                          )}
                        </>
                      ) : (
                        <>
                        {call_data.CallStatus === "no-answer" || call_data.CallStatus === "busy" || call_data.CallStatus === "failed" ? (
                          <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }} >
                          Cloud Phone
                          </Typography>
                        ): (
                          <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }}>
                          Incoming Call...
                          </Typography>
                        )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {settings === false ? (
                        <>
                          {conversation === true ? (
                        <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }} >
                          Cloud Phone
                       </Typography>
                      ): (
                        <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1', marginRight: '10px' }} >
                          Power Dialer
                        </Typography>
                      )}
                        </>
                      ): (
                        <>
                          {status === false && team === false && blacklist === false && add_blacklist === false && calling_pattern === false ? (
                            <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }} >
                              Settings
                            </Typography>
                          ): (
                            <>
                              {status === true && team === false && blacklist === false && add_blacklist === false && calling_pattern === false ? (
                                <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }} >
                                  Status
                                </Typography>
                              ): (
                                <>
                                  {status === false && team === true && blacklist === false && add_blacklist === false && calling_pattern === false ? (
                                    <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }} >
                                      Team
                                    </Typography>
                                  ): (
                                    <>
                                      {status === false && team === false && blacklist === true && add_blacklist === false && calling_pattern === false ? (
                                        <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }} >
                                          Blacklist
                                        </Typography>
                                      ): (
                                        <>
                                          {status === false && team === false && blacklist === true && add_blacklist === true && calling_pattern === false ? (
                                            <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }} >
                                              Add blacklist
                                            </Typography>
                                          ): (
                                            <>
                                              {status === false && team === false && blacklist === false && add_blacklist === false && calling_pattern === true && <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }} >
                                                Calling Pattern
                                            </Typography>}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                    </>
                  )}
                </>
              )}

   

              {settings === false && callConnected === false && conversation === true && <>
                {refreshing === true ? (
                  <CircularProgress style={{ width: 25, height: 25, color: "#fff", marginRight: 2 }} />
                ) : (
                  <Button style={{ width: 40, height: 35, minWidth: 20, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 2, marginRight: 2 }} onClick={() => refresh_calls()}>
                    <UpdateIcon style={{ color: "#fff" }} />
                  </Button>
                )}
              </>}

              {settings === false && callConnected === false && call_data === "" && conversation === false && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
               
                
                <TextField className='appbar-select' size="small" select id="filled-hidden-label-small" value={power_dialer_call_status} onChange={(event) => {
                    setPhoneDialer([]);
                    setPowerDialerCallStatus(event.target.value);
                  }}>
                    <MenuItem value="All"> All </MenuItem>
                    <MenuItem value="New"> New </MenuItem>
                    <MenuItem value="completed"> Completed </MenuItem>
                    <MenuItem value="no-answer"> No Answer </MenuItem>
                  </TextField>

                {phone_dialer.length > 0 && <>
                  {calling == true ? (
                  <CircularProgress style={{ color: "#fff", width: 25, height: 25, }}/>
                ): (
                  <Button
                    className='appbar-button'
                    size='small'
                    variant='outlined'
                    onClick={() => {
                      start_power_dialer()
                    }}>
                    
                      Start
             
                  </Button>
                )}
                </>}
                {power_dialer_refreshing === true ? (
                  <CircularProgress style={{ color: "#fff", width: 25, height: 25, marginRight: 2 }}/>
                ): (
                  <Button style={{ width: 40, height: 35, minWidth: 20, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 2 }} onClick={() => refresh_power_dialer_data(power_dialer_call_status)}>
                  <UpdateIcon style={{ color: "#fff" }} />
                  </Button>
                )}

              </div>}

              
              

              <Button size="small" color="inherit" className="cloud-phone-appbar-btn" onClick={expandWidjet}>
                {minimizeMaximize ? (
                  <MinimizeIcon style={{ color: "#797979" }} />
                ) : (
                  <CallMadeOutlinedIcon style={{ color: "#797979" }} />
                )}
              </Button>

            </Toolbar>
          </AppBar>
        ) : (
          <span>
            <AppBar position="static" onClick={expandWidjet} style={{ cursor: "pointer", padding: "5px 10px", backgroundColor: callConnected === true && completed_call === true ? "#f50057" : "#0046BB" }}>
              <Toolbar>
              {callConnected === true ? (
                <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }} >
                  {completed_call === true ? "Disconnected..." : "Connected..."}
                </Typography>
              ) : (
                <>
                  {ringing_sip === true ? (
                    <>
                      {incoming_sip === true ? (
                        <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }}>
                           Incoming Call...
                        </Typography>
                      ): (
                        <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }}>
                          Ringing...
                        </Typography>
                      )}
                    </>
                  ): (
                    <>
                      {call_data !== "" && call_data.CallStatus !== "completed" ? (
                    <>
                      {call_data.Direction !== "inbound" ? (
                        <>
                          {call_data.Called.slice(-10) !== user.phone.slice(-10) ? (
                            <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }}>
                              Ringing...
                            </Typography>
                          ) : (
                            <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }}>
                              Dialing...
                            </Typography>
                          )}
                        </>
                      ) : (
                        <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }}>
                          Incoming Call...
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      {conversation === true ? (
                        <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1' }} >
                          Cloud Phone
                       </Typography>
                      ): (
                        <Typography variant="subtitle1" style={{ fontWeight: "700", flexGrow: '1'}} >
                          Power Dialer
                        </Typography>
                      )}
                    </>
                  )}
                    </>
                  )}
                </>
              )}





                <Button size="small" color="inherit" className="cloud-phone-appbar-btn" onClick={expandWidjet}>
                  {minimizeMaximize ? (
                    <MinimizeIcon style={{ color: "#797979" }} />
                  ) : (
                    <CallMadeOutlinedIcon style={{ color: "#797979" }} />
                  )}


                </Button>

              </Toolbar>
            </AppBar>
          </span>
        )}
        
        




        {showCallType === true ? (
          <div style={{ height: "45px" }}></div>
        ) : ''}

        <Collapse in={widjetMainToggle}>
          {settings === false ? (
            <>
              {conversation === true ? (
                <div style={{ display: "block", position: "relative", paddingBottom: '50px', minWidth: 350, }}>
                <Conversations setWidjetMainToggle={setWidjetMainToggle} setExpandContractBtn={setExpandContractBtn} setExpandContractClass={setExpandContractClass} setMinimizeMaximize={setMinimizeMaximize} scrollRef={scrollRef} minimizeMaximize={minimizeMaximize}/>
                </div>
              ): (
                <div style={{ display: "block", position: "relative", paddingBottom: '50px', minWidth: 350, minHeight: 430, maxHeight: 430,  overflowY: "scroll"}}>
                  <PowerDialerComponent dialer={phone_dialer} loading_numbers={loading_numbers} load_more_power_dialer={load_more_power_dialer} load_more_data={load_more_data} power_dialer_total_count={power_dialer_total_count}/>
                </div>
              )}
            </>
          ) : ''}

          {settings === true && status === false && team === false && blacklist === false && calling_pattern === false && add_blacklist === false ? (
            <div style={{ display: "block", position: "relative", paddingBottom: 50, minWidth: 350, minHeight: 430, maxHeight: 430}}>
              <div style={{ minHeight: "330px", maxHeight: "400px", overflowY: "auto", display: "flex", alignItems: "center", flexDirection: "column", backgroundColor: "#FAFAFA" }} className="center-col">
                <div style={{ width: "100%", height: 150, backgroundColor: "#0046BB", display: "flex", flexDirection: "column", }}>


                  <img src={`${baseUrl}/download/user_logo?fileLocation=${user.user_logo}`} style={{ width: 60, height: 60, borderRadius: 50, display: "flex", alignSelf: "center" }} />
                  <div style={{ color: "#fff", fontSize: 14, marginTop: 5, display: "flex", alignSelf: "center", fontWeight: "500" }}>
                    {user.fullName}
                  </div>
                  <Button style={{ height: 40, width: "90%", display: "flex", alignSelf: "center", flexDirection: "row", alignItems: "center", justifyContent: "space-between", position: "absolute", backgroundColor: "#fff", marginTop: 130, boxShadow: "1px 1px 1px lightgrey", }} onClick={() => setStatus(true)}>
                    <div style={{ height: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <div style={{ marginLeft: 5, width: 30, height: 30, backgroundColor: "#0046BB", borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CheckIcon style={{ color: "#fff", width: 20, height: 20 }} />
                      </div>
                      <div style={{ marginLeft: 10, display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                        <div style={{ color: "#0046BB", fontSize: 13, fontWeight: "normal" }}>
                          Current Status
                        </div>
                        {user_status === "available" ? (
                          <div style={{ color: "#0046BB", fontSize: 12, fontWeight: "normal" }}>
                            Available
                          </div>
                        ) : (
                          <>
                            {user_status === "busy" ? (
                              <div style={{ color: "#0046BB", fontSize: 12, fontWeight: "normal" }}>
                                Busy
                              </div>
                            ) : (
                              <>
                                {user_status === "away" ? (
                                  <div style={{ color: "#0046BB", fontSize: 12, fontWeight: "normal" }}>
                                    Away
                                  </div>
                                ) : (
                                  <div style={{ color: "#0046BB", fontSize: 12, fontWeight: "normal" }}>
                                    Do not Disturb
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <ArrowForwardIosOutlinedIcon style={{ color: "#0046BB", height: 25, width: 25, marginRight: 10 }} />
                  </Button>
                </div>

                <div style={{ width: "90%", height: 135, backgroundColor: "#fff", display: "flex", alignSelf: "center", boxShadow: "1px 1px 1px 1px lightgrey", borderRadius: 5, flexDirection: "column", marginTop: 30 }}>

                  <Button style={{ width: "100%", height: "33%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderRadius: 0, borderBottom: "1px solid lightgrey" }} onClick={() => setTeam(true)}>
                    <div style={{ display: "flex", flexDirection: "row", height: "100%", alignItems: "center" }}>
                      <div style={{ marginLeft: 10, width: 25, height: 25, backgroundColor: "#0046BB", borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <PeopleRoundedIcon style={{ color: "#fff", width: 15, height: 15, }} />
                      </div>
                      <div style={{ color: "#000", fontSize: 13, marginLeft: 15, fontWeight: "normal" }}>
                        Team
                      </div>
                    </div>
                    <ArrowForwardIosOutlinedIcon style={{ color: "#0046BB", height: 25, width: 25, marginRight: 10 }} />
                  </Button>
                  <Button style={{ width: "100%", height: "33%", borderRadius: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid lightgrey" }} onClick={() => setBlacklist(true)}>
                    <div style={{ display: "flex", flexDirection: "row", height: "100%", alignItems: "center" }}>
                      <div style={{ marginLeft: 10, width: 25, height: 25, backgroundColor: "#0046BB", borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <BlockRoundedIcon style={{ color: "#fff", width: 15, height: 15, }} />
                      </div>
                      <div style={{ color: "#000", fontSize: 13, marginLeft: 15, fontWeight: "normal" }}>
                        Blacklist
                      </div>
                    </div>
                    <ArrowForwardIosOutlinedIcon style={{ color: "#0046BB", height: 25, width: 25, marginRight: 10 }} />
                  </Button>
                  <Button style={{ width: "100%", height: "34%", borderRadius: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} onClick={() => setCallingPattern(true)}>
                    <div style={{ display: "flex", flexDirection: "row", height: "100%", alignItems: "center" }}>
                      <div style={{ marginLeft: 10, width: 25, height: 25, backgroundColor: "#0046BB", borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CallIcon style={{ color: "#fff", width: 15, height: 15, }} />
                      </div>
                      <div style={{ color: "#000", fontSize: 13, marginLeft: 15, fontWeight: "normal" }}>
                        Calling Pattern
                      </div>
                    </div>
                    <ArrowForwardIosOutlinedIcon style={{ color: "#0046BB", height: 25, width: 25, marginRight: 10 }} />
                  </Button>
                </div>
                {/* <div style={{ width: "90%", height: 90, backgroundColor: "#fff", display: "flex", alignSelf: "center", marginTop: 10, boxShadow: "1px 1px 1px lightgrey", flexDirection: "column", borderRadius: 5, marginBottom: 10 }}>
                          <Button style={{ width: "100%", height: "50%", borderRadius: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid lightgrey"}} onClick={() => setBlacklist(true)}>
                            <div style={{ display: "flex", flexDirection: "row", height: "100%", alignItems: "center"}}>
                              <div style={{ marginLeft: 10, width: 25, height: 25, backgroundColor: "#0046BB", borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center" }}> 
                                 <BlockRoundedIcon style={{ color: "#fff", width: 15, height: 15, }} />
                              </div>
                              <div style={{ color: "#000", fontSize: 13, marginLeft: 15, fontWeight: "normal"}}>
                                Blacklist
                              </div>
                            </div>
                            <ArrowForwardIosOutlinedIcon style={{ color: "#0046BB", height: 25, width: 25, marginRight: 10 }}/>
                          </Button>
                          <Button style={{ width: "100%", height: "50%", borderRadius: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} onClick={() => setCallingPattern(true)}>
                            <div style={{ display: "flex", flexDirection: "row", height: "100%", alignItems: "center"}}>
                              <div style={{ marginLeft: 10, width: 25, height: 25, backgroundColor: "#0046BB", borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center" }}> 
                                 <CallIcon style={{ color: "#fff", width: 15, height: 15, }} />
                              </div>
                              <div style={{ color: "#000", fontSize: 13, marginLeft: 15, fontWeight: "normal"}}>
                                Calling Pattern
                              </div>
                            </div>
                            <ArrowForwardIosOutlinedIcon style={{ color: "#0046BB", height: 25, width: 25, marginRight: 10 }}/>
                          </Button>
                        </div> */}

              </div>
            </div>


          ) : ''}
          {settings === true && status === true && team === false && blacklist === false && add_blacklist === false && calling_pattern === false ? (
            <div style={{ display: "block", position: "relative", paddingBottom: 50, minWidth: 350, minHeight: 430, overflowY: "scroll", maxHeight: 430 }}>
              <StatusComponent setStatus={setUserStatus} />
            </div>
          ) : ''}
          {settings === true && status === false && team === true && blacklist === false && add_blacklist === false && calling_pattern === false ? (
            <div style={{ display: "block", position: "relative", paddingBottom: 50, minWidth: 350, minHeight: 430, overflowY: "scroll", maxHeight: 430 }}>
              <TeamComponent />
            </div>) : ''}

          {settings === true && status === false && team === false && blacklist === true && add_blacklist === false && calling_pattern === false ? (
            <div style={{ display: "block", position: "relative", paddingBottom: 50, minWidth: 350, minHeight: 430, overflowY: "scroll", maxHeight: 430}}>
              <BlacklistComponent setAddBlaclist={setAddBlacklist} />
            </div>
          ) : ''}

          {settings === true && status === false && team === false && blacklist === true && add_blacklist === true && calling_pattern === false ? (
            <div style={{ display: "block", position: "relative", paddingBottom: 50, minWidth: 350, minHeight: 430, overflowY: "scroll", maxHeight: 430}}>
              <AddBlacklistNumberComponent setStatus={setAddBlacklist} country_list={allowed_country}/>
            </div>
          ) : ''}

          {settings === true && status === false && team === false && blacklist === false && add_blacklist === false && calling_pattern === true ? (
            <div style={{ display: "block", position: "relative", paddingBottom: 50, minWidth: 350, minHeight: 430, overflowY: "scroll", maxHeight: 430}}>
              <CallingPattern />
            </div>
          ) : ''}




          {/* Notes */}
          <Collapse in={showAddNotes} className="call-widjet-bottom-appbar notes-sec">
            <Paper elevation={4} style={{ borderRadius: "15px 15px 0 0", padding: "20px" }}>
              <Grid container spacing={2} alignItems="center">

                <Grid item xs>
                  <Typography variant="subtitle1" className="history-head">
                    Notes
                  </Typography>
                </Grid>
                <Grid item>
                  <Button size="small" onClick={handleAddNotes} color="primary">
                    Done
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    placeholder="Add Notes"
                    multiline
                    fullWidth
                    rows={5}
                    variant="outlined"
                    value={callNotes}
                    onChange={(e) => setCallNotes(e.target.value)}
                  />
                </Grid>
              </Grid>

            </Paper>
          </Collapse>
          {/* Notes */}

          {/* Tags */}
          <Collapse in={showTags} className="call-widjet-bottom-appbar notes-sec">
            <Paper elevation={4} style={{ borderRadius: "15px 15px 0 0", padding: "20px", height: 250, overflowY: "scroll" }}>


              <Grid container spacing={2} alignItems="center" style={{ borderBottom: "1px solid #eee", marginBottom: "10px" }}>

                <Grid item xs>
                  <Typography variant="subtitle1" className="history-head">
                    Tags
                  </Typography>
                </Grid>
                <Grid item>
                  <Button size="small" onClick={handleSaveTags} color="primary">
                    Done
                  </Button>
                </Grid>

              </Grid>

              <FormControl fullWidth>
                <TextField id="filled-basic" fullWidth placeholder="Start typing a tag" value={tags_input} onChange={(e) => search_tags(e.target.value)} />
              </FormControl>

              <div style={{ display: "flex", flexDirection: "column", overFlowY: "scroll", }}>
                {search_tag.map((item, index) => (
                  <Button key={index} style={{ height: 30, marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} onClick={() => {
                    const new_array = [...tagsPresentInUserAccount];
                    const find_index = tagsPresentInUserAccount.findIndex((value) => { return value.name === item.name })
                    const checked = new_array[find_index].checked;
                    const tag_arr = [...search_tag]
                    if (checked === false) {
                      new_array[find_index].checked = true;
                      tag_arr[index].checked = true;
                    } else if (checked === true) {
                      new_array[find_index].checked = false;
                      tag_arr[index].checked = false;
                    } else if (checked === undefined) {
                      new_array[find_index].checked = true
                      tag_arr[index].checked = true
                    }
                    setSearchTags(tag_arr)
                    setTagsPresentInUserAccount(new_array);
                  }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 10 }}>
                      <div style={{ backgroundColor: item.backgroundColor, width: 15, height: 15 }} />
                      <div style={{ color: "#000", fontSize: 14, fontWeight: "normal", marginLeft: 20 }}>
                        {item.name}
                      </div>
                    </div>
                    {item.checked === true ? (
                      <div style={{ width: 25, height: 25, borderRadius: 20, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CheckIcon style={{ width: 15, color: "#fff", height: 15, }} />
                      </div>
                    ) : (
                      <div style={{ width: 25, height: 25, borderRadius: 20, border: "2px solid grey" }} />
                    )}
                  </Button>
                ))}
              </div>

            </Paper>
          </Collapse>
          {/* tags */}


          {/* Assign */}
          <Collapse in={showAssign} className="call-widjet-bottom-appbar notes-sec">
            <Paper elevation={4} style={{ borderRadius: "15px 15px 0 0", padding: "20px" }}>


              <Grid container spacing={2} alignItems="center" style={{ borderBottom: "1px solid #eee", marginBottom: "10px" }}>

                <Grid item xs>
                  <Typography variant="subtitle1" className="history-head">
                    Assign To
                  </Typography>
                </Grid>
                <Grid item>
                  <Button size="small" onClick={handleAssign} color="primary">
                    Done
                  </Button>
                </Grid>

              </Grid>

              <FormControl fullWidth>
                <TextField id="filled-basic" fullWidth placeholder="Start typing to search" />
              </FormControl>
              <List className="tags-list">
                <FormControl component="fieldset" fullWidth>

                  <RadioGroup aria-label="numberlist" name="numberlist" value={tags} onChange={handleAssignChange} className="radio-tag-group">
                    {assignToList.map((item, index) => {
                      return (
                        <ListItem value={item.value} dense button key={index}>
                          <ListItemIcon>
                            <FormControlLabel value={item.value} control={<Radio color="primary" checkedIcon={<CheckCircleIcon />} />} />
                          </ListItemIcon>


                          <ListItemText primary={item.name} style={{ textTransform: "capitalize" }} />

                          <ListItemAvatar>
                            <Avatar src="/broken-image.jpg" style={{ width: "30px", height: "30px" }} />
                          </ListItemAvatar>

                        </ListItem>
                      )
                    })}


                  </RadioGroup>
                </FormControl>
              </List>

            </Paper>
          </Collapse>
          {/* Assign */}


          <IncomingCall incomingCallToggle={incomingCallToggle} answerCall={answerCall} declineCall={declineCall} number={incoming_number} />

          <Collapse in={showSearchNumbers} className="dial-pad call-type number-listing" style={{ height: 450 }}>
            <Paper elevation={4} className="number-paper" style={{ overflowY: "scroll", height: 450 }}>
              <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                <Grid item>
                  <Button style={{ width: 50, height: 50, marginLeft: 15, minWidth: 30, borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => setShowSearchNumbers(false)}>
                    <ArrowCircleLeftOutlinedIcon style={{ color: "#0046BB", width: 30, height: 30 }} />
                  </Button>
                </Grid>
                <Grid item>
                <TextField
                  placeholder="Search your Cloud Number"
                  id="outlined-size-small"
                  style={{ width: "110%", }}
                  value={search_cloud_number}
                  onChange={(event) => search_number(event.target.value)}
                />
                </Grid>
               
                <Grid item>

                </Grid>
              </Grid>
              {removed_dulicate_array.length > 0 ? (
                <List className="number-list">
                  <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ background: "#F3F2F2", width: '100%', display: "block", padding: "10px 10px 10px 30px", fontSize: "12px"}}>Available Cloud Numbers</FormLabel>
                    {removed_dulicate_array.map((item, index) => (
                      <Button style={{ width: "100%", height: 45, display: "flex", flexDirection: "row", alignItems: "center", borderBlockWidth:1, borderBottomColor: "grey", borderRadius: 0, justifyContent: "space-between", marginBottom: index+1 === removed_dulicate_array.length ? 40 : 0}} key={index} onClick={() => handleChange(item.phone_number)}>
                        <div style={{ display: "flex", flexDirection: "row", }}>
                          {item.country_iso !== undefined && item.country_iso !== null &&
                            <img src={`https://flagcdn.com/w80/${item.country_iso.substr(0, 2).toLowerCase()}.png`} style={{ width: 30, height: 20, marginLeft: 10, }} alt={regionNames.of(item.country_iso.substr(0, 2))}/>
                          }
                          <div style={{ fontSize: 14, color: "#000", marginLeft: 20, fontWeight: "normal"}}>
                            {item.phone_number.slice(-10)}
                          </div>
                        </div>
                        {age === item.phone_number ? (
                          <div style={{ marginRight: 10, width: 25, height: 25, borderRadius: 20, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <DoneRoundedIcon style={{ height: 15, width: 15, color: "#fff"}}/>
                          </div>
                        ): (
                          <div style={{ marginRight: 10, border: "1px solid grey", width: 25, height: 25, borderRadius: 20 }}>

                          </div>
                        )}
                      </Button>
                    ))}

                  </FormControl>
                </List>

              )
                : (
                  <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img src={require("./cloudNumber.png")} style={{ width: 100, height: 100, display: "flex", flexDirection: "column", alignSelf: "center"}}/>
                  </div>
                )}
              {/* {removed_dulicate_array.length > 0 ? (
                          <List className="number-list">
                              {removed_dulicate_array.map((name, index)=>(
                                <ListItem key={index}  value={name.number} onChange={handleChange} dense button className="phone-flag-only" >
                                <ListItemText>
                                  <PhoneInput
                                  
                                    value={name.number}
                                    showDropdown={false}
                                    disabled
                                  
                                  />
                                </ListItemText>
    
                                <ListItemSecondaryAction>
                                  <FormControlLabel value={name.number} onChange={handleChange} control={<Radio />} />
                                </ListItemSecondaryAction>
    
                              </ListItem>
                               ))}
                               </List>
                             )
                           :(
                               <option value="">No Number</option>
                        )} */}



            </Paper>
          </Collapse>


          <Collapse in={showCallType} className="dial-pad call-type">
            <Paper elevation={4} className="call-type-paper">
              <RadioGroup aria-label="gender" name="gender1"
                value={callFormPhoneBrowser}
                onChange={event => setCallFormPhoneBrowser(event.target.value)}

                className="custom-radio-calltype">
                {/* <Button variant="outlined" htmlFor="Browser" style={{ marginRight: "10px", textTransform: "capitalize" }} onClick={handleBrowserCall} className="active-radio-call">
                  <div style={{ display: "block" }}>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography variant="h5">
                        Internet Call
                      </Typography>
                      {callFormPhoneBrowser === "Browser" ? (
                        <div style={{  height: 25, width: 25, borderRadius: 20, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <DoneRoundedIcon style={{ width: 15, height: 15, color: "#fff"}}/>
                        </div>
                      ): (
                        <div style={{  height: 25, width: 25, borderRadius: 20, border: "1px solid grey" }}>

                        </div>
                      )}
                    </div>
                    <Typography variant="caption">
                      Call any Phone Number using Internet
                    </Typography>

                    <List
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                    >
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="VoIP Call using WiFi/Cellular" />
                      </ListItem>

                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Call Qulaity is Determined by your internet" />
                      </ListItem>

                    </List>

                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      <img src={internetCallImg} className="internet-call-img" />
                    </Typography>


                    <FormControlLabel value="Browser" control={<Radio style={{ display: "none" }} id="Browser" />} />
                  </div>
                </Button> */}

                <Button variant="outlined" htmlFor="Phone" style={{ marginRight: "10px", textTransform: "capitalize" }} onClick={handleBrowserCall} className="active-radio-call">
                  <div style={{ display: "block", width: "100%" }}>

                    {/* {callFormPhoneBrowser === 'Phone' ? (
                      <img src={arrowActiveImg} className="radio-active-img" />
                    ) : ''} */}
                    
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography variant="h5">
                        Internet Call
                      </Typography>
                      {callFormPhoneBrowser === "Browser" ? (
                        <div style={{  height: 25, width: 25, borderRadius: 20, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <DoneRoundedIcon style={{ width: 15, height: 15, color: "#fff"}}/>
                        </div>
                      ): (
                        <div style={{  height: 25, width: 25, borderRadius: 20, border: "1px solid grey" }}>

                        </div>
                      )}
                    </div>
                    <Typography variant="caption">
                      Call any Phone Number using Internet
                    </Typography>

                    <List
                      
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                    >
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="VoIP Call using WiFi/Cellular" />
                      </ListItem>

                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Call Quality is Determined by your internet" />
                      </ListItem>

                    </List>

                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      <img src={phoneCallImg} className="internet-call-img" />
                    </Typography>

                    <FormControlLabel value="Browser" control={<Radio style={{ display: "none" }} id="Browser" />} />
                  </div>
                </Button>


                <Button variant="outlined" htmlFor="Phone" style={{ marginRight: "10px", textTransform: "capitalize" }} onClick={handlePhoneCall} className="active-radio-call">
                  <div style={{ display: "block", width: "100%" }}>

                    {/* {callFormPhoneBrowser === 'Phone' ? (
                      <img src={arrowActiveImg} className="radio-active-img" />
                    ) : ''} */}
                    
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography variant="h5">
                        Phone Call
                      </Typography>
                      {callFormPhoneBrowser === "Phone" ? (
                        <div style={{  height: 25, width: 25, borderRadius: 20, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <DoneRoundedIcon style={{ width: 15, height: 15, color: "#fff"}}/>
                        </div>
                      ): (
                        <div style={{  height: 25, width: 25, borderRadius: 20, border: "1px solid grey" }}>

                        </div>
                      )}
                    </div>
                    <Typography variant="caption">
                      Call any number using your Cellular Network
                    </Typography>

                    <List
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                    >
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="The system dials your and destination 
number simultaneously" />
                      </ListItem>

                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Call Quality is Determined by your 
Cellular Network" />
                      </ListItem>

                    </List>

                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      <img src={phoneCallImg} className="internet-call-img" />
                    </Typography>

                    <FormControlLabel value="Phone" control={<Radio style={{ display: "none" }} id="Phone" />} />
                  </div>
                </Button>


              </RadioGroup>

            </Paper>
          </Collapse>



          <Backdrop className="dial-pad-backdrop" open={dialPadBackDrop}>


            <Collapse in={dialToggle} className="dial-pad">
              <Paper elevation={4}>

                <AppBar position="inherit" color="transparent" className="call-widjet-number-appbar" style={{ borderBottom: "1px solid #0045BA" }}>
                  <Toolbar className="dial-phone-number">
                    {/* {allowedCountry.length > 0 ?
                      <>
                        <PhoneInput
                          country={defaultCallingCountry.toLowerCase()}
                          onlyCountries={allowedCountry}
                          onlyCountries={['us','in']}
                          value={phoneNumber}
                          enableSearch={true}
                          onChange={number => {
                            console.log("number", number)
                            // setPhoneNumber(number)
                          }}
                          onEnterKeyPress={() => handleSubmit()}

                        />
                      </> : " "} */}
                      
                      <Button style={{ width: 40, height: 40, display: "flex", alignItems: "center", justifyContent: "space-between", borderRadius: 2, borderColor: "#0046BB", borderWidth: 1, marginLeft: -10, marginRight: 5, }} onClick={(event) => {
                        if (show_country_dropdown === false) {
                          setShowCountryDropDown(true)
                        } else {
                          setShowCountryDropDown(false)
                        }
                      }}>
                        {selected_country ? (
                          <>
                          {selected_country.code !== undefined && selected_country.code !== null && <img src={`https://flagcdn.com/w80/${selected_country.code.substr(0, 2).toLowerCase()}.png`} style={{height: 20, width: 30 }}/>}
                          </>
                        ): ''}
                        
                        <ArrowDropDownIcon color='#0046BB'/>
                      </Button>
                      {/* <input style={{ height: 40, width: "80%", borderColor: "#0046BB", fontSize: 20, fontWeight: "500", borderWidth: 0, }} value={phoneNumber} onChange={(e) => set_phone(e.target.value)} onSubmit={() => handleSubmit()} onEnterKeyPress={() => handleSubmit()} type="tel" autoFocus/> */}
                      <TextField
                        placeholder="0000000000"
                        id="outlined-size-small"
                        style={{ width: "80%"}}
                        value={phoneNumber} 
                        onChange={(e) => set_phone(e.target.value)} 
                        onSubmit={() => handleSubmit()}
                        size="small"
                      />
                    <IconButton onClick={(e) => {
                      const delete_number = phoneNumber.slice(0, -1);
                      setPhoneNumber(delete_number)
                    }} 
                    style={{
                      marginLeft: 10
                    }}
                    size="small">
                      <BackspaceOutlinedIcon />
                    </IconButton>

                  </Toolbar>

                </AppBar>
                {show_country_dropdown === true && <div className="country-list">
                  <div style={{ backgroundColor: "#fff", padding: "10px 0 6px 10px", position: "sticky", top: 0, zIndex: 2 }}>
                    <input type="search" placeholder="search" value={country_input} autoComplete='off' style={{ border: "1px solid #cacaca", borderRadius: 3, fontSize: 15, height: 30, marginLeft: 6, padding: "3px 8px 5px"}} onChange={(e) => search_country(e.target.value)}/>
                  </div>
                  {country_list1.map((item, index) => (
                    <Button style={{ width: "100%", height: 45, borderRadius: 2, display: "flex", alignItems: "center", justifyContent: "flex-start"  }} key={index} onClick={() => {
                      dispatch({
                        type: SET_SELECTED_COUNTRY,
                        payload: item
                      })
                      setShowCountryDropDown(false)
                    }}>
                      <img src={`https://flagcdn.com/w80/${item.code.substr(0, 2).toLowerCase()}.png`} style={{height: 20, width: 30 }}/>
                      <div style={{ marginLeft: 10, fontSize: 13, color: "#000", fontWeight: "normal"}}>
                        {regionNames.of(item.code.slice(0, 2))}
                      </div>
                      <div style={{ marginLeft: 10, fontWeight: "normal", fontSize: 13, color: "grey"}}>
                        +{item.phone}
                      </div>
                    </Button>
                  ))}
                </div>}
                <AppBar position="inherit" color="transparent" className="call-widjet-number-appbar" style={{ marginTop: "10px" }}>
                  <Toolbar>

                    <Button onClick={(e) => handleClickBtn('1')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <div className="no-alphabet">

                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          1
                        </Grid>
                        <Grid item xs={12}>
                          <div className="no-alphabet">

                          </div>
                        </Grid>
                      </Grid>
                    </Button>


                    <Button onClick={(e) => handleClickBtn('2')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          2
                        </Grid>
                        <Grid item xs={12}>
                          <div className="alphabet">
                            ABC
                          </div>
                        </Grid>
                      </Grid>
                    </Button>

                    <Button onClick={(e) => handleClickBtn('3')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          3
                        </Grid>
                        <Grid item xs={12}>
                          <div className="alphabet">
                            DEF
                          </div>
                        </Grid>
                      </Grid>
                    </Button>



                  </Toolbar>
                </AppBar>

                <AppBar position="inherit" color="transparent" className="call-widjet-number-appbar">
                  <Toolbar>
                    <Button onClick={(e) => handleClickBtn('4')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          4
                        </Grid>
                        <Grid item xs={12}>
                          <div className="alphabet">
                            GHI
                          </div>
                        </Grid>
                      </Grid>
                    </Button>

                    <Button onClick={(e) => handleClickBtn('5')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          5
                        </Grid>
                        <Grid item xs={12}>
                          <div className="alphabet">
                            JKL
                          </div>
                        </Grid>
                      </Grid>
                    </Button>

                    <Button onClick={(e) => handleClickBtn('6')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          6
                        </Grid>
                        <Grid item xs={12}>
                          <div className="alphabet">
                            MNO
                          </div>
                        </Grid>
                      </Grid>
                    </Button>
                  </Toolbar>
                </AppBar>

                <AppBar position="inherit" color="transparent" className="call-widjet-number-appbar">
                  <Toolbar>

                    <Button onClick={(e) => handleClickBtn('7')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          7
                        </Grid>
                        <Grid item xs={12}>
                          <div className="alphabet">
                            PQRS
                          </div>
                        </Grid>
                      </Grid>
                    </Button>

                    <Button onClick={(e) => handleClickBtn('8')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          8
                        </Grid>
                        <Grid item xs={12}>
                          <div className="alphabet">
                            TUV
                          </div>
                        </Grid>
                      </Grid>
                    </Button>

                    <Button onClick={(e) => handleClickBtn('9')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          9
                        </Grid>
                        <Grid item xs={12}>
                          <div className="alphabet">
                            WXYZ
                          </div>
                        </Grid>
                      </Grid>
                    </Button>

                  </Toolbar>
                </AppBar>

                <AppBar position="inherit" color="transparent" className="call-widjet-number-appbar">
                  <Toolbar>

                    <Button onClick={(e) => handleClickBtn('*')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          *
                        </Grid>
                        <Grid item xs={12}>
                          <div className="alphabet">

                          </div>
                        </Grid>
                      </Grid>
                    </Button>


                    <Button onClick={(e) => handleClickBtn('0')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          0
                        </Grid>
                        <Grid item xs={12}>
                          <div className="alphabet">
                            +
                          </div>
                        </Grid>
                      </Grid>
                    </Button>

                    <Button onClick={(e) => handleClickBtn('#')} className="dial-btn">
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          #
                        </Grid>
                        <Grid item xs={12}>
                          <div className="alphabet">

                          </div>
                        </Grid>
                      </Grid>
                    </Button>

                  </Toolbar>
                </AppBar>


                <AppBar position="inherit" color="transparent" className="call-widjet-number-appbar">
                  <Toolbar style={{ justifyContent: app[0].type_allow === "both" ? "space-around" : "space-evenly", width: app[0].type_allow === "both" ? "100%" : "70%" }}>

                    <Button
                      className="callfrom-btn"
                      variant="outlined"
                      size="small"
                      onClick={handleSearchNumbers}
                    >
                      Call From
                    </Button>

                    {/* <select className="dial-dropdown"
                          value={age}
                          onChange={handleChange}
                        >
                          <option value='0'>
                            Call From
                          </option>

                          {removed_dulicate_array.length > 0 ? (
                              removed_dulicate_array.map((name, index)=>(
                                 <option key={index} value={name.number}>
                                    {name.number}
                                 </option>
                               ))
                             )
                              :(
                               <option value="">No Number</option>
                                )}

                        </select> */}
                    {/* <FormControl variant="outlined" className={classes.formControl} fullWidth size="small">
                            <InputLabel id="demo-simple-select-outlined-label">From</InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={age}
                            onChange={handleChange}
                            size="small"
                            className="from-select"
                            >
                              
                             {removed_dulicate_array.length > 0 ? (
                              removed_dulicate_array.map((name, index)=>(
                                 <MenuItem key={index} value={name.number}>
                                    {name.number}
                                 </MenuItem>
                               ))
                             )
                              :(
                               <MenuItem value="">No Number</MenuItem>
                                )}
                                  

                            </Select>
                          </FormControl> */}
                    <span>

                      {/* <Fab color="secondary" style={{margin: "0 20px"}} onClick={makeCall}> */}
                      {calling === true ? (
                        <CircularProgress color='primary' style={{ width: 25, height: 25, }} />
                      ) : (
                        <Fab color="secondary" className="green" style={{ margin: "0 20px" }} onClick={CallType}>

                          <CallIcon />
                        </Fab>
                      )}
                    </span>

                    {app.length > 0 && app[0].type_allow === "both" && <Button
                      className="callfrom-btn"
                      variant="outlined"
                      size="small"
                      onClick={handleCallUsing}
                    >
                      Call Using
                    </Button>}

                    {/* <select className="dial-dropdown"
                            value={callFormPhoneBrowser}
                            onChange={event => setCallFormPhoneBrowser(event.target.value)}
                          >

                         {showPhoneCallType? <option value="Phone">Phone Call</option>:null}
                            {showBrowserCallType? <option value="Browser">Internet Call</option>:null}
                        </select> */}


                  </Toolbar>
                </AppBar>

              </Paper>
            </Collapse>
          </Backdrop>



          <Collapse in={dialingToggle} className="dial-pad call-on" style={{ borderRadius: 0, }}>
            <Paper elevation={4} className="dialing-call-window">
              <Grid container direction="column" alignItems="center" justify="center" style={{ height: "300px", minWidth: "300px", paddingTop: "50px" }}>
                <Grid item xs style={{ textAlign: "center" }}>
                  {ringingIconShow ? (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      {contact.length > 0 ? (
                        <>
                          {contact[0].photo !== undefined && contact[0].photo !== null && contact[0].photo !== "undefined" ? (
                            <img src={`${baseUrl}/contact/download?fileLocation=${contact[0].photo}`} style={{ width: 50, height: 50, borderRadius: 40, }} />
                          ) : (
                            <div style={{ width: 50, height: 50, borderRadius: 40, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <div style={{ color: "#fff", fontSize: 30, fontWeight: "bold" }}>
                                {contact[0].firstName.substr(0, 1)}
                              </div>

                            </div>
                          )}
                          <Button style={{ marginTop: 10, minWidth: 20, }} onClick={() => {
                            if (app[0].disable_contact === false) {
                              const contactNumber = contact[0].phoneNumber.replace('+', '')
                              navigateContactNumber(contactNumber)
                            }
                          }}>
                            <div style={{ color: "#0046BB", fontSize: 15, fontWeight: "600" }}>
                              {contact[0].firstName} {contact[0].lastName}
                            </div>
                          </Button>
                        </>
                      ) : (
                        <>
                          <div style={{ width: 50, height: 50, borderRadius: 40, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ color: "#fff", fontSize: 25, fontWeight: "bold" }}>
                              U
                            </div>
                          </div>

                          <div style={{ color: "#0046BB", fontSize: 15, fontWeight: "600", marginTop: 10 }}>
                            Unknown
                          </div>
                        </>
                      )}
                      {callFormPhoneBrowser === "Phone" ? (
                        <>
                          {incoming_sip === true ? (
                            <>
                              {app[0].hide_contact === true ? (
                                <Typography style={{ textAlign: "center", marginTop: 10 }}>
                                  XXXXXXXX{phoneNumber.slice(-3)}
                                </Typography>
                              ): (
                                <Typography style={{ textAlign: "center", marginTop: 10 }}>
                                  {contact.length > 0 ? `${contact[0].phoneNumber}` : `+${phoneNumber}`}
                                </Typography>
                              )}
                            </>
                          ): (
                            <>
                              {app[0].hide_contact === true ? (
                                <Typography style={{ textAlign: "center", marginTop: 10 }}>
                                  {call_data.Direction !== "inbound" ? `XXXXXXXX${call_data?.Called?.slice(-3)}` : contact.length > 0 ? `XXXXXXXX${contact[0].phoneNumber.slice(-3)}` : `XXXXXXXX${call_data.From.slice(-3)}`}
                                </Typography>
                              ): (
                                <Typography style={{ textAlign: "center", marginTop: 10 }}>
                                  {call_data.Direction !== "inbound" ? `+${call_data?.Called}` : contact.length > 0 ? `${contact[0].phoneNumber}` : `${call_data.From}`}
                                </Typography>
                              )}
                            </>
                          )}
                          
                        </>
                      ): (
                        <>
                          {app[0].hide_contact === true ? (
                            <Typography style={{ textAlign: "center", marginTop: 10 }}>
                              {contact.length > 0 ? `XXXXXXXX${contact[0].phoneNumber.slice(-3)}` : `XXXXXXXX${phoneNumber.slice(-3)}`}
                            </Typography>
                          ): (
                            <Typography style={{ textAlign: "center", marginTop: 10 }}>
                              {contact.length > 0 ? `${contact[0].phoneNumber}` : `+${phoneNumber}`}
                            </Typography>
                          )}
                        </>
                      )}
                    </div>
                  ) : ''}


                  {dialingIconShow ? (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      {agent_contact.length > 0 ? (
                        <>
                          {agent_contact[0].photo !== undefined && agent_contact[0].photo !== null && agent_contact[0].photo !== "undefined" ? (

                            <img src={`${baseUrl}/contact/download?fileLocation=${agent_contact[0].photo}`} style={{ width: 50, height: 50, borderRadius: 40, }} />
                          ) : (

                            <div style={{ width: 50, height: 50, borderRadius: 40, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <div style={{ color: "#fff", fontSize: 25, fontWeight: "bold" }}>
                                {agent_contact[0].firstName.substr(0, 1)}
                              </div>
                            </div>
                          )}
                          <Button style={{ marginTop: 10, minWidth: 20 }} onClick={() => {
                            if (app[0].disable_contact === false) {
                              const contactNumber = user.phone.replace('+', '')
                              navigateContactNumber(contactNumber)
                            }
                          }}>
                            <div style={{ color: "#0046BB", fontSize: 15, fontWeight: "600" }}>
                              {agent_contact[0].firstName} {agent_contact[0].lastName}
                            </div>
                          </Button>
                        </>
                      ) : (
                        <>
                          <div style={{ width: 50, height: 50, borderRadius: 40, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ color: "#fff", fontSize: 25, fontWeight: "bold" }}>
                              U
                            </div>
                          </div>
                          <div style={{ marginTop: 20, color: "#0046BB", fontSize: 15, fontWeight: "600" }}>
                            Unknown
                          </div>
                        </>
                      )}
                      <Typography style={{ textAlign: "center", marginTop: 10 }}>
                        +{user.phone}
                      </Typography>
                    </div>

                  ) : ''}

                  {/* <img src={dialGif} style={{width: "100px"}} /> */}

                </Grid>
              </Grid>

              <AppBar position="inherit" color="transparent" className="call-widjet-number-appbar">
                <Toolbar>
                  <FormControl variant="outlined" fullWidth size="small">

                  </FormControl>
                  <span>
                    {killing === true ? (
                      <CircularProgress color='primary' style={{ width: 30, height: 30 }} />
                    ) : (
                      <>
                        {power_dialer === true ? (
                           <>
                             {phone_dialer.length === power_dialer_index+1 ? (
                              <Fab color="error" style={{ margin: "0 20px", marginBottom: 50, marginLeft: 20,  }} onClick={() => exit_from_call()} >
                                <CloseIcon />
                              </Fab>
                             ): (
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                             <Fab color="secondary" style={{ margin: "0 20px", marginBottom: 50, }} onClick={() => next_call()} >
                                <SkipNextRoundedIcon />
                             </Fab>
                             <Fab color="error" style={{ margin: "0 20px", marginBottom: 50, marginLeft: 20,  }} onClick={() => exit_from_call()} >
                                <CloseIcon />
                             </Fab>
                           </div>
                             )}
                           </>
                        ): (
                          <>
                            {incoming_sip === false ? (
                              <Fab color="secondary" style={{ margin: "0 20px", marginBottom: 50, }} onClick={() => disconnectType()} >
                              <CallEndIcon />
                              </Fab>
                            ): (
                              <div style={{ width: 330, display: "flex", alignItems: "center", justifyContent: "space-around",}}>
                                <Button className="accept-btn" variant="outlined"  onClick={answerCall} style={{ width: "40%", }}>
                                  <CallIcon /> Answer
                                </Button>
                                <Button className="decline-btn" variant="outlined" onClick={declineCall} style={{ width: "40%" }}>
                                    <CallEndIcon /> Decline
                                  </Button>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                    </span>
                  <FormControl variant="outlined" fullWidth size="small">

                  </FormControl>
                </Toolbar>
              </AppBar>

            </Paper>
          </Collapse>

          {/* <Collapse in={callOnProgress} className="dial-pad call-progress" style={{width:"20em"}} > */}
          <Collapse in={callOnProgress} className="dial-pad call-progress">
            <Paper elevation={4} className="connected-call-window">

              <>
                <div className="fixed-connect-call" style={{ backgroundColor: callFormPhoneBrowser !== "Phone" ? completed_call === true ? "#f50057" : "#0046BB" : call_data.Direction === "inbound" ? call_status === "completed" ? "#f50057" : "#0046BB" : call_data.CallStatus === "completed" ? "#f50057" : "#0046BB" }}>

                  <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignSelf: "center", width: "100%", alignItems: "center", justifyContent: "center", }}>
                    <>
                      {incoming_sip === false && <>
                        {phoneDetails.length > 0 ? (
                      <img src={`https://flagcdn.com/w80/${phoneDetails[0].country_iso.slice(0, 2).toLowerCase()}.png`} style={{ width: 20, height: 15 }} />
                    ) : (
                      <img src={`https://flagcdn.com/w80/in.png`} style={{ width: 20, height: 15 }} />
                    )}
                      </>}
                    </>
                    <>
                      {phoneDetails.length > 0 ? (
                        <>
                          {phoneDetails[0].name !== "" && phoneDetails[0].name !== null && phoneDetails[0].name !== undefined ? (
                            <div style={{ color: "#fff", fontSize: 15, marginLeft: 20, fontWeight: "bold" }}>
                              {phoneDetails[0].name}
                            </div>
                          ) : (
                            <>
                              {callFormPhoneBrowser === "Phone" ? (
                                <div style={{ color: "#fff", fontSize: 15, marginLeft: 20, fontWeight: "bold" }}>
                                {call_data.Direction === "inbound" ? call_data.Called : call_data.Caller}
                                </div>
                              ): (
                                <div style={{ color: "#fff", fontSize: 15, marginLeft: 20, fontWeight: "bold" }}>
                                  {age}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <div style={{ color: "#fff", fontSize: 15, marginLeft: 20, fontWeight: "bold" }}>
                          {call_data.Direction === "inbound" ? call_data.Called : call_data.Caller}
                        </div>
                      )}
                    </>
                  </div>

                  <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Button style={{ width: "80%", marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", borderRadius: 20, alignSelf: "center" }} onClick={() => {
                      if (contact.length > 0 && app[0].disable_contact === false) {
                        const contactNumber = contact[0].phoneNumber.replace('+', '')
                        navigateContactNumber(contactNumber)
                      }
                    }}>
                      {contact.length > 0 ? (
                        <>
                          {contact[0].photo !== undefined && contact[0].photo !== null && contact[0].photo !== "" && contact[0].photo !== "undefined" ? (
                            <img src={`${baseUrl}/contact/download?fileLocation=${contact[0].photo}`} style={{ width: 40, height: 40, borderRadius: 30, }} />
                          ) : (
                            <div style={{ width: 40, height: 40, borderRadius: 30, backgroundColor: "#FAFAFA", display: "flex", alignItems: "center", justifyContent: "center", }}>
                              <div style={{ color: "#0046BB", fontSize: 20, }}>
                                {contact[0].firstName.substr(0, 1)}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ width: 40, height: 40, borderRadius: 30, backgroundColor: "#FAFAFA", display: "flex", alignItems: "center", justifyContent: "center", }}>
                          <div style={{ color: "#0046BB", fontSize: 20, }}>
                            U
                          </div>
                        </div>
                      )}
                      <div style={{ marginLeft: 10 }}>
                        {contact.length > 0 ? (
                          <div style={{ fontSize: 15, fontWeight: "bold", color: "#FAFAFA" }}>
                            {contact[0].firstName} {contact[0].lastName}
                          </div>
                        ) : (
                          <div style={{ fontSize: 15, fontWeight: "bold", color: "#FAFAFA" }}>
                            Unknown
                          </div>
                        )}
                        {callFormPhoneBrowser === "Phone" ? (
                          <>
                            {call_data !== "" && <>
                            {app[0].hide_contact === false ? (
                              <div style={{ color: "#FAFAFA", marginTop: 7, fontSize: 13, }}>
                              {isCallIncomingDirection === true ? contact.length > 0 ? `${contact[0].phoneNumber}` : `${call_data.Caller}` : contact.length > 0 ? contact[0].phoneNumber : `+${phoneNumber}`}
                             </div>
                            ): (
                              <div style={{ color: "#FAFAFA", marginTop: 7, fontSize: 13, }}>
                              {isCallIncomingDirection === true ? contact.length > 0 ? `XXXXXXXX${contact[0].phoneNumber.slice(-3)}` : `XXXXXXXX${call_data.Caller.slice(-3)}` : contact.length > 0 ? `XXXXXXXX${contact[0].phoneNumber.slice(-3)}` : `XXXXXXXX${phoneNumber.slice(-3)}`}
                             </div>
                            )}
                            </>}
                          </>
                        ): (
                          <>
                            {app[0].hide_contact === false ? (
                              <div style={{ color: "#FAFAFA", marginTop: 7, fontSize: 13, }}>
                              {contact.length > 0 ? `${contact[0].phoneNumber}` : `+${selected_country.phone}${phoneNumber}`}
                              </div>
                            ): (
                              <div style={{ color: "#FAFAFA", marginTop: 7, fontSize: 13, }}>
                                {contact.length > 0 ? `XXXXXXXX${contact[0].phoneNumber.slice(-3)}` : `XXXXXXXX${phoneNumber.slice(-3)}`}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Button>
                  </div>
                  <Typography variant="p" className="connected-timer">
                    {formatTime()}
                  </Typography>

                  {callFormPhoneBrowser === 'Browser' ? (
                    <>

                      {mute === true ? (
                        <Button className="icon-text-btn" onClick={unMuteCall}>
                          <div>
                            <MicOffOutlinedIcon /><br />
                            Unmute
                          </div>
                        </Button>
                      ) : (
                        <Button className="icon-text-btn" onClick={muteCall}>
                          <div>
                            <MicOffRoundedIcon /><br />
                            Mute
                          </div>
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {incoming_sip === true && <>

                        {mute === true ? (
                          <Button className="icon-text-btn" onClick={unMuteCall}>
                              <div>
                                <MicOffOutlinedIcon /><br />
                                Unmute
                              </div>
                          </Button>
                        ) : (
                          <Button className="icon-text-btn" onClick={muteCall}>
                            <div>
                              <MicOffRoundedIcon /><br />
                              Mute
                            </div>
                          </Button>
                        )}
                    </>}
                    </>
                  )}




                  {hold === false ? (
                    <Button className="icon-text-btn" onClick={() => {
                      if (callFormPhoneBrowser !== "Phone") {
                        g_client.Hold();
                        setHold(true)
                      }
                    }}>
                      <div>
                        <PauseRoundedIcon /><br />
                        Hold
                      </div>
                    </Button>
                  ): (
                    <Button className="icon-text-btn" onClick={() => {
                      if (callFormPhoneBrowser !== "Phone") {
                        g_client.Hold();
                        setHold(false)
                      }
                    }}>
                      <div>
                        <PlayArrowRoundedIcon /><br />
                        Unhold
                      </div>
                    </Button>
                  )}
                  {callFormPhoneBrowser === 'Browser' ? (
                    <Button className="icon-text-btn">
                      <div>
                        <DialpadRoundedIcon /><br />
                        Keyboard
                      </div>
                    </Button>

                  ) : (
                    <>
                      {incoming_sip === true && <Button className="icon-text-btn">
                      <div>
                        <DialpadRoundedIcon /><br />
                        Keyboard
                      </div>
                    </Button>}
                    </>
                  )}
                  <Button className="icon-text-btn" style={{ marginLeft: 50 }}>
                    <div>
                      <VoicemailRoundedIcon /><br />
                      Record
                    </div>
                  </Button>
                </div>

                <Grid container style={{ marginTop: "-30px" }}>
                  <Grid item xs={12}>
                    <AppBar position="inherit" color="transparent" className="call-widjet-action-appbar">
                      <Toolbar>
                        <Button variant="contained" className="icon-contained-btn" onClick={handleShowAddNotes}>
                          <div>
                            <NoteAddRoundedIcon /><br />
                            Notes
                          </div>
                        </Button>

                        <Button variant="contained" className="icon-contained-btn" onClick={handleShowTags}>
                          <div>
                            <LocalOfferRoundedIcon /><br />
                            Tags
                          </div>
                        </Button>

                        <Button variant="contained" className="icon-contained-btn" onClick={handleShowAssign}>
                          <div>
                            <PersonAddRoundedIcon /><br />
                            Assign
                          </div>
                        </Button>



                      </Toolbar>
                    </AppBar>
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" spacing={2} className="cancel-call">
                  <Grid item xs></Grid>
                  <Grid item>
                    {showTags === false && showAddNotes === false && <>
                      {killing === true ? (
                        <CircularProgress color='primary' style={{ width: 25, height: 25 }} />
                      ) : (
                        <>
                          {power_dialer === true ? (
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Fab color="secondary" style={{ margin: "0 20px", marginBottom: 50, }} onClick={() => next_call()} >
                               <SkipNextRoundedIcon />
                            </Fab>
                            <Fab color="error" style={{ margin: "0 20px", marginBottom: 50, marginLeft: 20 }} onClick={() => exit_from_call()} >
                               <CloseIcon />
                            </Fab>
                            </div>
                          ): (
                            <Fab color="secondary" style={{ margin: "0 30px" }} onClick={() => {
                              disconnectType();
                            }}>
                              <CloseRoundedIcon />
                            </Fab>
                          )}
                        </>
                      )}
                    </>}

                  </Grid>
                  <Grid item xs></Grid>
                </Grid>

              </>




              <div className="connected-expanded-call">
                <div style={{ padding: "0px 20px 0 20px" }}>
                  <Grid container direction="row" alignItems="center" spacing={2} style={{ background: "#eee", borderRadius: "5px" }}>
                    <Grid item xs={12} sm={6}>
                      <List style={{ padding: "0px" }}>
                        <span className="call-list-floating">
                          <ListItem style={{ padding: "0px" }}>
                            <ListItemAvatar>


                              <div className="dialing-icon-cover">
                                <div className="dialing-icon-animate">
                                  <div className='phone-connected animate-call-connected'>
                                    <span className="material-icons">
                                      <CallIcon />
                                    </span>
                                  </div>
                                  <div className="circle1 animate-call-connected"></div>
                                  <div className="circle2 animate-call-connected"></div>
                                </div>
                              </div>


                            </ListItemAvatar>
                            <ListItemText primary="Connected..." secondary="Outgoing | Manual" />
                          </ListItem>
                        </span>
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <List style={{ padding: "0px" }}>
                        <span className="call-list-floating">
                          <ListItem style={{ padding: "0px" }}>

                            <ListItemSecondaryAction style={{ paddingRight: "20px" }}>
                              <ListItemText style={{ textAlign: "right" }} secondary="Total Call Duration" />
                              <Typography style={{ textAlign: "right" }} variant="h5">{formatTime()}</Typography>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </span>
                      </List>
                    </Grid>
                  </Grid>

                </div>

                <div style={{ padding: "10px 10px 0 10px" }}>
                  <Grid container direction="row" alignItems="center" spacing={2}>


                    <Grid item xs={12}>
                      <TextField id="outlined-basic" label="Notes" variant="outlined" value={callNotes} onChange={event => setCallNotes(event.target.value)} fullWidth size="small" multiline rows={4} />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <Card style={{ padding: "3px 10px 6px 10px", border: "1px solid rgba(0, 0, 0, 0.23)", boxShadow: "none" }}>
                        <Typography variant="subtitle1">
                          {/* John Doe */}
                          {nameToBeDisplayed.map((name) => {
                            if (name.number === phoneNumber) {
                              return name.name
                            } else {
                              return null
                            }
                          })}
                        </Typography>
                        <div className="call-phone-flag-only">
                          <PhoneInput
                            country={'in'}
                            value={phoneNumber}
                            disabled
                          />
                        </div>
                      </Card>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>

                          <Autocomplete
                            fullWidth
                            size="small"

                            value={value}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={options}
                            // getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" label="Call Tags" placeholder="Favorites" />
                            )}
                          />



                        </Grid>

                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion style={{ border: "1px solid rgba(0, 0, 0, 0.23)", boxShadow: "none" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Call History</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ maxHeight: "200px", overflowY: "auto" }}>

                          <TableContainer>
                            <Table aria-label="simple table">

                              <TableBody>

                                <TableRow>

                                  <TableCell>
                                    +9688573582
                                  </TableCell>
                                  <TableCell>
                                    <img src={MissedCall} style={{ width: "20px" }} />
                                  </TableCell>
                                  <TableCell>
                                    Sreeju
                                  </TableCell>

                                  <TableCell>
                                    <Typography variant="caption" display="block">
                                      2 minutes ago
                                    </Typography>

                                  </TableCell>


                                </TableRow>

                              </TableBody>
                            </Table>
                          </TableContainer>

                        </AccordionDetails>
                      </Accordion>

                    </Grid>


                  </Grid>

                  <AppBar position="inherit" color="transparent" className="call-widjet-action-appbar">
                    <Toolbar>
                      {
                        stepOne && callFormPhoneBrowser === 'Browser' ? (
                          <IconButton onClick={setStepThree} >
                            {
                              stepTwo ?
                                (<div>
                                  <Tooltip title="Mute">
                                    <Button variant="raised" style={{ border: "1px solid blue" }} onClick={muteCall} >
                                      <MicOffOutlinedIcon />
                                    </Button>
                                  </Tooltip>
                                </div>) : (
                                  <div>
                                    <Tooltip title="UnMute">
                                      <Button variant="raised" style={{ border: "1px solid red" }} onClick={unMuteCall} >
                                        <MicNoneOutlined />
                                      </Button>
                                    </Tooltip>
                                  </div>
                                )
                            }

                          </IconButton>
                        ) : ''
                      }

                      <div style={{ textAlign: "center" }}>
                        <IconButton >
                          <KeyboardOutlinedIcon />
                        </IconButton>
                        <Typography variant="caption" display="block">
                          KeyPad
                        </Typography>
                      </div>

                      <div style={{ textAlign: "center" }}>
                        {showTags === false && showAddNotes === false && <>
                          {killing === true ? (
                            <CircularProgress color='primary' style={{ width: 30, height: 30 }} />
                          ) : (
                            <IconButton color="secondary" onClick={() => {
                              disconnectType();
                            }}>
                              <CallEndOutlinedIcon />
                            </IconButton>
                          )}
                        </>}
                        <Typography variant="caption" display="block" color="secondary">
                          End Call
                        </Typography>
                      </div>

                    </Toolbar>
                  </AppBar>




                </div>
              </div>

            </Paper>
          </Collapse>

          {bottomNav === true && settings === false ? (
            <AppBar position="inherit" color="transparent" className="call-widjet-bottom-appbar">
              <Toolbar>
                {app[0].pwd_allow === true && <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={show_power_dialer}>
                  <BoltIcon />
                </IconButton>}

                <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={showAllConversation}>
                  <SettingsPhoneOutlinedIcon />
                </IconButton>

                {dialPadBtn === true ? (
                  <>
                    <IconButton color="inherit" onClick={showDial}>
                      <DialpadOutlinedIcon />
                    </IconButton>
                  </>
                ) : (
                  <IconButton color="inherit" onClick={hideDial}>
                    <DialpadOutlinedIcon />
                  </IconButton>
                )}



                <IconButton edge="end" color="inherit" onClick={showSettings}>
                  <SettingsOutlinedIcon />
                </IconButton>

              </Toolbar>
            </AppBar>
          ) : ''}

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={showError}
                autoHideDuration={6000}
                onClose={handleErrorClose}
                message={errorMessage}
                action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={handleErrorClose}>
                            OK
                        </Button>

                    </React.Fragment>
                }
            />
        </Collapse>
      </Card>): ('')}

    </span>
  )

  
}