// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  Userconfirmation: '/UserConfirmation/:token',
  invitedSignup: '/invitedSignup'

};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/matrix'),
    profile: path(ROOTS_DASHBOARD, '/profile'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  number: {
    root: path(ROOTS_DASHBOARD, '/number'),
    buy: path(ROOTS_DASHBOARD, '/number/buy?offsetCount=0&limit=10'),
    manage: path(ROOTS_DASHBOARD, '/number/manage'),
    cancelled: path(ROOTS_DASHBOARD, '/number/cancelled'),
  },
  contacts: {
    root: path(ROOTS_DASHBOARD, '/contacts'),
    manage: path(ROOTS_DASHBOARD, '/contacts/manage?offsetCount=0&limit=10'),
    new: path(ROOTS_DASHBOARD, '/contacts/manage/new'),
    contactsearch: path(ROOTS_DASHBOARD, '/contacts/manage/:id'),
    update: path(ROOTS_DASHBOARD, '/contacts/manage/account/:id'),
    group: path(ROOTS_DASHBOARD, '/contacts/group?offsetCount=0&limit=10'),
    custom: path(ROOTS_DASHBOARD, '/contacts/customfields'),
    import: path(ROOTS_DASHBOARD, '/contacts/import'),
  },
  voicemail: {
    root: path(ROOTS_DASHBOARD, '/voicemail'),
    voicemailbox: path(ROOTS_DASHBOARD, '/voicemail/voicemailbox?offsetCount=0&limit=10'),
  },
  powerdialer: {
    root: path(ROOTS_DASHBOARD, '/powerdialer?offsetCount=0&limit=10'),
  },
  inbox: {
    root: path(ROOTS_DASHBOARD, '/inbox'),
    voice: path(ROOTS_DASHBOARD, '/inbox/voice?offsetCount=0&limit=10'),
    voicesearch: path(ROOTS_DASHBOARD, '/inbox/voice/search/:number'),
    voicefilter: path(ROOTS_DASHBOARD, '/inbox/voice/filter'),
    voiceexport: path(ROOTS_DASHBOARD, '/inbox/voice/export'),
    voicenotes: path(ROOTS_DASHBOARD, '/inbox/voice/notes'),
    whatsapp: path(ROOTS_DASHBOARD, '/inbox/whatsapp'), 
    sms: path(ROOTS_DASHBOARD, '/inbox/sms'),
    telegram: path(ROOTS_DASHBOARD, '/inbox/telegram'),
    whatsappchat: (name) => path(ROOTS_DASHBOARD, `/inbox/whatsapp/${name}`)
  },
  apps: {
    root: path(ROOTS_DASHBOARD, '/apps'),
    ivrstudio: path(ROOTS_DASHBOARD, '/apps/ivrstudio'),
    ivrstudioflow: path(ROOTS_DASHBOARD, '/apps/ivrstudio/flow/:id'),
  },
  support: {
    root: path(ROOTS_DASHBOARD, '/support'),
    tickets: path(ROOTS_DASHBOARD, '/support/tickets'),
    livesupport: path(ROOTS_DASHBOARD, '/support/livesupport'),
    helpdocs: path(ROOTS_DASHBOARD, '/support/helpdocs'),
    feedback: path(ROOTS_DASHBOARD, '/support/feedback'),
  },
  setting: {
    inbox: path(ROOTS_DASHBOARD, '/settings/inbox'),
    custom: path(ROOTS_DASHBOARD, '/settings/customfields'),
  },

  tickets: {
    matrix: path(ROOTS_DASHBOARD, '/tickets/matrix'),
    create: path(ROOTS_DASHBOARD, '/tickets/create'),
  },

  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  telegram: {
    root: path(ROOTS_DASHBOARD, '/telegram'),
    new: path(ROOTS_DASHBOARD, '/telegram/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/telegram/${name}`),
  },
  viber: {
    root: path(ROOTS_DASHBOARD, '/viber'),
    new: path(ROOTS_DASHBOARD, '/viber/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/viber/${name}`),
  },
  tickets: path(ROOTS_DASHBOARD, '/tickets'),




  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    accountinvoice: (id) => path(ROOTS_DASHBOARD, `/user/account/invoiceDetails/${id}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/details'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
