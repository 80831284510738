import React, {useState, useEffect} from 'react';
import { Link as RouterLink, useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import {
    Drawer,
    AppBar,
    Toolbar,
    IconButton,
    Avatar,
    Typography,
    Tooltip,
    Grid,
    Button,
    TextField,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
    Stack,
    Chip
} from '@mui/material';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { addNotes, removeNotes } from '../../../redux/slices/Inbox/voice';
import moment from 'moment';

import Iconify from '../../Iconify';
import { SET_CALL_HISTORY, SET_MISSED_CALL, SET_NOTES } from 'src/redux/userReducer';

export default function Notes({
    props,
    open_notes,
    setOpenNotes
}){

    console.log('notes props', props)

    const {callHistory, missed_call, notes} = useSelector((state) => state.user)
    



    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const notesArray = props.row.Notes !== null ? props.row.Notes : []

    useEffect(() => {
        if (open_notes === true) {
            setDrawerOpen(true)
        }
    }, [])


    const noteId = props.row._id

    console.log("noteId", noteId);
    console.log("notes", notesArray)
    const [noteArrayState, setNotesArrayState] = useState([])

    useEffect(() => {
        const mutableJSON = JSON.parse(JSON.stringify(notesArray))
        let array = []
        mutableJSON.map((item)=>{
            if(item.value !== ''){
                array.push({createdAt: item.createdAt, name: item.name, value: item.value})
            }
        })
        if (array !== undefined && array !== null) {
            setNotesArrayState(array)
        }
    }, [notesArray])
    

    const [note, setNote] = useState('')

    const [drawerOpen, setDrawerOpen] = useState(false)

    const [editShow, setEditShow] = useState(false)
    const [editIndex, setEditIndex] = useState(0)
    const [editNote, setEditNote] = useState('')
  
    const handleDrawerClose = () =>{
      setDrawerOpen(false)
      if (setOpenNotes !== undefined && setOpenNotes !== null) {
        setOpenNotes(false)
      }
      localStorage.setItem('voiceNotesTrigger', false)
    }
    const handleDrawerOpen = () =>{
      setDrawerOpen(true)
      localStorage.setItem('voiceNotesTrigger', true)

    }


    const handleAddNotes = () =>{
        console.log('add notes trigger', noteArrayState, noteId )
        noteArrayState.push({createdAt: Date.now(), name: '', value: note})
        console.log('add notes trigger', noteArrayState, noteId )
        if (props.row.ParentCallSid === undefined) {
            dispatch(addNotes(noteId, noteArrayState, props.row.ParentCall.ParentCallSid))
        } else {
            dispatch(addNotes(noteId, noteArrayState, props.row.ParentCallSid))
        }
        
        setNote('')
        
    }

    useEffect(() => {
        if (notes.length > 0) {
           const formValues = [...callHistory]
           console.log("callHistory", formValues);

           const findIndex= callHistory.findIndex(value => { return noteId === value._id });
                const missed_formValues = [...missed_call];
                console.log("missed_call", missed_formValues)
                const index = missed_call.findIndex(value => { return noteId === value._id });
                if (index !== -1) {
                  missed_formValues[index].Notes = notes;
                  dispatch({
                    type: SET_MISSED_CALL,
                    payload: missed_formValues
                  })
                }
      
                if (findIndex !== -1) {
                  formValues[findIndex].Notes = notes;
                  dispatch({
                    type: SET_CALL_HISTORY,
                    payload: formValues
                  })
                }
            dispatch({
              type: SET_NOTES,
              payload: []
            })
        }
    }, [notes])

    

    const updateNotes = () =>{
        setEditShow(false)
        setEditNote('')
        noteArrayState[editIndex].value = editNote
        dispatch(addNotes(noteId, noteArrayState));
       
        //dispatch(updateNotes(noteId, noteArrayState));
    }

    const cancelEdit = () =>{
        setEditShow(false)
        setEditNote('')
    }

    const clearNote = () =>{
        setNote('')
    }

    const deleteNotes = (index) =>{
        noteArrayState.splice(index, 1)
        dispatch(removeNotes(noteId, noteArrayState));
       
    }

    const editNotes = (value, index) =>{
        console.log('notes index', index)
        setEditShow(true)
        setEditIndex(index)
        setEditNote(value)
    }

   

    useEffect(() => {
      console.log('noteArrayState', noteArrayState)
    }, [noteArrayState])

    function utc(myDateTime){
       // var localDate = new Date(utcDate);
       let myDate = new Date(myDateTime).toLocaleDateString('en-US');
       let myTime = new Date(myDateTime).toLocaleTimeString('en-US');
        return `${myDate} , ${myTime}`
    }

 
    

    return(
        <span>
            {open_notes !== true && <>
                {noteArrayState.length > 0 ? (
    <Tooltip title="Notes" >
        <IconButton size='small' color='primary' onClick={()=>handleDrawerOpen()}>
            <Iconify icon='uil:clipboard-notes'  />
        </IconButton>
    </Tooltip> 
) : (
    <Tooltip title="Notes" >
        <IconButton size='small' onClick={()=>handleDrawerOpen()}>
            <Iconify icon='uil:clipboard-notes'  />
        </IconButton>
    </Tooltip> 
)}
            </>}
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose} className="numberSettigns">

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                Notes
                </Typography>

                <IconButton >
                  <Iconify icon={'ic:round-refresh'} width={20} height={20} />
                </IconButton>

                <IconButton onClick={handleDrawerClose}>
                  <Iconify icon={'eva:close-fill'} width={20} height={20} />
                </IconButton>
              </Stack>

              <Divider />



            <div style={{padding: "10px 30px", maxWidth: "600px"}}>

            {editShow === true ? (
                 
            <Grid container direction="row" spacing={2} style={{marginTop: "10px"}}>
                    <Grid item md={12}>
                        <TextField
                            id="firstName"
                            variant="outlined"
                            placeholder='Type Something here'
                            value={editNote}
                            onChange={e => setEditNote(e.target.value)}
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Grid>

                  <Grid item xs></Grid>
                  <Grid item >
                    <Button variant='outlined' style={{marginRight: "10px"}} onClick={cancelEdit}>
                      cancel
                    </Button>
                    <Button variant='contained' color='primary' onClick={updateNotes}>
                      Update Note
                    </Button>
                  </Grid>


              </Grid>

 ) : (
                      
            <Grid container direction="row" spacing={2} style={{marginTop: "10px"}}>
                    <Grid item md={12}>
                        <TextField
                            id="firstName"
                            variant="outlined"
                            placeholder='Type Something here'
                            value={note}
                            onChange={e => setNote(e.target.value)}
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Grid>

                  <Grid item xs></Grid>
                  <Grid item >
                    <Button variant='outlined' style={{marginRight: "10px"}} onClick={clearNote}>
                      Clear
                    </Button>
                    <Button variant='contained' color='primary' disabled={note === ''} onClick={handleAddNotes}>
                      Add Note
                    </Button>
                  </Grid>


              </Grid>
              
)}

              

<Typography variant="subtitle1" style={{margin: "0px 10px 0 0"}}>Notes <Chip size="small" label={noteArrayState.filter(item => item.value !== '').length} color="primary" component="a" /> </Typography>

<List className="notes-list">

    {noteArrayState.filter(item => item.value !== '').map((item, index)=>{
        return(
            <>

            <Grid container direction='row' spacing={3} style={{marginBottom: "20px"}}>
                <Grid item>
                    <Avatar className="light-avatar">
                        <Iconify icon='uil:clipboard-notes'  />
                    </Avatar>
                </Grid>
                <Grid item xs>
                    <Typography variant='subtitle2' style={{display: "block"}}>
                        {item.value}
                    </Typography>
          
                    <Typography variant='caption' style={{display: "block"}}>
                    {/* {timeStamp(item.createdAt)} */}
                    
                    {utc(item.createdAt)}
                     
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item>

                    <IconButton size="small" color="primary" onClick={()=>editNotes(item.value, index)}>
                        <Iconify icon='fluent:clipboard-text-edit-20-regular'  />
                    </IconButton>

                    <IconButton size="small"  color="secondary" onClick={()=>deleteNotes(index)}>
                        <Iconify icon='fluent:delete-28-regular'  />
                    </IconButton>

                </Grid>
            </Grid>
            
            </>
        )
    })}

</List>

            </div>
        </Drawer>




         
        </span>
    )
}


