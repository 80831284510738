import { Button, Snackbar, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { edit_app_config } from "src/redux/userReducer";


export const PowerDialer_General = ({ props, setProps,setDrawerOpen }) => {
    const [showError, setShowError] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    useEffect(() => {
        if (props.create === undefined) {
            var app_details = props;
            app_details.create = false;
            setProps(app_details);
        }

        if (props.delete === undefined) {
            var app_details = props;
            app_details.delete = false;
            setProps(app_details)
        }
    }, [])

    const change_props = (name, value) => {
        var app_details = props;
        app_details[name] = value;
        console.log("app_details", app_details)
        setProps(app_details)
    }

    const edit_app = (body) => {
        setDisabled(true)
        const token = localStorage.getItem("redux-token");
        const config_id = props._id;
        console.log("props", props)

        console.log("body", body)
        edit_app_config(config_id, body, token).then((res) => {
            setErrorMessage("PowerDialer config is updated successfully");
            setShowError(true)
            setDisabled(false)
        }).catch((err) => {
            console.log("error", err);
            setErrorMessage("PowerDialer config is not updated");
            setShowError(true)
            setDisabled(false)
        })
    }
    return (
        <div style={{ display: "flex", width: "100%", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ display: "flex", width: "100%", flexDirection: "row", }}>
                <div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Can Add
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Can Delete
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: 40 }}>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <Switch checked={props.create} onChange={(e) => change_props("create", e.target.checked)}/>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <Switch checked={props.delete} onChange={(e) => change_props("delete", e.target.checked)}/>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end", flexDirection: "row", marginTop: 10 }}>
                <Button style={{ marginRight: 20, height: 40, borderRadius: 10, backgroundColor: "#fff", }} variant="outlined" size='small' onClick={() => setDrawerOpen(false)}>
                    <div style={{ color: "#0046BB", marginLeft: 10, marginRight: 10, fontWeight: "600"}}>
                        Cancel
                    </div>
                </Button>
                <Button style={{ marginRight: 20, height: 40, borderRadius: 10, }} variant="contained" size='small' disabled={disabled} onClick={() => edit_app(props)}>
                    <div style={{ color: "#fff", marginLeft: 10, marginRight: 10, fontWeight: "600"}}>
                        Save Changes
                    </div>
                </Button>
            </div>
            <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        message={errorMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => setShowError(false)}>
              OK
            </Button>

          </React.Fragment>
        }
      />
        </div>
    )
}