import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { AccountBilling } from 'src/sections/@dashboard/user/account';
import { _userAddressBook, _userInvoices, _userPayment } from 'src/_mock';



import {Application_configure} from 'src/components/Setting/Application_configure';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {

  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

const MainLayout = Loadable(lazy(() => import('../layouts/main')));
const DashboardLayout = Loadable(lazy(() => import('../layouts/dashboard')));
const LogoOnlyLayout = Loadable(lazy(() => import('../layouts/LogoOnlyLayout')));

const Profile = Loadable(lazy(() => import('src/layouts/dashboard/navbar/Profile')));
const UserConfirmation = Loadable(lazy(() => import('src/sections/@dashboard/user/account/UserConfirmation')));
const InvitedSignup = Loadable(lazy(() => import('src/sections/@dashboard/user/account/InvitedSignup')));
const Welcome = Loadable(lazy(() => import('src/components/Inbox/Welcome')));
const ChooseChannel = Loadable(lazy(() => import('src/components/Inbox/ChooseChannel')));

const InboxWhatsAppCompose = Loadable(lazy(() => import('src/pages/inbox/InboxWhatsappCompose')));
const Inbox = Loadable(lazy(() => import('src/components/Setting/Inbox')));
const EditSettings = Loadable(lazy(() => import('src/components/Setting/EditSettings')));

const Teammanagement = Loadable(lazy(() => import('src/sections/@dashboard/user/account/Teammanagement')));
const RoleDataGrid = Loadable(lazy(() => import('src/components/Profile/Role/RoleDataGrid')));
const WhatsappEdit = Loadable(lazy(() => import('src/components/Setting/WhatsappEdit')));
const SmsEdit = Loadable(lazy(() => import('src/components/Setting/SmsEdit')));
const Edittelegram = Loadable(lazy(() => import('src/components/Setting/Edittelegram')));
const Editviber = Loadable(lazy(() => import('src/components/Setting/Editviber')));
const InboxTelegram = Loadable(lazy(() => import('src/pages/inbox/InboxTelegram')));
const InboxViber = Loadable(lazy(() => import('src/pages/inbox/InboxViber')));
const MessengerEdit = Loadable(lazy(() => import('src/components/Setting/MessengerEdit')));
const InboxMessenger = Loadable(lazy(() => import('src/pages/inbox/InboxMessenger')));
const InstagramEdit = Loadable(lazy(() => import('src/components/Setting/InstagramEdit')));
const InboxInstagram = Loadable(lazy(() => import('src/pages/inbox/InboxInstagram')));
const PowerDialer = Loadable(lazy(() => import('src/components/PoweDialer/PowerDialer')));
const Application = Loadable(lazy(() => import('src/components/Setting/Application')));


// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')))

// Number
const NumberBuy = Loadable(lazy(() => import('../pages/dashboard/NumberBuy')));
const NumberManage = Loadable(lazy(() => import('../pages/dashboard/NumberManage')));
const NumberCancelled = Loadable(lazy(() => import('../pages/dashboard/NumberCancelled')));

// Contacts
const ContactsManage = Loadable(lazy(() => import('../pages/dashboard/ContactsManage')));
const ContactsNew = Loadable(lazy(() => import('../pages/dashboard/ContactsNew')));
const ContactsGroup = Loadable(lazy(() => import('../pages/dashboard/ContactsGroup')));
const ContactsAccount = Loadable(lazy(() => import('../pages/dashboard/ContactsAccount')));
const ContactCustomFields = Loadable(lazy(() => import('../pages/dashboard/ContactCustomFields')));
const ContactImport = Loadable(lazy(() => import('../pages/dashboard/ContactImport')));

// Voicemail
const VoiceMailBox = Loadable(lazy(() => import('../pages/dashboard/VoiceMailBox')));

// Inbox
const InboxVoice = Loadable(lazy(() => import('../pages/inbox/InboxVoice')));
const InboxVoiceExport = Loadable(lazy(() => import('../pages/inbox/InboxVoiceExport')));
// const InboxWhatsApp = Loadable(lazy(() => import('../pages/inbox/InboxWhatsApp')));
const InboxSms = Loadable(lazy(() => import('../pages/inbox/InboxSms')));
const CustomFields = Loadable(lazy(() => import('../pages/dashboard/ContactCustomFields')));
const InboxWhatsapp = Loadable(lazy(() => import('../components/Inbox/Whatsapp/index')));


// app
const AppIvrStudio = Loadable(lazy(() => import('../pages/dashboard/AppIvrStudio')));
const AppIvrStudioFlow = Loadable(lazy(() => import('../components/IvrStudioApp/index')));

// support
const SupportTickets = Loadable(lazy(() => import('../pages/dashboard/SupportTickets')));
const SupportLiveSupport = Loadable(lazy(() => import('../pages/dashboard/SupportLiveSupport')));
const SupportHelpDocs = Loadable(lazy(() => import('../pages/dashboard/SupportHelpDocs')));
const SupportFeedback = Loadable(lazy(() => import('../pages/dashboard/SupportFeedback')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));


// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// Tickets

const Tickets = Loadable(lazy(() => import('../pages/dashboard/Tickets')));
const TicketsExport = Loadable(lazy(() => import('../components/Tickets/TicketsExport')));
const CreateTicket = Loadable(lazy(() => import('../components/Tickets/CreateTicket')));



export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          // index: true,
          element: <Login />

        },
        {
          path: 'register',
          element: <Register />

        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'matrix', element: <Dashboard /> },

        {
          path: 'profile',
          children: [
            { element: <Navigate to="/dashboard/profile/general" replace />, index: true },
            { path: 'general', element: <Profile /> },
            { path: 'billing', element: <Profile /> },
            { path: 'team', element: <Profile /> },
            { path: 'role', element: <Profile /> },

          ],
        },

        {
          path: 'number',
          children: [
            { element: <Navigate to="/dashboard/number/buy" replace />, index: true },
            { path: 'buy', element: <NumberBuy /> },
            { path: 'manage', element: <NumberManage /> },
            { path: 'cancelled', element: <NumberCancelled /> },

          ],
        },
        { path: 'powerdialer', element: <PowerDialer /> },
        {
          path: 'settings',
          children: [
            { element: <Navigate to='/dashboard/settings' replace />, index: true },
            { path: 'inbox', element: <Inbox /> },
            { path: "application", element: <Application/>},
            { path: "application/configure", element: <Application_configure/>},
            { path: 'inbox/:id', element: <EditSettings /> },
            { path: 'inbox/whatsapp/:id', element: <WhatsappEdit /> },
            { path: 'inbox/sms/:id', element: <SmsEdit /> },
            { path: 'inbox/telegram/:id', element: <Edittelegram /> },
            { path: 'inbox/viber/:id', element: <Editviber /> },
            { path: 'inbox/messenger/:id', element: <MessengerEdit /> },
            { path: 'inbox/instagram/:id', element: <InstagramEdit /> },
            { path: 'customfields', element: <ContactCustomFields /> },
            { path: 'billing', element: <AccountBilling cards={_userPayment} addressBook={_userAddressBook} invoices={_userInvoices} /> },
            { path: 'team', element: <Teammanagement /> },
            { path: 'role', element: <RoleDataGrid /> },
          ]
        },
        {
          path: 'contacts',
          children: [
            { element: <Navigate to="/dashboard/contacts/manage" replace />, index: true },
            { path: 'manage', element: <ContactsManage /> },
            { path: 'manage/search', element: <ContactsManage /> },
            { path: 'manage/filter', element: <ContactsManage /> },
            { path: 'manage/:number', element: <ContactsManage /> },
            { path: 'manage/new', element: <ContactsNew /> },
            // { path: 'manage/account/:id', element: <ContactsAccount /> },
            { path: 'manage/account/:id/general', element: <ContactsAccount /> },
            { path: 'manage/account/:id/notes', element: <ContactsAccount /> },
            { path: 'manage/account/:id/conversations', element: <ContactsAccount /> },
            { path: 'manage/account/:id/tickets', element: <ContactsAccount /> },
            { path: 'manage/account', element: <ContactsAccount /> },
            { path: 'group', element: <ContactsGroup /> },
            { path: 'customfields', element: <ContactCustomFields /> },
            { path: 'import', element: <ContactImport /> }, ,

          ],
        },
        {
          path: 'onboarding',
          children: [
            { element: <Navigate to='/dashboard/onboarding/welcome' replace />, index: true },
            { path: 'welcome', element: <Welcome /> },
            { path: 'channel', element: <ChooseChannel /> },

          ]

        },
        {
          path: 'voicemail',
          children: [
            { element: <Navigate to="/dashboard/voicemail/voicemailbox" replace />, index: true },
            { path: 'voicemailbox', element: <VoiceMailBox /> },

          ],
        },

        {
          path: 'inbox',
          children: [
            { element: <Navigate to="/dashboard/inbox/voice" replace />, index: true },
            // { path: 'voice', element: <InboxVoice /> },
            { path: 'voice/search/:number', element: <InboxVoice /> },
            { path: 'voice/:name/:number', element: <InboxVoice /> },
            { path: 'voice/filter', element: <InboxVoice /> },
            { path: 'voice/search', element: <InboxVoice /> },
            { path: 'voice/export', element: <InboxVoiceExport /> },
            { path: 'voice/notes', element: <InboxVoice /> },
            { path: 'whatsapp/:name/:phoneNumberId', element: <InboxWhatsAppCompose /> },
            { path: 'sms/:name', element: <InboxSms /> },
            { path: "telegram/:name/:token", element: <InboxTelegram /> },
            { path: "viber/:name/:token", element: <InboxViber /> },
            { path: "messenger/:name", element: <InboxMessenger /> },
            { path: 'messenger/:conversationKey', element: <InboxMessenger /> },
            { path: 'insta/:name', element: <InboxInstagram /> },
            { path: 'insta/:conversationKey', element: <InboxInstagram /> },
            {path:  'whatsapp',  children: [
              { element: <Navigate to="/dashboard/inbox/whatsapp/chats" replace />, index: true },
              { path:  'chats', element: <InboxWhatsapp /> },
              { path:  'settings', children: [
                { element: <Navigate to="/dashboard/inbox/whatsapp/settings/customers" replace />, index: true },
                { path:  'customers', element: <InboxWhatsapp /> },
                { path:  'labels', element: <InboxWhatsapp /> },
                { path:  'team', element: <InboxWhatsapp /> },
              ]},
              
            ]}
              
            

          ],
        },

        {
          path: 'apps',
          children: [
            { element: <Navigate to="/dashboard/apps/ivrstudio" replace />, index: true },
            { path: 'ivrstudio', element: <AppIvrStudio /> },
            { path: 'ivrstudio/flow/:id', element: <AppIvrStudioFlow /> },

          ],
        },

        {
          path: 'support',
          children: [
            { element: <Navigate to="/dashboard/support/tickets" replace />, index: true },
            { path: 'tickets', element: <SupportTickets /> },
            { path: 'livesupport', element: <SupportLiveSupport /> },
            { path: 'helpdocs', element: <SupportHelpDocs /> },
            { path: 'feedback', element: <SupportFeedback /> },

          ],
        },



        
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
           
          ],
        },


        { path: 'permission-denied', element: <PermissionDenied /> },

        {
          path: 'tickets',
          children: [
            
            { element: <Navigate to="/dashboard/tickets/matrix" replace />, index: true },
            { path: 'matrix', element: <Tickets /> },
            { path: 'export', element: <TicketsExport /> },
            { path: 'create', element: <CreateTicket /> },
            { path: 'create/contactselected', element: <CreateTicket /> },
            { path: 'filter', element: <Tickets /> },

          ],
        },

      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Login />, index: true },
      ],
    },
    {
      path: "/",
      children: [
        { element: <Navigate to='/UserConfirmation' />, index: true },
        { path: "/UserConfirmation/:token", element: <UserConfirmation /> },
        { path: "/invitedSignup", element: <InvitedSignup /> }

      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}



