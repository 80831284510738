import React, {useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import { 
    Box,
    Typography,
    Tooltip,
    IconButton,
    Grid,
    Card,
    
} from '@mui/material';
import ReactAudioPlayer from 'react-audio-player';
import axiosInstance from 'src/utils/axios';



import Tags from './Tags'

export default function CallSummary(props){

   
    const authID = localStorage.getItem("authID");
    const { enqueueSnackbar } = useSnackbar();
    const [tags, setTags] = useState([])
    const [callsid, setCallSid] = useState('')

    useEffect(() => {

      if(props.props.Tags !== null && props.props.Tags !== undefined){
        setTags(props.props.Tags)
        console.log('call summary in', props.props.Tags)
      }

      if(props.props.ParentCall !== null && props.props.ParentCall !== undefined){
        setCallSid(props.props.ParentCall.ParentCallSid)
      }

    }, [props])

    const handleSaveTag = () => {
  
        axiosInstance.patch(`ivrstudios/addTags/${localStorage.getItem('editTagID')}`, JSON.parse(localStorage.getItem('saveTagUpdatedData')))
            .then((response) => {

                if (response.data.status === 'success') {

                    enqueueSnackbar('Update success!');
                } else {
                    enqueueSnackbar('Update Failed!');
                }
            })
            .catch(err => console.log("These are ", err))
    }

    

    function getRecording(params) {
        // console.log('audio record render', params)
         // let childCallCheck = Object.keys(params.ParentCall?.ChildCall).length
         var CallSidForRecording
        
             // console.log('audio record render', params.ParentCall?.ChildCall[0].CallSid)
             // CallSidForRecording = params.ParentCall?.ChildCall[0].CallSid;
             if(params.listOfChildCalls !== undefined){

             
             if (params.listOfChildCalls.length > 0) {
                 for (let i = 0; i < params.listOfChildCalls.length; i++) {
                     if(params.listOfChildCalls[i].CallSid !== undefined && params.listOfChildCalls[i].CallSid !== null){
                         if (params.listOfChildCalls[i] === null || params.listOfChildCalls[i] === undefined) return CallSidForRecording = ''
                         if (params.listOfChildCalls[i].Status["completed"]) {
                             let ExactCallSidForRecording = params.listOfChildCalls[i].RecordingSid ? params.listOfChildCalls[i].RecordingSid : params.listOfChildCalls[i].CallSid
                             CallSidForRecording = ExactCallSidForRecording
                             break;
                         }
                     }
                    
                 }
 
             } else {
                 CallSidForRecording = ''
             }

            }
       
         return (
             <ReactAudioPlayer
             src={`https://launch-api.vibtree.com/api/Recording?AccountSid=${params.AccountSid}&RecordingSid=${CallSidForRecording}&tab=vibtree`}
                 controls
                 style={{ borderRadius: "10px", height: '48px' }}
             />
         )
     }

    // console.log('Call Summary', props.props)
    // const CallSidForRecording = props.props.ParentCall?.ChildCall[0].CallSid;
    const CloudNumber = props.props.CloudNumber
    const recieverNumber = props.props.ParentCall !== undefined ?( props.props.ParentCall.ChildCall[0] !== null ? props.props.ParentCall.ChildCall[0].To : '') : ''
    const cloudNumberName = props.props.CloudNumberName
    const recieverName = props.props.ReceiverName


    return(
        <>

{getRecording(props.props)}

            <Grid container spacing={2}>
                <Grid item xs>
                    <Card style={{padding: '20px', border: '1px solid #eee', marginBottom: '10px'}}>
                        <Grid container direction="row">
                            <Grid item zs={12}>
                                <Typography variant='caption'>
                                    Cloud Number
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="primary" variant='subtitle1'>
                                   {cloudNumberName}
                                </Typography>
                                <Typography variant='subtitle1'>
                                    {CloudNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card style={{padding: '20px', border: '1px solid #eee', marginBottom: '10px'}}>
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                <Typography variant='caption'>
                                    Recieved by Number
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="primary" variant='subtitle1'>
                                    {recieverName}
                                </Typography>
                                <Typography variant='subtitle1'>
                                    {recieverNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

            <Card style={{padding: '20px', border: '1px solid #eee', marginBottom: '10px'}}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant='caption'>
                            Call Tags
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Tags tags={tags}  />
                        
                    </Grid>
                    
                </Grid>
            </Card>

            <Card style={{padding: '20px', border: '1px solid #eee', marginBottom: '10px'}}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant='caption'>
                            Ring Duration
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='caption' >
                            00:00
                        </Typography>
                    </Grid>
                </Grid>
            </Card>

            <Card style={{padding: '20px', border: '1px solid #eee', marginBottom: '10px'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='caption'>
                            Call Costs
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='caption'>
                            00:00
                        </Typography>
                    </Grid>
                </Grid>
            </Card>


                
        </>
    )

   }