import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from '../../../utils/axios';

//
import { dispatch } from '../../store';

import {showSnackbar} from '../snackbar'

let AccountSidLocal = localStorage.getItem('authID')

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    getRoleResponse: {},
    posttRoleResponse: {},
    putRoleResponse: {},
    getRoleIDResponse: {},
    roleData: {},
}

const userRoleSlice = createSlice({
    name: 'userRole',
    initialState,
    reducers: {

      currentRoleData: (state, { payload }) => {
          state.roleData = payload
      },

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET NUMBER SSUCCESS
        getRolesSuccess(state, action) {
            state.getRoleResponse = action.payload;
            state.isLoading = false;
          },

        postRoleSuccess(state, action){
            state.posttRoleResponse = action.payload;
            state.isLoading = false
        },

        putRoleSuccess(state, action){
            state.putRoleResponse = action.payload;
            state.isLoading = false
        },

        getRoleIDSuccess(state, action){
            state.getRoleIDResponse = action.payload;
            state.isLoading = false
        }
        

      
    }
})

export const selectuserRoles = (state) => state.userRole;

export default userRoleSlice.reducer

// Action creators are generated for each case reducer function
export const { currentRoleData } = userRoleSlice.actions


// Actions

export function getuserRoles(AccountSid) {

    return async () => {
      dispatch(userRoleSlice.actions.startLoading());
      try {
        const response = await axios.get(`/roles?AccountSid=${AccountSid}`);
          dispatch(userRoleSlice.actions.getRolesSuccess(response.data));
          
      } catch (error) {
        dispatch(userRoleSlice.actions.hasError(error));
      }
    };
  }

  export function postUserRole(data) {
    console.log('update drag redux request', data)
    return async () => {
      dispatch(userRoleSlice.actions.startLoading());
      try {
        const response = await axios.post(`/roles`, data);
        dispatch(userRoleSlice.actions.postRoleSuccess(response.data));
        console.log('postUserRole redux', response)
        if (response.data.status === true) {
          dispatch(showSnackbar('Role Created'));
          dispatch(getuserRoles(AccountSidLocal))
        } else {
          dispatch(showSnackbar('Oops.. Trouble to Save'));
        }
  
      } catch (error) {
        dispatch(userRoleSlice.actions.hasError(error));
        dispatch(showSnackbar('Oops.. Trouble to Save'));
      }
    };
  }

  export function putUserRole(id, data) {
    console.log('update drag redux request', data)
    return async () => {
      dispatch(userRoleSlice.actions.startLoading());
      try {
        const response = await axios.put(`/roles/${id}`, data);
        dispatch(userRoleSlice.actions.putRoleSuccess(response.data));
        console.log('putUserRole redux', response)
        if (response.data.status === true) {
          dispatch(showSnackbar('Role Updated'));
          dispatch(getuserRoles(AccountSidLocal))
        } else {
          dispatch(showSnackbar('Oops.. Trouble to Update'));
        }
  
      } catch (error) {
        dispatch(userRoleSlice.actions.hasError(error));
        dispatch(showSnackbar('Oops.. Trouble to Update'));
      }
    };
  }

  export function assignRole(id, data) {
    console.log('assignRole request', data)
    return async () => {
      dispatch(userRoleSlice.actions.startLoading());
      try {
        const response = await axios.put(`/users/${id}`, {"role" : data});
        dispatch(userRoleSlice.actions.putRoleSuccess(response.data));
        console.log('assignRole redux', response)
        if (response.data.status === true) {
          dispatch(showSnackbar('Role Assigned'));
          dispatch(getuserRoles(AccountSidLocal))
        } else {
          dispatch(showSnackbar('Oops.. Trouble to Assigned'));
        }
  
      } catch (error) {
        dispatch(userRoleSlice.actions.hasError(error));
        dispatch(showSnackbar('Oops.. Trouble to Assigned'));
      }
    };
  }




  export function getuserRolesID(id) {
    console.log('role id redux', id)
    return async () => {
      console.log('role id redux in')
      // dispatch(userRoleSlice.actions.startLoading());
      try {
        const response = await axios.get(`/roles/${id}`);
        console.log('role id redux res')
          dispatch(userRoleSlice.actions.getRoleIDSuccess(response.data));
          dispatch(currentRoleData(response.data))
          console.log('role id redux res')
      } catch (error) {
        console.log('role id redux err', error)
        dispatch(userRoleSlice.actions.hasError(error));
      }
    };
  }

  export function deleteUserRole(id) {

    return async () => {
      dispatch(userRoleSlice.actions.startLoading());
      try {
        const response = await axios.delete(`/roles/${id}`);
          dispatch(userRoleSlice.actions.getRoleIDSuccess(response.data));
          dispatch(showSnackbar('Role Deleted'));
          dispatch(getuserRoles(AccountSidLocal));
      } catch (error) {
        dispatch(userRoleSlice.actions.hasError(error));
        dispatch(showSnackbar('Role Deleted Failed'));
      }
    };
    
  }


  