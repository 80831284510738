import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {
  Typography
} from '@mui/material'
import moment from 'moment';
import Iconify from '../../../Iconify';

import TimeZone from '../../../TimeZone'

export default function CallLifeCycle(props) {

  console.log("call life cycle props", props);

  const item = props.props

  const removePlusFiveThirty = (item) => {
    const timeInString = item.toString();
    const actualTimeWithoutGmt = timeInString.replace("+0530", "");
    return actualTimeWithoutGmt;
  }


  // const checkIfInitiateCallHappened = (item) => {
  //   console.log("Checking in child Call Happened", item);
  //   if (item.listOfChildCalls.length === 0) {
  //     // if (item.ParentCall.ChildCall[0].To.slice(-12) === item.CloudNumber.slice(-12)) {
  //     //   return null
  //     // } else {
  //     //   return (
  //     //     <>
  //     //       <TimelineItem>
  //     //         <TimelineSeparator>
  //     //           <TimelineDot color="primary">
  //     //             <Iconify icon={'fluent:call-forward-16-regular'} width={20} height={20} />
  //     //           </TimelineDot>
  //     //           <TimelineConnector />
  //     //         </TimelineSeparator>
  //     //         <TimelineContent>
  //     //           {/* {item.Direction}Receive Call at {item?.PC_intiated} */}
  //     //           <Typography variant="subtitle1" >
  //     //             Call Initated to    {item?.ParentCall.ChildCall[0].To}
  //     //           </Typography>
  //     //           <Typography variant="caption" ><TimeZone date={item?.ParentCall.ChildCall[0].Status?.initiated} />  </Typography>
  //     //         </TimelineContent>
  //     //       </TimelineItem>
  //     //     </>
  //     //   )

  //     // }
  //   } else {
  //     if (item.ParentCall.ChildCall[0].To !== item.CloudNumber) {
  //       return null
  //     } else if(item.listOfChildCalls.length === 0) {
  //       return (


  //         <>
  //           <TimelineItem>
  //             <TimelineSeparator>
  //               <TimelineDot color="primary">
  //                 <Iconify icon={'fluent:call-forward-16-regular'} width={20} height={20} />
  //               </TimelineDot>
  //               <TimelineConnector />
  //             </TimelineSeparator>
  //             <TimelineContent>
  //               {/* {item.Direction}Receive Call at {item?.PC_intiated} */}
  //               <Typography variant="subtitle1" >
  //                 Call Initated to  {item?.ParentCall.ChildCall[0].To}
  //               </Typography>
  //               <Typography variant="caption" ><TimeZone date={item?.ParentCall.ChildCall[0].Status?.initiated} />   </Typography>
  //             </TimelineContent>
  //           </TimelineItem>
  //         </>
  //       )
  //     }



  //   }
  // }



  const checkIfRingingCallHappened = (item) => {
    console.log("Checking in child Call Happened", item);
    if (item.listOfChildCalls.length === 0) {
      if (item.ParentCall.ChildCall[0].To.slice(-12) === item.CloudNumber.slice(-12)) {
        return null
      } else {
        return (


          <>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot style={{ background: "#FFCE42" }}>
                  <Iconify icon={'material-symbols:ring-volume-outline'} width={20} height={20} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              {/* <TimelineContent>Ringing at {item?.ParentCall.CallStatus['ringing']}</TimelineContent> */}
              <TimelineContent>
                {/* Route Started Ringing at {item?.CC_ringing} */}
                <Typography variant="subtitle1" >
                  Starts Ringing
                </Typography>
                <Typography variant="caption" ><TimeZone date={item?.ParentCall.ChildCall[0].Status?.ringing} /> </Typography>
              </TimelineContent>
            </TimelineItem>
          </>
        )
      }
    } else {
      if (item.ParentCall.ChildCall[0].To !== item.CloudNumber) {
        return null
      } else if(item.listOfChildCalls.length === 0) {
        return (


          <>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot style={{ background: "#FFCE42" }}>
                  <Iconify icon={'material-symbols:ring-volume-outline'} width={20} height={20} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              {/* <TimelineContent>Ringing at {item?.ParentCall.CallStatus['ringing']}</TimelineContent> */}
              <TimelineContent>
                {/* Route Started Ringing at {item?.CC_ringing} */}
                <Typography variant="subtitle1" >
                  Starts Ringing
                </Typography>
                <Typography variant="caption" ><TimeZone date={item?.ParentCall.ChildCall[0].Status?.ringing} /> </Typography>
              </TimelineContent>
            </TimelineItem>
          </>
        )
      }



    }
  }

  const checkIfInProgressCallHappened = (item) => {
    console.log("Checking in child Call Happened", item);
    if (item.listOfChildCalls.length === 0) {
      if (item.ParentCall.ChildCall[0].To.slice(-12) === item.CloudNumber.slice(-12)) {
        return null
      } else
      {
        if (item?.ParentCall.ChildCall[0].Status['in-progress']) {
          return (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot style={{ background: "#0ada8ae7" }}>
                    <Iconify icon={'ph:phone-call'} width={20} height={20} />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {/* {item.Direction}Receive Call at {item?.PC_intiated} */}
                  <Typography variant="subtitle1" >
                    Call Connected at
                  </Typography>
                  <Typography variant="caption" ><TimeZone date={item?.ParentCall.ChildCall[0].Status['in-progress']} /> </Typography>
                </TimelineContent>
              </TimelineItem>
            </>
          )
        } else {
          return null
        }
      }
    } else {
      if (item.ParentCall.ChildCall[0].To !== item.CloudNumber) {
        return null
      } else if(item.listOfChildCalls.length === 0) {
        return (
          <>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot style={{ background: "#0ada8ae7" }}>
                  <Iconify icon={'ph:phone-call'} width={20} height={20} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                {/* {item.Direction}Receive Call at {item?.PC_intiated} */}
                <Typography variant="subtitle1" >
                  Call Connected at
                </Typography>
                <Typography variant="caption" ><TimeZone date={item?.ParentCall.ChildCall[0].Status['in-progress']} /> </Typography>
              </TimelineContent>
            </TimelineItem>
          </>
        )

      }


    }
  }



  const checkIfCompletedCallHappened = (item) => {
    console.log("Checking in child Call Happened", item);
    if (item.listOfChildCalls.length === 0) {
      if (item.ParentCall.ChildCall[0].To.slice(-12) === item.CloudNumber.slice(-12)) {
        return null
      } else {
        if (item?.ParentCall.ChildCall[0].Status['completed']) {
          return (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot style={{ background: "red" }}>
                    <Iconify icon={'ph:phone-disconnect-light'} width={20} height={20} />
                  </TimelineDot>
                  {/* <TimelineConnector /> */}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="subtitle1" >
                    Completed at
                  </Typography>
                  <Typography variant="caption" ><TimeZone date={item.ParentCall.ChildCall[0].Status.completed} /> </Typography>
                </TimelineContent>

              </TimelineItem>
            </>
          )
        } else if (item?.ParentCall.ChildCall[0].Status['failed']) {
          return (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot style={{ background: "#8E0000" }}>
                    <Iconify icon={'eva:phone-off-outline'} width={20} height={20} />
                  </TimelineDot>
                  {/* <TimelineConnector /> */}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="subtitle1" >
                    Not Connected at
                  </Typography>
                  <Typography variant="caption" ><TimeZone date={item.ParentCall.ChildCall[0].Status.failed} /> </Typography>
                </TimelineContent>

              </TimelineItem>
            </>
          )
        }
      }
    } else {
      if (item.ParentCall.ChildCall[0].To !== item.CloudNumber) {
        return null
      } else if(item.listOfChildCalls.length === 0) {
        return (
          <>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot style={{ background: "#0ada8ae7" }}>
                  <Iconify icon={'ph:phone-call'} width={20} height={20} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                {/* {item.Direction}Receive Call at {item?.PC_intiated} */}
                <Typography variant="subtitle1" >
                  Call Connected at
                </Typography>
                <Typography variant="caption" ><TimeZone date={item?.ParentCall.ChildCall[0].Status['completed']} /> </Typography>
              </TimelineContent>
            </TimelineItem>
          </>
        )

      }


    }
  }

  const showIvrDetails = (item) => {
   console.log("item in ivr details : ", item, item[0].Digit)
    if (item[0].Digit !== undefined && Object.keys(item[0]).length !== 0 ) {

      return(
        <>
      {item.map((ivr, index) => {
        console.log("item in loop hello", ivr)
        return (
          <>


          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <Iconify icon={'bi:play'} width={20} height={20} />

              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>

              <Typography variant="subtitle1" >
                Played IVR at
              </Typography>
              <Typography variant="caption" >{ivr.pressedTime ? <TimeZone date={removePlusFiveThirty(moment(ivr.pressedTime).subtract(5, "hours").subtract(30, "minutes").toLocaleString())} /> : ''}</Typography>
            </TimelineContent>

          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <Iconify icon={'fluent:dialpad-32-regular'} width={20} height={20} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>

              <Typography variant="subtitle1" >
                Caller Pressed - {ivr.Digit ? ivr.Digit : ''}
              </Typography>

              <Typography variant="caption" >{ivr.pressedTime ? <TimeZone date={removePlusFiveThirty(moment(ivr.pressedTime).subtract(5, "hours").subtract(30, "minutes").toLocaleString())} /> : ''}</Typography>
              <Typography variant="caption" ></Typography>
            </TimelineContent>

          </TimelineItem>

        </>
        )
      })}
      </>
      )
    }
  }

  const showListOfChildCalls = (item) =>{
   // console.log("Item in list of child calls : ", item )
    if(item.listOfChildCalls.length > 0){
      if(item.listOfChildCalls[0].Status){
        return (
          <>
    
    
            {item.listOfChildCalls?.map((ivr, index) => {
              return (
                <>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot color="primary">
                        <Iconify icon={'fluent:call-forward-16-regular'} width={20} height={20} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
    
                      <Typography variant="subtitle1" >
                        Call Initated to  {ivr?.To}
                      </Typography>
                      <Typography variant="caption" ><TimeZone date={ivr?.Status?.initiated} /></Typography>
                    </TimelineContent>
                  </TimelineItem>
    
                  {
                    ivr['Status']['ringing'] ? (
                      <>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot style={{ background: "#FFCE42" }}>
    
                              <Iconify icon={'material-symbols:ring-volume-outline'} width={20} height={20} />
    
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
    
                          <TimelineContent>
    
                            <Typography variant="subtitle1" >
                              Starts Ringing
                            </Typography>
                            <Typography variant="caption" ><TimeZone date={ivr['Status']['ringing']} /> </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      </>
                    )
                      : ''
                  }
    
    
    
    
                  {
                    ivr.Status['in-progress'] ?
                      <>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot style={{ background: "#0ada8ae7" }}>
    
                              <Iconify icon={'ph:phone-call'} width={20} height={20} />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant="subtitle1" >
                              Call Connected at
                            </Typography>
                            <Typography variant="caption" ><TimeZone date={ivr['Status']["in-progress"]} /></Typography>
                          </TimelineContent>
    
                        </TimelineItem>
                      </> : null
                  }
    
                  {
                    ivr.Status["completed"] ?
                      <>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot style={{ background: "red" }}>
    
                              <Iconify icon={'ph:phone-disconnect-light'} width={20} height={20} />
                            </TimelineDot>
    
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant="subtitle1" >
                              Completed at
                            </Typography>
                            <Typography variant="caption" > <TimeZone date={ivr['Status']["completed"]} /> </Typography>
                          </TimelineContent>
    
                        </TimelineItem>
                      </> :
                      <>
    
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot style={{ background: "#8E0000" }}>
    
                              <Iconify icon={'eva:phone-off-outline'} width={20} height={20} />
                            </TimelineDot>
                            {index === item.listOfChildCalls.length - 1 ? '' : <TimelineConnector />}
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant="subtitle1" >
                              Not Connected -  {ivr['Status']["no-answer"] ? 'No Answer' : ''}
                              {ivr['Status']["busy"] ? 'Busy' : ''} 
                              {ivr['Status']['failed'] ? 'Failed' : ''}
                            </Typography>
                            
                            <Typography variant="caption" >
                              {ivr['Status']["no-answer"] ? <TimeZone date={ivr['Status']["no-answer"]} /> : ''}
                              {ivr['Status']["busy"] ? <TimeZone date={ivr['Status']["busy"]} /> : ''} 
                              {ivr['Status']['failed'] ? <TimeZone date={ivr['Status']["failed"]} /> : ''}
    
                              
                              
                               </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      </>
                  }
    
                </>
              )
            })}
    </>
        )
      }
    }

  }


  return (
    <>
      <Timeline className="callmatrics-timeline" >

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <Iconify icon={'fluent:call-forward-16-regular'} width={20} height={20} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>

            <Typography variant="subtitle1" >
              Call Recieved
            </Typography>
            <Typography variant="caption" >{item?.ParentCall.CallStatus['initiated'] !== undefined ? <TimeZone date={item.ParentCall.CallStatus['initiated']} /> : ''}</Typography>
          </TimelineContent>

        </TimelineItem>


        
        {showIvrDetails(item?.ivrDetails)}

        {/* {item?.ivrDetails[0].Digit !== undefined ? (
        <>
          {item?.ivrDetails?.map((ivr, index) => {
            return (
              <>

                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="primary">
                      <Iconify icon={'bi:play'} width={20} height={20} />

                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>

                    <Typography variant="subtitle1" >
                      Played IVR at
                    </Typography>
                    <Typography variant="caption" >{ivr.pressedTime ? <TimeZone date={removePlusFiveThirty(moment(ivr.pressedTime).subtract(5, "hours").subtract(30, "minutes").toLocaleString())} /> : ''}</Typography>
                  </TimelineContent>

                </TimelineItem>

                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="primary">
                      <Iconify icon={'fluent:dialpad-32-regular'} width={20} height={20} />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>

                    <Typography variant="subtitle1" >
                      Caller Pressed - {ivr.Digit ? ivr.Digit : ''}
                    </Typography>

                    <Typography variant="caption" >{ivr.pressedTime ? <TimeZone date={removePlusFiveThirty(moment(ivr.pressedTime).subtract(5, "hours").subtract(30, "minutes").toLocaleString())} /> : ''}</Typography>
                    <Typography variant="caption" ></Typography>
                  </TimelineContent>

                </TimelineItem>

              </>
            )
          })}
        </>
        
        ) : ""} */}



{/* {Object.keys(item.listOfChildCalls[0]).includes('Status') ? (


<>


        {item.listOfChildCalls?.map((ivr, index) => {
          return (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    <Iconify icon={'fluent:call-forward-16-regular'} width={20} height={20} />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>

                  <Typography variant="subtitle1" >
                    Call Initated to  {ivr?.To}
                  </Typography>
                  <Typography variant="caption" ><TimeZone date={ivr?.Status?.initiated} /></Typography>
                </TimelineContent>
              </TimelineItem>

              {
                ivr['Status']['ringing'] ? (
                  <>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot style={{ background: "#FFCE42" }}>

                          <Iconify icon={'material-symbols:ring-volume-outline'} width={20} height={20} />

                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>

                      <TimelineContent>

                        <Typography variant="subtitle1" >
                          Starts Ringing
                        </Typography>
                        <Typography variant="caption" ><TimeZone date={ivr['Status']['ringing']} /> </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  </>
                )
                  : ''
              }




              {
                ivr.Status['in-progress'] ?
                  <>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot style={{ background: "#0ada8ae7" }}>

                          <Iconify icon={'ph:phone-call'} width={20} height={20} />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant="subtitle1" >
                          Call Connected at
                        </Typography>
                        <Typography variant="caption" ><TimeZone date={ivr['Status']["in-progress"]} /></Typography>
                      </TimelineContent>

                    </TimelineItem>
                  </> : null
              }

              {
                ivr.Status["completed"] ?
                  <>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot style={{ background: "red" }}>

                          <Iconify icon={'ph:phone-disconnect-light'} width={20} height={20} />
                        </TimelineDot>

                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant="subtitle1" >
                          Completed at
                        </Typography>
                        <Typography variant="caption" > <TimeZone date={ivr['Status']["completed"]} /> </Typography>
                      </TimelineContent>

                    </TimelineItem>
                  </> :
                  <>

                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot style={{ background: "#8E0000" }}>

                          <Iconify icon={'eva:phone-off-outline'} width={20} height={20} />
                        </TimelineDot>
                        {index === item.listOfChildCalls.length - 1 ? '' : <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant="subtitle1" >
                          Not Connected -  {ivr['Status']["no-answer"] ? 'No Answer' : ''}
                          {ivr['Status']["busy"] ? 'Busy' : ''} 
                          {ivr['Status']['failed'] ? 'Failed' : ''}
                        </Typography>
                        
                        <Typography variant="caption" >
                          {ivr['Status']["no-answer"] ? <TimeZone date={ivr['Status']["no-answer"]} /> : ''}
                          {ivr['Status']["busy"] ? <TimeZone date={ivr['Status']["busy"]} /> : ''} 
                          {ivr['Status']['failed'] ? <TimeZone date={ivr['Status']["failed"]} /> : ''}

                          
                          
                           </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  </>
              }

            </>
          )
        })}
</>
        ) : ''} */}

{showListOfChildCalls(item)}

{/* 
        {checkIfInitiateCallHappened(item)} */}

{/* 
        {checkIfRingingCallHappened(item)} */}
{/* 
        {checkIfInProgressCallHappened(item)}
        {checkIfCompletedCallHappened(item)} */}
        {item.listOfChildCalls.length === 0 ? (
          <>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot style={{ background: "#8E0000" }}>

                  <Iconify icon={'eva:phone-off-outline'} width={20} height={20} />
                </TimelineDot>
                {/* { index === item.listOfChildCalls.length -1 ? '' : <TimelineConnector />} */}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="subtitle1" >
                  Hangup
                </Typography>
                <Typography variant="caption" >{item['ParentCall']["CallStatus"] ? (
                  <>
                    {item['ParentCall']["CallStatus"]["completed"] ?  <TimeZone date={item['ParentCall']["CallStatus"]["completed"]} />  : ''}
                    {item['ParentCall']["CallStatus"]["failed"] ?  <TimeZone date={item['ParentCall']["CallStatus"]["failed"]} />  : ''}
                    {item['ParentCall']["CallStatus"]["busy"] ?  <TimeZone date={item['ParentCall']["CallStatus"]["busy"]} />  : ''}
                    {item['ParentCall']["CallStatus"]["canceled"] ?  <TimeZone date={item['ParentCall']["CallStatus"]["canceled"]} />  : ''}
                    {item['ParentCall']["CallStatus"]["no-answer"] ?  <TimeZone date={item['ParentCall']["CallStatus"]["no-answer"]} />  : ''}
                    
                   
                  </>
                ) : '' }
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </>
        ) : ""}


      </Timeline>
    </>
  )
}