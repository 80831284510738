
import { createSlice } from "@reduxjs/toolkit";
import { useSnackbar } from 'notistack';
// utils
import axios, { baseUrl } from '../../../utils/axios';

//
import { dispatch } from '../../store';
import { getAuth } from '../getAuth';

import { showSnackbar } from '../snackbar'




const initialState = {
    isLoading: false,
    isLoadingFilter: false,
    isLoadingExport: false,
    total_tickets: 0,
    isLoadingNotes: false,
    isStartLoadingStatus: false,
    error: null,
    inboxVoiceData: {},
    inboxTicketData: [],
    inboxVoiceSearchData: {},
    inboxVoiceFilterData: {},
    contactData: {},
    addNotesResponse: {},
    updateNotesResponse: {},
    deleteNotes: {},
    updateStatusResponse: {},
    exportDataResponse: {},
    updateInboxColumn: {},
    updateInboxDragColumn: {},
    isRefresh: false,
    userOfCompany: {}
}

export const inboxCommentsSlice = createSlice({
    name: 'inboxComments',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        // START LOADING
        startLoadingFilter(state) {
            state.isLoadingFilter = true;
        },

        // START LOADING
        startLoadingExport(state) {
            state.isLoadingExport = true;
        },

        // START LOADING
        startLoadingNotes(state) {
            state.isLoadingNotes = true;
        },

        // START LOADING
        startLoadingStatus(state) {
            state.isStartLoadingStatus = true;
        },

        // START LOADING
        stopLoadingStatus(state) {
            state.isStartLoadingStatus = false;
        },

        // STOP LOADING
        stopLoadingFilter(state) {
            state.isLoadingFilter = false;
        },

        // STOP LOADING
        stopLoadingExport(state) {
            state.isLoadingExport = false;
        },

        setTotalTicket(state, action) {
            state.total_tickets = action.payload;
        },

        // STOP LOADING
        stopLoadingNotes(state) {
            state.isLoadingNotes = false;
        },



        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;

        },

        // GET IVR VOICE
        getCommentsSuccess(state, action) {
            state.inboxVoiceData = action.payload;
            state.isLoading = false;
        },

        getTicketsSuccess(state, action) {
            state.inboxTicketData = action.payload;
            state.isLoading = false;
        },

        getCompanyUserSuccess(state, action) {
            state.userOfCompany = action.payload;
            state.isLoading = false;
        },

    }
})



export const selectAllInboxComments = (state) => state.inboxComments;

export default inboxCommentsSlice.reducer


// Actions


export function editAssignedUserOfTicket(list, id) {
    const AccountSid = localStorage.getItem('authID')
    return async () => {
        dispatch(inboxCommentsSlice.actions.startLoading());
        try {
            console.log("New List in redux : ", list)
            const dataToSend = {
                user_id: list
            }
            const response = await axios.patch(`/ticket-list/${id}`, dataToSend)
            // dispatch(inboxCommentsSlice.actions.getCompanyUserSuccess(response.data));
            console.log('getAllUsersOfCompany Fetched : ', response)
            const rowsPerPage = 10
            const offsetCount = 0
            let link = `/ticket-list?AccountSid=${AccountSid}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id&populate=created_by&populate=user_id`
            const responseOfGetTicket = await axios.get(link);
            dispatch(inboxCommentsSlice.actions.getTicketsSuccess(responseOfGetTicket.data));
            dispatch(showSnackbar('User Assigned'));
        } catch (error) {

            console.log('getAllUsersOfCompany trigger error', error)
            dispatch(inboxCommentsSlice.actions.hasError(error));

        }
    }
}

export function getAllUsersOfCompany() {
    return async () => {
        dispatch(inboxCommentsSlice.actions.startLoading());
        try {

            const response = await axios.get('/users_of_company')
            dispatch(inboxCommentsSlice.actions.getCompanyUserSuccess(response.data));
            console.log('getAllUsersOfCompany Fetched : ', response)


        } catch (error) {

            console.log('getAllUsersOfCompany trigger error', error)
            dispatch(inboxCommentsSlice.actions.hasError(error));

        }
    }
}

export function getAllPost(offsetCount, rowsPerPage) {
    console.log('getIvrVoice trigger', offsetCount)
    return async () => {
        console.log('getIvrVoice trigger in', offsetCount)
        dispatch(inboxCommentsSlice.actions.startLoading());
        try {
            let link = `/meta/getFormattedPost?limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`
            const response = await axios.get(link);
            dispatch(inboxCommentsSlice.actions.getCommentsSuccess(response.data));
            console.log('Comments Fetched : ', response)
            dispatch(inboxCommentsSlice.actions.isRefreshDataCancel());

        } catch (error) {
            dispatch(inboxCommentsSlice.actions.hasError(error));
            console.log('Comments trigger error', error)
        }
    };
}

export function getAllTickets(offsetCount, rowsPerPage, authId) {
    console.log('getAllTickets trigger', offsetCount)
    
    return async () => {
        console.log('getAllTickets trigger in', offsetCount)
        dispatch(inboxCommentsSlice.actions.startLoading());
        try {
            let link = `/ticket-list?AccountSid=${authId}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id&populate=created_by&populate=user_id`
            const response = await axios.get(link);
            dispatch(inboxCommentsSlice.actions.getTicketsSuccess(response.data.data.data));
            console.log('Comments Fetched : ', response)
            dispatch(inboxCommentsSlice.actions.setTotalTicket(response.data.data.totalCount));
            dispatch(inboxCommentsSlice.actions.stopLoading())
            //dispatch(inboxCommentsSlice.actions.isRefreshDataCancel());

        } catch (error) {
            dispatch(inboxCommentsSlice.actions.hasError(error));
            console.log('Comments trigger error', error)
        }
    };
}

export function updateTicketInWhatsapp(id, data) {

    return async () => {
        try {
            const dataToSend = { "ticketId": data }
            const response = await axios.put(`/whatsapp/chat/conversations/${id}`, dataToSend);
            console.log('update status redux', response)
        } catch (error) {
            dispatch(showSnackbar('Oops.. Trouble while updating ticket in whatsapp conversation'));
        }
    };
}

export function updateTicketInTelegram(id, data) {

    return async () => {
        try {
            const dataToSend = { "ticketId": data }
            const response = await axios.put(`/telegram/chat/conversations/${id}`, dataToSend);
            console.log('update status redux', response)
        } catch (error) {
            dispatch(showSnackbar('Oops.. Trouble while updating ticket in telegram conversation'));
        }
    };
}

export function updateTicketInViber(id, data) {

    return async () => {
        try {
            const dataToSend = { "ticketId": data }
            const response = await axios.put(`/viber/chat/conversations/${id}`, dataToSend);
            console.log('update status redux', response)
        } catch (error) {
            dispatch(showSnackbar('Oops.. Trouble while updating ticket in viber conversation'));
        }
    };
}

export function createTicket(data) {
    const AccountSid = localStorage.getItem('authID')
    const userID = localStorage.getItem('userID')
    return async () => {
        try {
            const dataToSend = {
                "AccountSid": AccountSid,
                "user_id": [],
                "ticket_id": data.ticketId,
                "ticket_details": data.ticketDetails,
                "status": {
                    "name": "open",
                    "color": "green"
                },
                "created_by": userID
            }
            const response = await axios.post(`/ticket-list`, dataToSend);
            console.log('update status redux', response)
            const rowsPerPage = 10
            const offsetCount = 0
            let link = `/ticket-list?AccountSid=${AccountSid}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id&populate=created_by&populate=user_id`
            const responseOfGetTicket = await axios.get(link);
            dispatch(inboxCommentsSlice.actions.getTicketsSuccess(responseOfGetTicket.data));
            dispatch(showSnackbar('Ticket Created'));

        } catch (error) {
            console.log("Error after adding ticket : ", error)
            dispatch(showSnackbar('Oops.. Trouble while creating ticket'));
        }
    };
}

export function updateConversationTicket(id, data) {

    return async () => {
        dispatch(inboxCommentsSlice.actions.startLoading());
        try {
            const response = await axios.post(`/ticket-list/addConversation?id=${id}`, data);
            console.log("Response for conversation tickets : ", response)
        } catch (error) {
            dispatch(inboxCommentsSlice.actions.hasError(error));
            console.log('Comments trigger error', error)
        }
    };
}

export function edit_ticket(body, id) {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await axios.patch(`/ticket-list/${id}`, body);
            resolve(response);
        } catch (err) {
            reject(err);
        }
    })
}

export function getAllInstaPost(offsetCount, rowsPerPage) {
    console.log('getAllInstaPost trigger', offsetCount)
    return async () => {
        console.log('getAllInstaPost trigger in', offsetCount)
        dispatch(inboxCommentsSlice.actions.startLoading());
        try {
            let link = `/insta/getFormattedPost?limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`
            const response = await axios.get(link);
            dispatch(inboxCommentsSlice.actions.getCommentsSuccess(response.data));
            console.log('getAllInstaPost Fetched : ', response)
            dispatch(inboxCommentsSlice.actions.isRefreshDataCancel());

        } catch (error) {
            dispatch(inboxCommentsSlice.actions.hasError(error));
            console.log('getAllInstaPost trigger error', error)
        }
    };
}