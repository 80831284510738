import { Button,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  Box,
  Card,
  Alert,

} from '@mui/material'
import React, {useEffect} from 'react'
import { REACT_APP_BASE_URL } from 'src/utils/axios';
import Label from 'src/components/Label'

function PowerDialerComponent({ dialer,  loading_numbers, load_more_data, load_more_power_dialer, power_dialer_total_count }) {
  console.log("dialer", dialer)
  
  return (
    <Box>
     
       
          {loading_numbers === true ? (
            <Card style={{padding: '20px', textAlign: 'center', minWidth: '300px'}}>
              <>
              Fetching Data..
              <LinearProgress />
              </>
          </Card>
          ): (
            <>
              {dialer.length === 0 ? (
                <>
                 {load_more_data === false && (
    
                 <Alert severity="warning" style={{width: '100%'}}>
                  No power dialer numbers are found
                </Alert>
                 )}
                </>
              ): (
                <>

                <List style={{width: '100%'}}>
                {dialer.map((item, index) => { 
                  return(
                      <ListItem divider>
                        <ListItemAvatar>
                          
                          <Avatar
                            alt={item.contactId !== null ? item.contactId.firstName : 'Unknown'}
                            src={item.contactId !== null ? `${REACT_APP_BASE_URL}/api/contact/download?fileLocation=${item.contactId.photo}` : ''}
                            sx={{ width: 40, height: 40 }}
                          />
                    
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography
                          variant="body2"
                          color="text.primary">
                              {item.contactId !== null && item.contactId !== undefined && item.contactId !== "" ? (
                                <>
                                {item.contactId.firstName} {item.contactId.lastName}
                                </>
                              ) : 'Unknown'}
                          </Typography>
                          <Typography
                          variant="caption"
                          color="text.primary">
                              {item.contactId !== null && item.contactId !== undefined && item.contactId !== "" ? (
                                <>
                                {item.contactId.phoneNumber}
                                </>
                              ): (
                                <>
                                {item.contactNumber}
                                </>
                              )}
                          </Typography>
                        </ListItemText>
                        <ListItemSecondaryAction>

                            {item.callStatus === 'New' && (
                              <Label color='primary'>
                                {item.callStatus}
                              </Label>
                            )}

                            {item.callStatus === 'completed' && (
                              <Label color='success'>
                                {item.callStatus}
                              </Label>
                            )}

                            {item.callStatus === 'no-answer' && (
                              <Label color='error'>
                                {item.callStatus}
                              </Label>
                            )}

                            



                            
                        </ListItemSecondaryAction>
                    </ListItem>
                  )
                  })}
                </List>

          {power_dialer_total_count > dialer.length && <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column"}}>
                      {load_more_data === true ? (
                        <Card style={{padding: '20px', textAlign: 'center', minWidth: '300px'}}>
                          <>
                          Fetching Data..
                          <LinearProgress />
                          </>
                      </Card>
                      ): (
                        <Button onClick={() => load_more_power_dialer()}>
                          <div>
                            Load More
                          </div>
                        </Button>
                      )}
                    </div>}
                </>
              )}
            </>
          )}
      
    </Box>
  )
}

export default PowerDialerComponent