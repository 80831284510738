import React, { useState, useEffect } from "react";

import "./style.css";

// mui

import {

  Grid,
  Chip,

} from "@mui/material";


export default function Tags({ tags }) {


  const [currentTags, setCurrentTags] = useState([])

  useEffect(()=>{
    setCurrentTags(tags)
  },[tags])



  return (
    <>
{currentTags.length === 0 ? 'No tags saved' : (
<Grid container spacing={1}>
{currentTags.map((item, index)=>{
      return(
        <Grid item>
          <Chip
                label={item.name}
                style={{
                  background: `${item.backgroundColor}`,
                  borderRadius: "8px",
                }}
                              
                            />
        </Grid>
      )
    })}
</Grid>
   )} 


     </>
  );
}
