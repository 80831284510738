import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SET_COMPANY } from "src/redux/userReducer";
import { REACT_APP_BASE_URL } from "src/utils/axios";
import { company } from "src/_mock/company";

export default function TeamComponent() {
    const reduxToken = localStorage.getItem('redux-token')
    const {user, company} = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [team, setTeam] = useState([])
    const [loading, setLoading] = useState(true)
    const get_team = () => {
        if (user.user_type === "company" && user.is_admin === true && company.length === 0) {
            axios({
                method: "GET",
                url: `${REACT_APP_BASE_URL}/api/users_of_company`,
                headers: {
                    authorization: localStorage.getItem('redux-token')
                }
            }).then((res) => {
                const response = res.data;
                //console.log("response_data", response)
                dispatch({
                    type: SET_COMPANY,
                    payload: response.data
                })
                setLoading(false)
            }).catch((err) => {
                //console.log("error_response", err.response.data)
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        get_team();
    }, [])
    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", backgroundColor: "#fff" }} className="center-col">
            {loading === true ? (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20}}>
                <CircularProgress color='primary' />
            </div>
            ): (
            <div style={{ marginTop: 10, width: "100%" }}>
                {company.map((item, index) => (
                    <div style={{ width: "100%", height: 60, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} key={index}>
                        <div style={{ display: "flex", flexDirection: "row", height: "100%", alignItems: "center"}}>
                            {item.user_logo !== null && item.user_logo !== undefined && item.user_logo !== "undefined" ? (
                                <img src={`${REACT_APP_BASE_URL}/api/download/user_logo?fileLocation=${item.user_logo}`} style={{ width: 40, height: 40, borderRadius: 30, marginLeft: 10, }} />
                            ): (
                                <div style={{width: 40, height: 40, borderRadius: 30, marginLeft: 10, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold"}}>
                                        {item.FirstName.substr(0, 1)}
                                    </div>
                                </div>
                            )}
                            <div style={{ marginLeft: 15, }}>
                                <div style={{ fontSize: 15, }}>
                                    {item.FirstName} {item.LastName}
                                </div>
                                <div style={{ fontSize: 12, }}>
                                    +{item.phone}
                                </div>
                            </div>
                        </div>
                        <div style={{ marginRight: 10, fontSize: 14, }}>
                            Ext.
                        </div>
                    </div>
                ))}
            </div>
            )}
        </div>
    )
}