// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
import BoltIcon from '@mui/icons-material/Bolt';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------

const viewType = localStorage.getItem("viewType")

let is_admin = localStorage.getItem('is_admin')

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  number: getIcon('ic_number'),
  contact: getIcon('ic_contact'),
  voicemail: getIcon('ic_voicemail'),
  voice: getIcon('ic_voice'),
  whatsapp: getIcon('ic_whatsapp'),
  sms: getIcon('ic_sms'),
  ivrstudio: getIcon('ic_ivrstudio'),
  tickets: getIcon('ic_tickets'),
  livesupport: getIcon('ic_livesupport'),
  helpdocs: getIcon('ic_helpdocs'),
  feedback: getIcon('ic_feedback'),
  setting: getIcon('ic_setting'),
  telegram: getIcon('ic_telegram'),
  tickets: getIcon('ic_tickets'),
  viber: getIcon('ic_viber'),
  messenger: getIcon('ic_messenger'),
  insta: getIcon('ic_insta')
};



const navConfig = [


  {
    // subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },

      // contacts
      {
        title: 'contacts',
        path: PATH_DASHBOARD.contacts.root,
        icon: ICONS.contact,
        children: [
          { title: 'manage', path: PATH_DASHBOARD.contacts.manage },
          { title: 'group', path: PATH_DASHBOARD.contacts.group },
          // { title: 'Custom Fields', path: PATH_DASHBOARD.contacts.custom },

        ],
      },

      {
        title: 'ticket', path: PATH_DASHBOARD.tickets, icon: ICONS.invoice,
      },
      // voicemail
      // {
      //   title: 'voicemail',
      //   path: PATH_DASHBOARD.voicemail.root,
      //   icon: ICONS.voicemail,
      //   children: [
      //     { title: 'voicemailbox', path: PATH_DASHBOARD.voicemail.voicemailbox },

      //   ],
      // },

      {
        title: 'Workflow', path: PATH_DASHBOARD.apps.ivrstudio, icon: ICONS.ivrstudio,
        info: (
          <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
            NEW
          </Label>
        ),
      },

      {
        title: 'settings', path: '/dashboard/Settings', icon: <i class="fa fa-cog" aria-hidden="true"></i>,
        children: [
          { title: 'inbox', path: '/dashboard/settings/inbox' },
          { title: 'Application', path: '/dashboard/settings/application' },
          { title: 'Custom Fields', path: '/dashboard/settings/customfields?offsetCount=0&limit=10' },
          { title: 'Billing', path: '/dashboard/settings/billing' },
          { title: 'Team', path: '/dashboard/settings/team' },
          { title: 'Role', path: '/dashboard/settings/role' },
        ]

      },
      

    ],
  },

];
export const navConfigtrail = [



  {
    // subheader: 'general',



    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },


      // contacts
      {
        title: 'contacts',
        path: PATH_DASHBOARD.contacts.root,
        icon: ICONS.contact,
        children: [
          { title: 'manage', path: PATH_DASHBOARD.contacts.manage },
          { title: 'group', path: PATH_DASHBOARD.contacts.group },
          // { title: 'Custom Fields', path: PATH_DASHBOARD.contacts.custom },

        ],
      },

      {
        title: 'ticket', path: PATH_DASHBOARD.tickets, icon: ICONS.invoice,
      },

      {
        title: 'Workflow', path: PATH_DASHBOARD.apps.ivrstudio, icon: ICONS.ivrstudio,
        info: (
          <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
            NEW
          </Label>
        ),
      },
      // {
      //   title: 'Power Dialer', path: PATH_DASHBOARD.powerdialer.root, icon: <BoltIcon />,

      // },
      {
        title: 'settings', path: '/dashboard/Settings', icon: <i class="fa fa-cog" aria-hidden="true"></i>,
        children: [
          { title: 'inbox', path: '/dashboard/settings/inbox' },
          { title: 'Application', path: '/dashboard/settings/application' },
          { title: 'Custom Fields', path: '/dashboard/settings/customfields?offsetCount=0&limit=10' },
          { title: 'Billing', path: '/dashboard/settings/billing' },
          { title: 'Team', path: '/dashboard/settings/team' },
          { title: 'Role', path: '/dashboard/settings/role' },
        ]

      },
      

    ],
  },


];
export const navConfigOfSubUser = [


  {
    // subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },

      // contacts
      {
        title: 'contacts',
        path: PATH_DASHBOARD.contacts.root,
        icon: ICONS.contact,
        children: [
          { title: 'manage', path: PATH_DASHBOARD.contacts.manage },
          { title: 'group', path: PATH_DASHBOARD.contacts.group },


        ],
      },
      // voicemail
      // {
      //   title: 'voicemail',
      //   path: PATH_DASHBOARD.voicemail.root,
      //   icon: ICONS.voicemail,
      //   children: [
      //     { title: 'voicemailbox', path: PATH_DASHBOARD.voicemail.voicemailbox },

      //   ],
      // },


      // {
      //   title: 'Power Dialer', path: PATH_DASHBOARD.powerdialer.root, icon: <BoltIcon />,

      // },


    ],
  },

];

export const navConfigForWhatsappAdmin = [

  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },

      // number
      // {
      //   title: 'number',
      //   path: PATH_DASHBOARD.number.root,
      //   icon: ICONS.number,
      //   children: [
      //     { title: 'buy', path: PATH_DASHBOARD.number.buy },
      //     { title: 'manage', path: PATH_DASHBOARD.number.manage },
      //     { title: 'cancelled', path: PATH_DASHBOARD.number.cancelled },
      //   ],
      // },
      // contacts
      {
        title: 'contacts',
        path: PATH_DASHBOARD.contacts.root,
        icon: ICONS.contact,
        children: [
          { title: 'manage', path: PATH_DASHBOARD.contacts.manage },
          { title: 'group', path: PATH_DASHBOARD.contacts.group },
          // { title: 'Custom Fields', path: PATH_DASHBOARD.contacts.custom },

        ],
      },
      // voicemail
      // {
      //   title: 'voicemail',
      //   path: PATH_DASHBOARD.voicemail.root,
      //   icon: ICONS.voicemail,
      //   children: [
      //     { title: 'voicemailbox', path: PATH_DASHBOARD.voicemail.voicemailbox },

      //   ],
      // },
      // { title: 'voice', path: PATH_DASHBOARD.inbox.voice, icon: ICONS.voice, },
      // {
      //   title: 'whatsapp', path: PATH_DASHBOARD.inbox.whatsapp, icon: ICONS.whatsapp,
      //   info: (
      //     <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
      //       NEW
      //     </Label>
      //   ),
      // },
      // {
      //   title: 'SMS', path: PATH_DASHBOARD.inbox.sms, icon: ICONS.sms,
      // },

      {
        title: 'ticket', path: PATH_DASHBOARD.tickets, icon: ICONS.invoice,
      },
      {
        title: 'Workflow', path: PATH_DASHBOARD.apps.ivrstudio, icon: ICONS.ivrstudio,
        info: (
          <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
            NEW
          </Label>
        ),
      },
      // {
      //   title: 'Power Dialer', path: PATH_DASHBOARD.powerdialer.root, icon: <BoltIcon />,

      // },
      {
        title: 'settings', path: '/dashboard/Settings', icon: <i class="fa fa-cog" aria-hidden="true"></i>,
        children: [
          { title: 'inbox', path: '/dashboard/settings/inbox' },
          { title: 'Application', path: '/dashboard/settings/application' },
          { title: 'Custom Fields', path: '/dashboard/settings/customfields?offsetCount=0&limit=10' },
          { title: 'Billing', path: '/dashboard/settings/billing' },
          { title: 'Team', path: '/dashboard/settings/team' },
          { title: 'Role', path: '/dashboard/settings/role' },

        ]

      },
      // {
      //   title: 'ticket', path: PATH_DASHBOARD.tickets, icon: ICONS.invoice,
      // },
      // {
      //   title: 'telegram', path: PATH_DASHBOARD.inbox.telegram, icon: ICONS.telegram,
      //   info: (
      //     <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
      //       NEW
      //     </Label>
      //   ),
      // },
      // { title: 'Support', path: 'https://vibtree.tawk.help/', icon: ICONS.livesupport },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    // subheader: 'management',
    items: [

      // number
      // {
      //   title: 'number',
      //   path: PATH_DASHBOARD.number.root,
      //   icon: ICONS.number,
      //   children: [
      //     { title: 'buy', path: PATH_DASHBOARD.number.buy },
      //     { title: 'manage', path: PATH_DASHBOARD.number.manage },
      //     { title: 'cancelled', path: PATH_DASHBOARD.number.cancelled },
      //   ],
      // },

      // contacts
      // {
      //   title: 'contacts',
      //   path: PATH_DASHBOARD.contacts.root,
      //   icon: ICONS.contact,
      //   children: [
      //     { title: 'manage', path: PATH_DASHBOARD.contacts.manage },
      //     { title: 'group', path: PATH_DASHBOARD.contacts.group },
      //     { title: 'Custom Fields', path: PATH_DASHBOARD.contacts.custom },

      //   ],
      // },

      // voicemail
      // {
      //   title: 'voicemail',
      //   path: PATH_DASHBOARD.voicemail.root,
      //   icon: ICONS.voicemail,
      //   children: [
      //     { title: 'voicemailbox', path: PATH_DASHBOARD.voicemail.voicemailbox },

      //   ],
      // },


    ],

  },

  // Inbox
  // .............................................
  // {
  //   // subheader: 'inbox',
  //   items: [
  //     { title: 'voice', path: PATH_DASHBOARD.inbox.voice, icon: ICONS.voice },
  //     {
  //       title: 'whatsapp', path: PATH_DASHBOARD.inbox.whatsapp, icon: ICONS.whatsapp,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'sms', path: PATH_DASHBOARD.inbox.sms, icon: ICONS.sms,
  //     },
  //   ]
  // },

  // App
  // .............................................
  // {
  //   // subheader: 'apps',
  //   items: [

  //     {
  //       title: 'ivrstudio', path: PATH_DASHBOARD.apps.ivrstudio, icon: ICONS.ivrstudio,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },

  //   ]
  // },

  // App
  // .............................................
  {
    // subheader: 'support',
    items: [

      // { title: 'tickets', path: PATH_DASHBOARD.support.tickets, icon: ICONS.tickets },
      // { title: 'livesupport', path: PATH_DASHBOARD.support.livesupport, icon: ICONS.livesupport },
      // { title: 'helpdocs', path: PATH_DASHBOARD.support.helpdocs, icon: ICONS.helpdocs },
      // { title: 'feedback', path: PATH_DASHBOARD.support.feedback, icon: ICONS.feedback },

    ]
  },


];

export default navConfig;
