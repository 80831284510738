import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value : null
}

export const phoneNumberSlice = createSlice({
    name: "phoneNumber",
    initialState,
    reducers: {
        setPhoneNumber: (state, action) => {
            state.value = action.payload
            console.log('phoneNumber redux', action.payload )
        },
    },
});

export const { setPhoneNumber } = phoneNumberSlice.actions;
export default phoneNumberSlice.reducer;