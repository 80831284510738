import React, {useState,useEffect} from "react";
import {Button, Divider, Drawer, Grid, Menu, MenuItem, Pagination, Skeleton, Stack, TextField, Typography, Tabs, Tab, IconButton, Dialog, DialogTitle, Box, DialogContent, FormControl, Snackbar, Toolbar, Tooltip} from "@mui/material"
import { DataGridPro } from '@mui/x-data-grid-pro';
import {create_app, edit_app, get_app, get_app_config, SET_APP, SET_CLOUDPHONE_APP, SET_LIST_APP, SET_LIST_APP_CONFIG, edit_app_config, SET_SHOW_CLOUDPHONE, SET_SHOW_TICKET, SET_SHOW_POWER_DIALER, SET_SHOW_VOICEMAIL,get_company,SET_COMPANY, create_app_config, SET_APP_CONFIG_COUNT, delete_app_config, delete_app} from "src/redux/userReducer"
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import {baseUrl} from "src/utils/axios"
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InboxAllowed from "./components/CloudPhone/InboxAllowed";
import { CountryAllowed } from "./components/CloudPhone/CountryAllowed";
import { General } from "./components/CloudPhone/General";
import Integration from "./components/CloudPhone/Integration";
import { Ticket_General } from "./components/Ticket/Ticket_General";
import { Ticket_Stages } from "./components/Ticket/Ticket_Stages";
import { PowerDialer_General } from "./components/PowerDialer/PowerDialer_General";
import { VoiceMail_General } from "./components/VoiceMail/VoiceMail_General";
import Iconify from "../Iconify";
import { LoadingButton } from "@mui/lab";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { list_app_config } from "src/redux/navigation";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
export const Application_configure = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [app_name, setAppName] = useState("cloudphone");
    const [limit, setLimit] = useState(10)
    const [delete_id,setDeleteId] = useState([])
    const navigate = useNavigate();
    const [selectedRowsBulk, setSelectedRowsBulk] = useState({})
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [value, setValue] = React.useState(0);
    const authID = localStorage.getItem("authID");
    const userID = localStorage.getItem("userID");
    const [loading, setLoading] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState(false)
    const [app_config_id, setAppConfigId] = useState("")
    const [confirmDialogMessage, setConfirmDialogMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const [showError,setShowError] = useState(false);
    const [offset, setOffset] = useState(0)
    const [pageCount, setPageCount] = useState(0);
    const [ticket_app_config, setTicket_app_config] = useState([]);
    const [app_details, setAppDetais] = useState("")
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog,setOpenDialog] = useState(false);
    const [power_dialer_app_config, setPowerDialerAppConfig] = useState([]);
    const [cloudphone_app_config, setCloudPhoneAppConfig] = useState([])
    const [voice_mail_app_config, setVoiceMailAppConfig] = useState([])
    const [assignee,setAssignee] = useState("Assigned User")
    const [page,setPage] = useState(1);

    useEffect(() => {
        get_company().then((res) => {
            dispatch({
                type: SET_COMPANY,
                payload: res
            })
        }).catch((err) => {
            console.log("error", err)
        })
    }, [])
    
    const getLocation = useLocation()
    const {list_app,company, app_config_count,cloudphone_app, ticket_app, power_dialer_app, voice_mail_app} = useSelector((state) => state.user)
    const change_active = (is_active) => {
        var app_detail = app_details;
        app_detail.is_active = is_active;
        setAppDetais(app_detail);
        setAnchorEl(null)
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    
    useEffect(() => {
        setPageCount(Math.ceil(app_config_count / limit))
    }, [app_config_count, limit])

    const [assigned_user,setAssignedUser] = useState(company)

    useEffect(() => {
        const formData = [...company];
        const modified_user = formData.filter((value) => { return value._id !== userID });
        console.log("modified_user",modified_user);
        setAssignedUser(modified_user)
    }, [userID,company])

    useEffect(() => {
        const token = localStorage.getItem("redux-token")
        if(getLocation.pathname.includes('/dashboard/settings/application/configure')){
            setIsLoading(true);
            const offsetCount = searchParams.get("offsetCount");
            const limit = searchParams.get("limit");
            const app_name = searchParams.get("app_name")
            console.log("app_name",app_name);
            
            if (offsetCount !== undefined && offsetCount !== null) {
                setOffset(offsetCount)
                const page1 = (parseInt(offsetCount)/10) + 1
                setPage(page1)
                setLimit(limit)
                setAppName(app_name);
                get_app_config(token,app_name,authID,limit,offsetCount).then((res) => {
                    dispatch({
                        type: SET_APP_CONFIG_COUNT,
                        payload: res.totalCount
                    })
                    setIsLoading(false)
                    if (app_name === "cloudphone") {
                        setCloudPhoneAppConfig(res.data)
                    } else if (app_name === "ticket") {
                        setTicket_app_config(res.data)
                    } else if (app_name === "powerdialer") {
                        setPowerDialerAppConfig(res.data)
                    } else if (app_name === "voicemail") {
                        setVoiceMailAppConfig(res.data);
                    }
                }).catch((err) => {
                    console.log("error", err)
                })
            }
        }
    }, [offset,limit,authID])

    const Delete_App = async (id) => {
        console.log("app_config_id",id);
        setConfirmDialog(false);
        setIsLoading(true);
        const token = localStorage.getItem("redux-token");
        await delete_app_config(token, id).then(async(res) => {
            setErrorMessage(`${app_name} is deleted successfully`);
            setShowError(true);
            await get_app_config(token,app_name,authID,10,0).then((response) => {
                dispatch({
                    type:SET_APP_CONFIG_COUNT,
                    payload:response.totalCount
                })
                setLimit(10);
                setIsLoading(false)
                setOffset(0)
                if (app_name === "cloudphone") {
                    setCloudPhoneAppConfig(response.data)
                } else if (app_name === "ticket") {
                    setTicket_app_config(response.data);
                } else if (app_name === "powerdialer") {
                    setPowerDialerAppConfig(response.data);
                } else if (app_name === "voicemail") {
                    setVoiceMailAppConfig(response.data);
                }
            }).catch((err) => {
                console.log("error", err);
            })
        }).catch((err) => {
            console.log("error", err);
        })
    }

    const bulkDelete = async() => {
        const token = localStorage.getItem("redux-token");
        setConfirmDialog(false)
        setSelectedRowsBulk({})
        setIsLoading(true);
        for (let i = 0; i < delete_id.length; i++) {
            const app_config_id = delete_id[i]
            console.log("app_config_id",app_config_id);
            await delete_app_config(token, app_config_id).then((res) => {
            }).catch((err) => {
                console.log("error", err);
            })
        }
        setErrorMessage(`${app_name} configs are delete`)
        setShowError(true);
        await get_app_config(token, app_name,authID,10,0).then((response) => {
            dispatch({
                type:SET_APP_CONFIG_COUNT,
                payload:response.totalCount
            })
            setLimit(10);
            setIsLoading(false)
            setOffset(0)
            if (app_name === "cloudphone") {
                setCloudPhoneAppConfig(response.data)
            } else if (app_name === "ticket") {
                setTicket_app_config(response.data);
            } else if (app_name === "powerdialer") {
                setPowerDialerAppConfig(response.data);
            } else if (app_name === "voicemail") {
                setVoiceMailAppConfig(response.data);
            }
        }).catch((err) => {
            console.log("error", err);
        })
    }

    const handleChangePage = (event, newPage) => {
        
        setPage(newPage);
        var Offset = `${newPage-1}0`;
        if (newPage-1 === 0) {
            Offset = 0
        }
        var paramsData = { offsetCount: Offset, limit: limit, app_name: app_name}
        
        setOffset(Offset);
        navigate({
            pathname: "/dashboard/settings/application/configure",
            search: `?${createSearchParams(paramsData)}`
        })
        setIsLoading(true);
    }

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
    }

    function EnhancedTableToolbar(props) {
        const { numSelected } = props;
      
        return (
          <Toolbar
            sx={{
                pl: { sm: 2, lg: 2 },
                pr: { xs: 1, sm: 1 },
              width: "95%",
              bgcolor: '#fff',
              marginTop: 2,
              border:'1px solid #eee',
              borderRadius: '5px',
              boxShadow: '0 8px 16px 0 rgb(32 101 209 / 24%)',
              marginBottom: '10px'
            }}>
          
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
              <Tooltip title="Delete">
                <IconButton onClick={() => {
                    setConfirmDialogMessage(`confirm bulk delete ${app_name} selected`)
                    setConfirmDialog(true)
                }}>
                    <Iconify icon={'fluent:delete-32-regular'} width={20} height={20} />
                </IconButton>
              </Tooltip>
           
          </Toolbar>
        );
      }

    const create_app_name = async(token,body)  =>  {
        
        await create_app_config(token, body).then(async(res) => {
            await get_app_config(token, body.app_name, body.auth_id,10,0).then((response) => {
                dispatch({
                    type:SET_APP_CONFIG_COUNT,
                    payload:response.totalCount
                })
                setLoading(false);
                setLimit(10);
                setOffset(0)
                setOpenDialog(false)
                if (body.app_name === "cloudphone") {
                    setCloudPhoneAppConfig(response.data)
                    setErrorMessage("Cloudphone Config is added");
                    setShowError(true)
                } else if (body.app_name === "ticket") {
                    setTicket_app_config(response.data);
                    setErrorMessage("Ticket Config is added");
                    setShowError(true)
                } else if (body.app_name === "powerdialer") {
                    setPowerDialerAppConfig(response.data);
                    setErrorMessage("PowerDialer Config is added");
                    setShowError(true)
                } else if (body.app_name === "voicemail") {
                    setVoiceMailAppConfig(response.data);
                    setErrorMessage("VoiceMail Config is added");
                    setShowError(true)
                }
            }).catch((err) => {
                console.log("error", err);
            })
          }).catch((err) => {
            console.log("error", err)
          })
    }

    const add_app_config = async() => {
        setLoading(true)
        const token = localStorage.getItem("redux-token")
        if (assignee ==="Assigned User") {
            setLoading(false);
            setErrorMessage("Please assign a user");
            setShowError(true)
        }
        await list_app_config(assignee,token,app_name).then((response)=>{
            const app_response_data = response.data.data.data;
            if(app_response_data.length > 0){
                setLoading(false);
                setErrorMessage(`${app_name} is already added to this user`);
                setShowError(true)
            } else {
        var body = {
            "user_id": assignee,
            "auth_id": authID,
            "is_active" : false,
            "app_name":app_name
        }
        create_app_name(token,body)
            }
        }).catch((err)=>{
            console.log("error",err)
        })
    }
    const columns = [
        {
            field: "Name",
            headerName: "Name",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                    {params.row.user_id !== null && params.row.user_id !== undefined ? (
                        <div style={{ color: "#000", fontSize: 12, fontWeight: "500"}}>
                            {params.row.user_id.FirstName} {params.row.user_id.LastName}
                        </div>
                    ): (
                        <div></div>
                    )}
                    </>
                )
            }
        },
        {
            field: "Status",
            headerName: "Status",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.is_active === true ? (
                            <div style={{ backgroundColor: "rgb(223, 243, 234)",  height: 30, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 10}}>
                                <div style={{ color: "rgb(27, 128, 106)", fontSize: 13, fontWeight: "bold", marginLeft: 10, marginRight: 10 }}>
                                    Active
                                </div>
                            </div>
                        ): (
                            <div style={{ backgroundColor: "#fcd2d2", height: 30, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 10 }}>
                                <div style={{ color: "red", fontSize: 13, fontWeight: "bold", marginLeft: 10, marginRight: 10 }}>
                                    Inactive
                                </div>
                            </div>
                        )}
                    </div>
                )
            }
        },
        {
            field: "Activate On",
            headerName: "Activate On",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                      {moment(params.row.createdAt).format("ddd DD MMM YYYY HH:mm:ss A")}
                    </div>
                )
            }
        },
        {
            field: "Activate By",
            headerName: "Activate By",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        {list_app.findIndex((value) => { return value.app_id._id === params.row._id }) !== -1 && <div style={{ color: "#000", fontSize: 13 }}>
                            {list_app[list_app.findIndex((value) => { return value.app_id._id === params.row._id })].active_by.FirstName} {list_app[list_app.findIndex((value) => { return value.app_id._id === params.row._id })].active_by.LastName}
                        </div>}
                    </div>
                )
            }
        },
        {
            field: "Action",
            headerName: "Action",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row", }}>
                        <Button color='primary' variant="outlined" onClick={() => {
                            setAppDetais(params.row);
                            setDrawerOpen(true)
                        }}>
                           
                                Edit
                     
                        </Button>
                        <IconButton onClick={() => {
                            setAppConfigId(params.row._id)
                            setConfirmDialogMessage(`confirm delete ${app_name} selected`)
                            setConfirmDialog(true)
                        }}>
                        <Iconify icon={'fluent:delete-32-regular'} width={20} height={20} />
                        </IconButton>
                    </div>
                )
            }
        }
    ]
    const skeltonLoaders = (
        <>
            <Grid container>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
            </Grid>
        </>
    )
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", alignSelf: "center"}}>
            <div style={{ width: "100%", height: "100%"}}>
                    <div style={{ width: "95%", display: "flex", marginTop: 20, alignItems: "center", flexDirection: "row", justifyContent: "space-between"}}>
                        <div></div>
                        <Button style={{ backgroundColor: "#0046BB", width: 120, height: 40, borderRadius: 10, marginRight: 10, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly",}} variant="contained" onClick={()=> setOpenDialog(true)}>
                            <AddIcon style={{ width: 20, height: 20, color: "#fff" }} />
                            <div style={{ color: "#fff", fontSize: 13, fontWeight: "bold" }}>
                                Add User
                            </div>
                        </Button>
                    </div>
                    {isLoading === true ? (
                        <>
                          {skeltonLoaders}
                        </>
                    ): (
                    <>
                    {selectedRowsBulk.size > 0 && <EnhancedTableToolbar numSelected={selectedRowsBulk.size} />}
                    <DataGridPro
                 columns={columns}
                 disableSelectionOnClick
                 onSelectionModelChange={(ids) => {
                    var formData = [];
                    formData.push(ids)
                    console.log("formData",formData[0])
                    setDeleteId(formData[0])
                    const selectedIDs = new Set(ids);
                    setSelectedRowsBulk(selectedIDs)
                 }}
                 checkboxSelection
                 rows={app_name === "cloudphone" ? cloudphone_app_config : app_name === "ticket" ? ticket_app_config : app_name === "powerdialer" ? power_dialer_app_config : app_name === "voicemail" ? voice_mail_app_config : []}
                 getRowId={(r) => r._id}
                 style={{ height: 550, width: "95%", display: "flex", alignSelf: "center", marginTop: 20, marginLeft: 10 }}
                 hideFooter
                throttleRowsMs={2000}
                 />
                {app_config_count>10&&<>
                    <Grid container spacing={3} style={{ marginTop: "0px", marginBottom: 50, }} justifyContent='center' alignItems='center'>
                    <Grid item> Rows Per Page</Grid>
                    <Grid item>

                        <TextField
                            value={limit}
                            onChange={(event) => {
                                setLimit(event.target.value)

                                var paramsData = { offsetCount: searchParams.get("offsetCount"), limit: event.target.value, app_name: app_name}

                                navigate({
                                    pathname: '/dashboard/settings/application/configure',
                                    search: `?${createSearchParams(paramsData)}`,
                                });

                            }}
                            size='small'
                            select>
                            <MenuItem value={10}>
                                10
                            </MenuItem>
                            <MenuItem value={25}>
                                25
                            </MenuItem>
                            <MenuItem value={50}>
                                50
                            </MenuItem>
                            <MenuItem value={100}>
                                100
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item>
                        <Pagination count={pageCount} page={page} showFirstButton showLastButton onChange={handleChangePage} color="primary" shape="rounded" />
                    </Grid>

                </Grid>
                </>}
                    </>
                )}
                 
            </div> 
            <Drawer open={drawerOpen} onClose={handleCloseDrawer} anchor="right" className="numberSettigns" sx={{ minWidth: 550 }}>
                {app_details !== "" && <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5, minWidth: 550}} >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {app_details.user_id.user_logo !== null && app_details.user_id.user_logo !== undefined && app_details.user_id.user_logo !== "undefined" ? (
                            <img src={`${baseUrl}/download/user_logo?fileLocation=${app_details.user_id.user_logo}`} style={{width: 50, height: 50, borderRadius: 40, }}/>
                        ): (
                            <div style={{ width: 50, height: 50, borderRadius: 40, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center"}}>

                            </div>
                        )}
                        <div style={{ marginLeft: 10 }}>
                            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                {app_details.user_id.FirstName} {app_details.user_id.LastName}
                            </Typography>
                            <Typography variant="caption" sx={{ flexGrow: 1 }}>
                                App Id: {app_details._id}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginRight: -5, }}>
                        <Button style={{ height: 40, display: "flex", flexDirection: "row", border: "1px solid lightgrey", alignItems: "center",}} onClick={(e) => setAnchorEl(e.currentTarget)}>
                            <div style={{ width: 20, height: 20, backgroundColor: app_details.is_active === true ? "rgb(163, 235, 177)" : "red", borderRadius: 5, marginLeft: 10 }}/>
                            <div style={{ color: "#000", marginLeft: 10 }}>
                                {app_details.is_active === true ? "Active" : "Inactive"}
                            </div>
                            <KeyboardArrowDownIcon style={{ color: "#000", width: 15, height: 15, marginLeft: 10, marginRight: 10}}/>
                        </Button>
                        <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl} 
                        onClose={() => setAnchorEl(null)}
                        open={Boolean(anchorEl)}
                        sx={{ marginTop: 5, }}
                        anchorOrigin={{ 
                            vertical: 'top', 
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                            <MenuItem onClick={() => change_active(true)}>
                               <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                                <div style={{ width: 20, height: 20, backgroundColor: "rgb(163, 235, 177)", borderRadius: 5, marginLeft: 10 }}/>
                                <div style={{ marginLeft: 15}}>
                                   Active
                                </div>
                               </div>
                            </MenuItem>
                            <MenuItem onClick={() => change_active(false)}>
                               <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                                <div style={{ width: 20, height: 20, backgroundColor: "red", borderRadius: 5, marginLeft: 10 }}/>
                                <div style={{ marginLeft: 15}}>
                                   Inactive
                                </div>
                               </div>
                            </MenuItem>
                        </Menu>
                        <IconButton onClick={handleCloseDrawer}>
                            <Iconify icon={'eva:close-fill'} width={20} height={20} />
                        </IconButton>
                    </div>
                </Stack>}
                <Divider sx={{ borderStyle: 'dashed' }} />
                {app_name === "cloudphone" ? (
                    <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" style={{ marginTop: "10px" }}>
                        <Tab label="General" {...a11yProps(0)} sx={{ fontSize: 12, marginLeft: 2 }}/>
                        <Tab label="Country Allowed" {...a11yProps(1)} sx={{ fontSize: 12, marginLeft: -2}}/>
                        <Tab label="Inbox Allowed" {...a11yProps(2)} sx={{ fontSize: 12, marginLeft: -2 }}/>
                        <Tab label="Integrations" {...a11yProps(3)} sx={{ fontSize: 12, marginLeft: -2 }}/>
                    </Tabs>
                ): (
                    <>
                      {app_name === "ticket" ? (
                        <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" style={{ marginTop: "10px" }}>
                            <Tab label="General" {...a11yProps(0)} sx={{ fontSize: 12, marginLeft: 2 }}/>
                            <Tab label="Stages" {...a11yProps(1)} sx={{ fontSize: 12, marginLeft: -2}}/>
                        </Tabs>
                      ): (
                        <>
                          {app_name === "powerdialer" ? (
                            <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" style={{ marginTop: "10px" }}>
                                <Tab label="General" {...a11yProps(0)} sx={{ fontSize: 12, marginLeft: 2 }}/>
                            </Tabs>
                          ): (
                            <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" style={{ marginTop: "10px" }}>
                                <Tab label="General" {...a11yProps(0)} sx={{ fontSize: 12, marginLeft: 2 }}/>
                            </Tabs>
                          )}
                        </>
                      )}
                    </>
                )}
                {app_name === "cloudphone" ? (
                   <>
                     <TabPanel index={0} value={value}>
                        <General props={app_details} setProps={setAppDetais} setDrawerOpen={setDrawerOpen}/>
                     </TabPanel>
                     <TabPanel index={1} value={value}>
                        <CountryAllowed props={app_details} setProps={setAppDetais} setDrawerOpen={setDrawerOpen}/>
                     </TabPanel>
                     <TabPanel index={2} value={value}>
                        <InboxAllowed props={app_details} setProps={setAppDetais} setDrawerOpen={setDrawerOpen}/>
                     </TabPanel>
                     <TabPanel index={3} value={value}>
                        <Integration props={app_details} setProps={setAppDetais} setDrawerOpen={setDrawerOpen}/>
                     </TabPanel>
                   </>
                ): (
                    <>
                      {app_name === "ticket" ? (
                        <>
                          <TabPanel index={0} value={value}>
                            <Ticket_General props={app_details} setProps={setAppDetais} setDrawerOpen={setDrawerOpen}/>
                          </TabPanel>
                          <TabPanel index={1} value={value}>
                            <Ticket_Stages props={app_details} setProps={setAppDetais} setDrawerOpen={setDrawerOpen}/>
                          </TabPanel>
                        </>
                      ): (
                        <>
                          {app_name === "powerdialer" ? (
                            <TabPanel index={0} value={value}>
                                <PowerDialer_General props={app_details} setProps={setAppDetais} setDrawerOpen={setDrawerOpen}/>
                            </TabPanel>
                          ): (
                            <TabPanel index={0} value={value}>
                                <VoiceMail_General props={app_details} setProps={setAppDetais} setDrawerOpen={setDrawerOpen}/>
                            </TabPanel>
                          )}
                        </>
                      )}
                    </>
                )}
            </Drawer>
            <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        message={errorMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => setShowError(false)}>
              OK
            </Button>

          </React.Fragment>
        }
      />
      <ConfirmationDialog openDialog={confirmDialog} setOpenDialog={setConfirmDialog} triggerConfirm={()=>{
        if (confirmDialogMessage === `confirm delete ${app_name} selected`) {
             Delete_App(app_config_id) 
        } else { 
            bulkDelete();
        }
      }} message={confirmDialogMessage} />
            <Dialog
      maxWidth="sm"
      fullWidth
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <h3 style={{ flexGrow: 1 }}>Add User</h3>
            <IconButton onClick={() => setOpenDialog(false)}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
        <Box style={{ padding: "20px 0 0 0" }}>
        <FormControl sx={{ mt: 3 }} fullWidth>
                    <TextField select size="medium"fullWidth value={assignee} onChange={(event) => {
                        setAssignee(event.target.value)
                    }}>
                        <MenuItem value={"Assigned User"}>
                            Assigned User
                        </MenuItem>
                        {assigned_user.map((item, index) => {
                            return (
                                <MenuItem value={item._id} key={index}>
                                    {item.fullName}
                                </MenuItem>
                            )
                        })}
                    </TextField>
        </FormControl>

        </Box>
        <LoadingButton color="primary" loading={loading} sx={{ marginTop:5,height:40}} fullWidth variant="contained"onClick={() => add_app_config()}>
            <Typography variant="subtitle2"  color="#fff">
                Add User
            </Typography>
        </LoadingButton>
        </DialogContent>
      </Dialog>
        </div>
    )
}

function EnhancedTableToolbar(props) {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          bgcolor: '#fff',
          border:'1px solid #eee',
          borderRadius: '5px',
          boxShadow: '0 8px 16px 0 rgb(32 101 209 / 24%)',
          marginBottom: '10px'
        }}>
      
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
          <Tooltip title="Delete">
            <IconButton onClick={() => {}}>
                <Iconify icon={'fluent:delete-32-regular'} width={20} height={20} />
            </IconButton>
          </Tooltip>
       
      </Toolbar>
    );
  }