import React, { useState, useEffect } from 'react';

import {
    Drawer,
    AppBar,
    Toolbar,
    IconButton,
    Avatar,
    Typography,
    Tooltip,
    LinearProgress,
    Grid,
    Button,
    TextField,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
    Stack,
    Badge,
    Card,
    Box,
    Tabs,
    Tab
} from '@mui/material';

import Iconify from '../../Iconify';
import Copy from './Copy'
// import ContactName from './ContactName'
import CallSummary from './CallDetails/CallSummary'
import CallLifeCycle from './CallDetails/CallLifeCycle'
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import { getIvrVoice, getIvrVoiceSearch, getFilter, setCallDetailsDrawer, setVoiceId } from 'src/redux/slices/Inbox/voice';

import TimeZone from '../../TimeZone'



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CallDetails({
}) {


    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)
    const { callDetailsDrawer, getFlowByIdResponse } = useSelector(state => state.inboxVoice)

    const [detailsData, setDetailsData] = useState([])

    

    useEffect(() => {
        console.log('getFlowByIdResponse', getFlowByIdResponse)
        if(getFlowByIdResponse.status === true && getFlowByIdResponse.status !== undefined){
            setLoading(false)
            setDetailsData(getFlowByIdResponse.data)
        }
    }, [getFlowByIdResponse])

    const number = detailsData.ParentCall !== undefined ? detailsData.ParentCall.From : ''


    // console.log('call details props', props)


    const callSid = detailsData.ParentCall !== undefined ? detailsData.ParentCall.ParentCallSid : ''
    const callerType = detailsData.CallerType
    const callStatus = detailsData.CallStatus
    const callInitiated = detailsData.ParentCall !== undefined ? detailsData.ParentCall.CallStatus['initiated'] :''
    const callDuration = detailsData.ParentCallDuration
    const callRinging = detailsData.ParentCall !== undefined ? detailsData.ParentCall.CallStatus['ringing'] : ''
    const callCompleted = detailsData.ParentCall !== undefined ? detailsData.ParentCall.CallStatus['completed'] : ''
    const [value, setValue] = React.useState(0);
    const callerName = detailsData.CallerName
    const recieverName = detailsData.ReceiverName
    const cloudNumberName = detailsData.CloudNumberName
    const QueueTime = detailsData.QueueTime ? moment.utc(detailsData.QueueTime * 1000).format("HH:mm:ss") : "00:00:00"
    const Direction = detailsData.ParentCall !== undefined ? (detailsData.ParentCall.Direction === 'inbound' ? "Inbound" : "Outbound") : ''

    const noteLenght = detailsData.Notes !== undefined ? detailsData.Notes.length : 0



    const handleDrawerClose = () => {
        dispatch(setCallDetailsDrawer(false))
    }
  

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };




    return (
        <span>
            {/* {open_drawer !== true && <>
                {drawerOpen === false && <Tooltip title="Call Details" >
                    <IconButton size='small' onClick={() => handleDrawerOpen()}>
                        <Iconify icon='clarity:details-line' />
                    </IconButton>
                </Tooltip>}
            </>} */}
            <Drawer anchor="right" open={callDetailsDrawer} onClose={handleDrawerClose} className="numberSettigns">

                {loading ? (
                    <Card style={{padding: '20px', textAlign: 'center', minWidth: '300px'}}>
                    <>
                    Fetching Data..
                    <LinearProgress />
                    </>
                </Card>
                ) : (

               <>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
                    <Box>
                        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                            Call Details



                        </Typography>
                        <Typography variant="caption" sx={{ flexGrow: 1 }}>
                            Call ID: {callSid}

                        </Typography>
                    </Box>



                    <IconButton onClick={handleDrawerClose}>
                        <Iconify icon={'eva:close-fill'} width={20} height={20} />
                    </IconButton>
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />



                <div style={{ padding: "10px 30px", maxWidth: "600px" }}>

                    {callStatus === 'completed' ? (
                        <Card style={{ padding: '20px', background: 'rgba(9,187,6,20%)', color: '#038F01' }}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                                        {callerType} {Direction} Call from {callerName} on <TimeZone date={callInitiated} /> by {recieverName} and lasted for {callDuration} seconds
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: '10px' }} spacing={3}>
                                <Grid item>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        {callerName}
                                    </Typography>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        +{number}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                        <Tooltip title="Click to Call" >
                                            <IconButton size='small'>
                                                <Iconify icon='fluent:call-20-regular' />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Click to SMS" >
                                            <IconButton size='small'  >
                                                <Iconify icon='material-symbols:sms-outline-rounded' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Click to Whatsapp" >
                                            <IconButton size='small'>
                                                <Iconify icon='ic:baseline-whatsapp' />
                                            </IconButton>
                                        </Tooltip>
                                        <Copy text={number} />
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Ringing</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> <TimeZone date={callRinging} /></Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Queue Time </Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>{QueueTime}</Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Completed</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}><TimeZone date={callCompleted} /></Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Duration</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> {moment.utc(callDuration * 1000).format("HH:mm:ss")}</Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Card>
                    ) : ''}

                    {callStatus === 'failed' ? (
                        <Card style={{ padding: '20px', background: 'rgba(165,0,0,20%)', color: 'rgba(165,0,0,1)' }}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                                        {callerType} {Direction} Call from {callerName} on <TimeZone date={callInitiated} /> by {recieverName} and lasted for {callDuration} seconds
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: '10px' }} spacing={3}>
                                <Grid item>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        {callerName}
                                    </Typography>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        +{number}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                        <Tooltip title="Click to Call" >
                                            <IconButton size='small'>
                                                <Iconify icon='fluent:call-20-regular' />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Click to SMS" >
                                            <IconButton size='small'  >
                                                <Iconify icon='material-symbols:sms-outline-rounded' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Click to Whatsapp" >
                                            <IconButton size='small'>
                                                <Iconify icon='ic:baseline-whatsapp' />
                                            </IconButton>
                                        </Tooltip>
                                        <Copy text={number} />
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Ringing</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> <TimeZone date={callRinging} /></Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Connected</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}><TimeZone date={callInitiated} /></Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Completed</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> <TimeZone date={callCompleted} /></Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Call Duration</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>{callDuration}</Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Card>
                    ) : ''}

                    {callStatus === 'canceled' ? (
                        <Card style={{ padding: '20px', background: 'rgba(165,0,0,20%)', color: 'rgba(165,0,0,1)' }}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                                        {callerType} {Direction} Call from {callerName} on <TimeZone date={callInitiated} /> by {recieverName} and lasted for {callDuration} seconds
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: '10px' }} spacing={3}>
                                <Grid item>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        {callerName}
                                    </Typography>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        +{number}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                        <Tooltip title="Click to Call" >
                                            <IconButton size='small'>
                                                <Iconify icon='fluent:call-20-regular' />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Click to SMS" >
                                            <IconButton size='small'  >
                                                <Iconify icon='material-symbols:sms-outline-rounded' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Click to Whatsapp" >
                                            <IconButton size='small'>
                                                <Iconify icon='ic:baseline-whatsapp' />
                                            </IconButton>
                                        </Tooltip>
                                        <Copy text={number} />
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Ringing</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> <TimeZone date={callRinging} /></Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Connected</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}><TimeZone date={callInitiated} /></Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Completed</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> <TimeZone date={callCompleted} /></Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Call Duration</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}><TimeZone date={callDuration} /></Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Card>
                    ) : ''}


                    {callStatus === 'missed' ? (
                        <Card style={{ padding: '20px', background: 'rgba(201,84,0,20%)', color: 'rgba(201,84,0,1)' }}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                                        {callerType} {Direction} Call from {callerName} on <TimeZone date={callInitiated} /> by {recieverName} and lasted for {callDuration} seconds
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: '10px' }} spacing={3}>
                                <Grid item>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        {callerName}
                                    </Typography>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        +{number}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                        <Tooltip title="Click to Call" >
                                            <IconButton size='small'>
                                                <Iconify icon='fluent:call-20-regular' />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Click to SMS" >
                                            <IconButton size='small'  >
                                                <Iconify icon='material-symbols:sms-outline-rounded' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Click to Whatsapp" >
                                            <IconButton size='small'>
                                                <Iconify icon='ic:baseline-whatsapp' />
                                            </IconButton>
                                        </Tooltip>
                                        <Copy text={number} />
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Ringing</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> <TimeZone date={callRinging} /> </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Connected</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}><TimeZone date={callInitiated} /> </Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Completed</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> <TimeZone date={callCompleted} /></Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Call Duration</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>{callDuration}</Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Card>
                    ) : ''}

                    {callStatus === 'no-answer' ? (
                        <Card style={{ padding: '20px', background: 'rgba(201,84,0,20%)', color: 'rgba(201,84,0,1)' }}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                                        {callerType} {Direction} Call from {callerName} on <TimeZone date={callInitiated} /> by {recieverName} and lasted for {callDuration} seconds
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: '10px' }} spacing={3}>
                                <Grid item>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        {callerName}
                                    </Typography>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        +{number}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                        <Tooltip title="Click to Call" >
                                            <IconButton size='small'>
                                                <Iconify icon='fluent:call-20-regular' />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Click to SMS" >
                                            <IconButton size='small'  >
                                                <Iconify icon='material-symbols:sms-outline-rounded' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Click to Whatsapp" >
                                            <IconButton size='small'>
                                                <Iconify icon='ic:baseline-whatsapp' />
                                            </IconButton>
                                        </Tooltip>
                                        <Copy text={number} />
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Ringing</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> <TimeZone date={callRinging} /></Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Connected</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}><TimeZone date={callInitiated} /></Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Completed</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> <TimeZone date={callCompleted} /></Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Call Duration</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>{callDuration}</Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Card>
                    ) : ''}



                    {callStatus === 'busy' ? (
                        <Card style={{ padding: '20px', background: 'rgba(201,84,0,20%)', color: 'rgba(201,84,0,1)' }}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                                        {callerType} {callStatus} Call from {callerName} on <TimeZone date={callInitiated} /> by {recieverName} and lasted for {callDuration} seconds
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: '10px' }} spacing={3}>
                                <Grid item>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        {callerName}
                                    </Typography>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        {number}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                        <Tooltip title="Click to Call" >
                                            <IconButton size='small'>
                                                <Iconify icon='fluent:call-20-regular' />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Click to SMS" >
                                            <IconButton size='small'  >
                                                <Iconify icon='material-symbols:sms-outline-rounded' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Click to Whatsapp" >
                                            <IconButton size='small'>
                                                <Iconify icon='ic:baseline-whatsapp' />
                                            </IconButton>
                                        </Tooltip>
                                        <Copy text={number} />
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Ringing</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> <TimeZone date={callRinging} /></Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Connected</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}><TimeZone date={callInitiated} /></Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Completed</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}> <TimeZone date={callCompleted} /></Typography>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>Call Duration</Typography>
                                                </td>
                                                <td>

                                                    <Typography variant="caption" sx={{ flexGrow: 1 }}>{callDuration}</Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Card>
                    ) : ''}


                    <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" style={{ marginTop: "10px" }}>
                        <Tab label="Call Summary" {...a11yProps(0)} />
                        <Tab label="Call Lifecycle" {...a11yProps(1)} />
                    </Tabs>

                    <TabPanel value={value} index={0} style={{ padding: '0' }}>

                        <CallSummary props={detailsData} />
                    </TabPanel>
                    <TabPanel value={value} index={1} >
                        <CallLifeCycle props={detailsData} />
                    </TabPanel>

                </div>
                </>

)}
            </Drawer>





        </span>
    )
}