import { createSlice } from "@reduxjs/toolkit";
import { useSnackbar } from 'notistack';
// utils
import axios, { REACT_APP_BASE_URL } from '../../../utils/axios';
//
import { dispatch, missed_call, callHistory } from '../../store';
import { showSnackbar } from '../snackbar'

const initialState = {
    isLoading: false,
    isStartLoadingStatus: false,
    error: null,
    ticketId: '',
    customFields: {},
    conversations: {},
    ticketUpdateResponse: {},
    ticketsResponse: {},
    ticketByIdResponse: {},
    liveConversations: {},
    isExportLoading: false,
    exportData: {},
}

const ticketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        startExportLoading(state) {
          state.isExportLoading = true;
      },
      stopExportLoading(state) {
        state.isExportLoading = false;
    },
       // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
          // START LOADING
        stopLoadingStatus(state) {
            state.isStartLoadingStatus = false;
        },

         // START LOADING
        startLoadingStatus(state) {
        state.isStartLoadingStatus = true;
      },

      getTicketIdSuccess(state,action){
        state.isLoading = false;
        state.ticketId = action.payload
      },

      getCustomFieldsSuccess(state,action){
        state.isLoading = false;
        state.customFields = action.payload
      },

      getConversationSuccess(state, action){
        state.isLoading = false;
        state.conversations = action.payload
      },

      updateTicketsSuccess(state, action){
        state.isLoading = false;
        state.ticketUpdateResponse = action.payload
      },
      
      getTicketsByIdSuccess(state, action){
        state.isLoading = false;
        state.ticketByIdResponse = action.payload
      },
      getLiveConversationSuccess(state, action){
        state.isLoading = false;
        state.liveConversations = action.payload
      },
      getExportSuccess(state, action){
        state.isExportLoading = false;
        state.exportData = action.payload;
      }

    }
})

export const selectAllTicketsData = (state) => state.ticketsSlice;

export default ticketsSlice.reducer

export function updateTicketStatus(id, data) {

    return async () => {
      dispatch(ticketsSlice.actions.startLoadingStatus());
      try {
        const response = await axios.patch(`/ticket-list/${id}`, { "status": data });
        console.log('update status redux', response)
        dispatch(ticketsSlice.actions.stopLoadingStatus());
        if (response.data.status === true) {
          dispatch(showSnackbar('Status Changed Successfully'));
        } else {
          dispatch(showSnackbar('Oops.. Trouble while updating Status'));
        }
  
      } catch (error) {
        dispatch(ticketsSlice.actions.stopLoadingStatus());
        dispatch(ticketsSlice.actions.hasError(error));
        dispatch(showSnackbar('Oops.. Trouble while updating Status'));
      }
    };
  }

  export function appConfig(app, authId) {

    return async () => {
      dispatch(ticketsSlice.actions.startLoading());
      try {
        const response = await axios.get(`/app-config?app_name=${app}&auth_id=${authId}`);
          dispatch(ticketsSlice.actions.getTicketIdSuccess(response.data));
          
      } catch (error) {
        dispatch(ticketsSlice.actions.hasError(error));
      }
    };
  }

  export function getAllCustomFields(authId, userid) {
    return async () => {
      dispatch(ticketsSlice.actions.startLoading());
      try {
        const response = await axios.get(`/contact/custom_field?AccountSid=${authId}&user_id=${userid}&sort=-_id&modules=ticket`);
        dispatch(ticketsSlice.actions.getCustomFieldsSuccess(response.data));
        console.log('get ticket custom fields Success', response)
  
      } catch (error) {
        dispatch(ticketsSlice.actions.hasError(error));
  
      }
    };
  }

  export function getConversation(offsetCount, rowsPerPage, selectedContactNumber) {
    console.log("selectedContactNumber", selectedContactNumber)
    return async () => {
      dispatch(ticketsSlice.actions.startLoading());
      try {
        let link = `/contact/cdr?limit=${rowsPerPage}&offset=${offsetCount}&contactNumber=${selectedContactNumber}&sort=-createdAt&populate=userID`
        // let link = `/ivrstudios/flowChart?limit=${rowsPerPage}&offset=${offsetCount}&sort=-createdAt&populate=userID&ContactNumber=${selectedContactNumber}`
        const response = await axios.get(link);
        dispatch(ticketsSlice.actions.getConversationSuccess(response.data));
      } catch (error) {
        dispatch(ticketsSlice.actions.hasError(error));
      }
    };
  }

  export function updateTicket(id, data) {
    return async () => {
      dispatch(ticketsSlice.actions.startLoading());
      try {
      
        const response = await axios.patch(`/ticket-list/${id}`, data);
        dispatch(ticketsSlice.actions.updateTicketsSuccess(response.data));
        if (response.data.status === true) {
          dispatch(showSnackbar('Ticket Updated Successfully'));
        } else {
          dispatch(showSnackbar('Oops.. Trouble while updating Ticket'));
        }
      } catch (error) {
        dispatch(ticketsSlice.actions.hasError(error));
      }
    };
  }

  export function getLiveConversations(number) {
    return async () => {
      dispatch(ticketsSlice.actions.startLoading());
      try {
        const response = await axios.get(`/realtime?contact=${number}`);
        dispatch(ticketsSlice.actions.getLiveConversationSuccess(response.data));
        console.log('live call Success', response)
  
      } catch (error) {
        dispatch(ticketsSlice.actions.hasError(error));
  
      }
    };
  }

  export function getTicketExport(authId, status, startDate, endDate, assignee, custom_variable) {
    return async () => {
      dispatch(ticketsSlice.actions.startExportLoading());
      var url = `/ticket-list?AccountSid=${authId}&sort=-_id&populate=created_by&populate=user_id&status=${status}&startDate=${startDate}&endDate=${endDate}&user_id=${assignee}&customVariable=${JSON.stringify(custom_variable)}`;
      if (Object.keys(custom_variable).length === 0) {
        url = `/ticket-list?AccountSid=${authId}&sort=-_id&populate=created_by&populate=user_id&status=${status}&startDate=${startDate}&endDate=${endDate}&user_id=${assignee}`
      }
      try {
        const response = await axios.get(url);
        dispatch(ticketsSlice.actions.getExportSuccess(response.data));
        dispatch(ticketsSlice.actions.stopExportLoading());
        console.log('live call Success', response)
        dispatch({
          type: "SET_OPEN_DRAWER",
          payload: false
      })
  
      } catch (error) {
        dispatch(ticketsSlice.actions.hasError(error));
        dispatch(ticketsSlice.actions.stopExportLoading());
        
      }
    };
  }
