
import { createSlice } from "@reduxjs/toolkit";
import { useSnackbar } from 'notistack';
// utils
import axios from 'axios';

//
import { dispatch } from '../../store';

import {showSnackbar} from '../snackbar'

import { sobotUrl } from 'src/utils/axios'


// ----------------------------------------------------------------------


const initialState= {
    edit_label: false,
    isLoading: false,
    error: null,
    conversations: {},
    show_create_label_dialog: false,
    showCustomerDetailDialog: false,
    myConversations: {},
    conversationId: '',
    conversationIdName: '',
    conversationIdData: {},
    addChatTextResponse: {},
    chatNotActive: false,
    templateActive: false, 
    templateDialog: false,
    templateData: {},
    selectedTemplate: {},
    searchCoversation: '',
    agentDialog: false,
    agentData: {},
    selectedAgent: '',
    closedConversations: {},
    settingsNav: 'customers',
    chatNav: 'mychats',
    searchCustomers: '',
    customerData: {},
    selectedCustomer: {},
    myData: {},
    directTo: '',
    createCustomerDialog: false,
    createCustomerResponse: {},
    labelData: {},
    selectedLabel: {},
    show_label_dialog: false,
    createTeamDialog: false,
    createTeamResponse: {},
    teamData: {},
    searchTeam: '',
    selectedTeam: {},
    editTeamDialog: false,
    editTeamResponse: {}

}

const whatsappSlice = createSlice({
    name: 'whatsapp',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        setEditLabel(state, action) {
          state.edit_label = action.payload;
        },

        stopLoading(state) {
          state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setShowCustomerDetailDialog(state, action) {
          state.showCustomerDetailDialog = action.payload;
        },
        
        // GET NUMBER SSUCCESS
        getConversationsSuccess(state, action) {
          state.conversations = action.payload;
          state.isLoading = false;
        },

        setShowLabelDialog(state, action) {
          state.show_label_dialog = action.payload;
        },

        setShowCreateLabelDialog(state, action) {
          state.show_create_label_dialog = action.payload
        },

        // GET NUMBER SSUCCESS
        getMyConversationsSuccess(state, action) {
          state.myConversations = action.payload;
          state.isLoading = false;
        },

         // GET NUMBER SSUCCESS
         getClosedConversationsSuccess(state, action) {
          state.closedConversations = action.payload;
          state.isLoading = false;
        },

        selectedConversationId: (state, { payload }) => {
            state.conversationId = payload;
            console.log('selectedConversationId', payload)
        },
        selectedConversationIdName: (state, { payload }) => {
          state.conversationIdName = payload;
          console.log('selectedConversationIdName', payload)
      },

        // GET NUMBER SSUCCESS
        getConversationsIdSuccess(state, action) {
          state.conversationIdData = action.payload;
          state.isLoading = false;
        },

        setConversationsIdData(state, action) {
          state.conversationIdData = action.payload;
        },

        addChatSuccess(state, action) {
          state.addChatTextResponse = action.payload;
          state.isLoading = false;
        },

        setChatNotActive(state, action){
          state.chatNotActive = action.payload
        },

        setTemplateActive(state, action){
          state.templateActive = action.payload
        },

        setTemplateDialog(state, action){
          state.templateDialog = action.payload
        },

        getTemplateSuccess(state, action){
          state.templateData = action.payload
        },

        setSelectedTemplate(state, action){
          state.selectedTemplate = action.payload
        },

        setSearchConversation(state, action){
          state.searchCoversation = action.payload
        },

        setAgentDialog(state, action){
          state.agentDialog = action.payload
        },

        getAgentSuccess(state, action){
          state.agentData = action.payload
        },

        setSelectedAgent(state, action){
          state.selectedAgent = action.payload
        },

        setSelectedLabel(state, action) {
          state.selectedLabel = action.payload
        },

        setSettingsNav(state, action){
          state.settingsNav = action.payload
        },

        setSearchCustomers(state, action){
          state.searchCustomers = action.payload
        },

        getCustomerSuccess(state, action){
          state.customerData = action.payload
          state.isLoading = false;
        },

        getLabelSuccess(state, action) {
          state.labelData = action.payload
          state.isLoading = false;
        },

        setSelectedCustomer(state, action){
          state.selectedCustomer = action.payload
        },

        getMeSuccess(state, action){
          state.myData = action.payload
          state.isLoading = false;
        },

        setChatNav(state, action){
          state.chatNav = action.payload
        },

        setDirectTo(state, action){
          state.directTo = action.payload
        },

        setCreateCustomerDialog(state, action){
          state.createCustomerDialog = action.payload
        },

        createCustomersSuccess(state, action){
          state.createCustomerResponse = action.payload
        },

        setCreateTeamDialog(state, action){
          state.createTeamDialog = action.payload
        },

        createTeamSuccess(state, action){
          state.createTeamResponse = action.payload
        },

        getTeamSuccess(state, action){
          state.teamData = action.payload
          state.isLoading = false;
        },

        setSearchTeam(state, action){
          state.searchTeam = action.payload
        },

        setSelectedTeam(state, action){
          state.selectedTeam = action.payload
        },
        setEditTeamDialog(state, action){
          state.editTeamDialog = action.payload
        },
        editTeamSuccess(state, action){
          state.editTeamResponse = action.payload
        },



        
        

      
    }
})



export const selectWhatsapp = (state) => state.whatsapp;

export const { 
  selectedConversationId, 
  setEditLabel,
  selectedConversationIdName, 
  setChatNotActive,
  setTemplateActive,
  getCustomerSuccess,
  setTemplateDialog,
  setSelectedTemplate,
  setSearchConversation,
  setAgentDialog,
  setSelectedAgent,
  setSettingsNav,
  setSearchCustomers,
  setSelectedCustomer,
  setChatNav,
  setDirectTo,
  setShowLabelDialog,
  setConversationsIdData,
  setCreateCustomerDialog,
  getConversationsIdSuccess,
  setSelectedLabel,
  setShowCreateLabelDialog,
  stopLoading,
  setCreateTeamDialog,
  setSearchTeam,
  setSelectedTeam,
  setEditTeamDialog,
  setShowCustomerDetailDialog
} = whatsappSlice.actions


export default whatsappSlice.reducer



// Actions

export function getConversations(access_token, page) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/conversations/?page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getConversationsSuccess(response.data));

          console.log('getConversations Success response', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }

  export function getMyConversations(access_token, me, page) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/conversations/?me=${1}&page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getMyConversationsSuccess(response.data));

          console.log('getMyConversations Success response', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }

  export function getClosedConversations(access_token, page) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/conversations/?me=1&status=2&page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getClosedConversationsSuccess(response.data));

          console.log('getMyConversations Success response', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }



  

  export function getConversationsId(access_token, page, id) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/conversations/${id}/message?page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getConversationsIdSuccess(response.data));

          console.log('getConversationsIdSuccess response', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }

  export function addChatText(access_token, message, msgType, id) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization",  `JWT ${access_token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "fb_msg_type": msgType,
      "message": message,
      "conversations_id": id
    });
    


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
   
    return async () => {

      fetch(`${sobotUrl}/conversations/message/`, requestOptions)
      .then(response =>{
        console.log('whatsapp response', response)
        if(response.status === 400){
          dispatch(whatsappSlice.actions.setChatNotActive(true))
          dispatch(whatsappSlice.actions.setTemplateActive(true))
        }
        if(response.status === 200){
          dispatch(whatsappSlice.actions.addChatSuccess(response));
        }
      })
      .catch(error => console.log('error', error));


      
    };
  }

  export function getTemplates(access_token, page) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/templates/?status=1&page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getTemplateSuccess(response.data));

          console.log('getTemplates response', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }

    export function getAgents(access_token, page) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/user/agents/?page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getAgentSuccess(response.data));

          console.log('getAgents response', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }

  export function addChatTemplate(access_token, template, id, uuid) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization",  `JWT ${access_token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = ''
    if(template.example !== '' && template.example !== null && template.example !== undefined){

    raw = JSON.stringify({
      fb_msg_type: 'template',
      conversations_id: id,
      entity_name: template.entity_name,
      lang_code: "en",
      msg_id: uuid,
      parms: [
        {
            "type": "header",
            "parameters": [
                {
                    "type": "image",
                    "image": {
                        "link": template.example
      
                        }
                    }
                
            ]
        }
    ]

    });

  }else{
    raw = JSON.stringify({
      fb_msg_type: 'template',
      conversations_id: id,
      entity_name: template.entity_name,
      lang_code: "en",
      msg_id: uuid,
      
    });
  }
    


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
   
    return async () => {

      fetch(`${sobotUrl}/conversations/message/`, requestOptions)
      .then(response =>{
        console.log('whatsapp response', response)
        if(response.status === 400){
          dispatch(whatsappSlice.actions.setChatNotActive(true))
          dispatch(whatsappSlice.actions.setTemplateActive(true))
        }
        if(response.status === 200){
          dispatch(whatsappSlice.actions.addChatSuccess(response));
          dispatch(setTemplateDialog(false))
        }
      })
      .catch(error => console.log('error', error));


      
    };
  }




  export function getSearchedConversations(access_token, text, page) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/search/conversation?q=${text}&page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getConversationsSuccess(response.data));
          dispatch(whatsappSlice.actions.getMyConversationsSuccess(response.data));
          

          console.log('getConversations Success response', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }



  export function assignAgent(access_token, assigneeId, conversationId) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization",  `JWT ${access_token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      assignee_id: assigneeId,
      conversation_id: conversationId
    });
    


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
   
    return async () => {

      fetch(`${sobotUrl}/conversations/agent/`, requestOptions)
      .then(response =>{
        console.log('assign agent', response)
        if(response.status === 200){
          dispatch(showSnackbar('Agent Assigned Success'));
        }else{
          dispatch(showSnackbar('Failed to Assign Agent'));
        }
      })
      .catch(error => {
        dispatch(showSnackbar('Failed to Assign Agent'));
      });


      
    };
  }


  export function getCustomers(access_token, page) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/user/customer?page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getCustomerSuccess(response.data));
          console.log('getCustomers', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }

  export function getLabels(access_token, page) {
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/labels?page=${page}`, {
          headers: {
            'Authorization': `JWT ${access_token}`
          }
        });
        dispatch(whatsappSlice.actions.getLabelSuccess(response.data));
        
      } catch (err) {
        dispatch(whatsappSlice.actions.hasError(err));
      }
    }
  }

  export function deleteLabels(access_token, id) {
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.delete(`${sobotUrl}/labels/${id}`, {
          headers: {
            'Authorization': `JWT ${access_token}`
          }
        });
        if (response.data) {
          const get_response = await axios.get(`${sobotUrl}/labels?page=1`, {
              headers: {
                'Authorization': `JWT ${access_token}`
              }
          });
          dispatch(showSnackbar('Label is deleted successfully'));
          dispatch(whatsappSlice.actions.getLabelSuccess(get_response.data));
        }
      } catch (err) {
        dispatch(whatsappSlice.actions.hasError(err));
      }
    }
  }

  export function createLabel(access_token, body) {
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.post(`${sobotUrl}/labels/`, body, {
          headers: {
            'Authorization': `JWT ${access_token}`
          }
        });
        if (response.data) {
          const get_response = await axios.get(`${sobotUrl}/labels?page=1`, {
              headers: {
                'Authorization': `JWT ${access_token}`
              }
          });

          dispatch(showSnackbar('Label is created successfully'));
          dispatch(whatsappSlice.actions.stopLoading());
          dispatch(setShowCreateLabelDialog(false))
          dispatch(setEditLabel(false))
          dispatch(whatsappSlice.actions.getLabelSuccess(get_response.data));
        }
      } catch (err) {
        dispatch(showSnackbar('Something went wrong'));
        dispatch(whatsappSlice.actions.stopLoading());
        dispatch(whatsappSlice.actions.hasError(err));
      }
    }
  }


  export function editLabels(access_token, id, body) {
    return async() => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.put(`${sobotUrl}/labels/${id}/`, body, {
          headers: {
            'Authorization': `JWT ${access_token}`
          }
        });

        if (response.data) {
          const get_response = await axios.get(`${sobotUrl}/labels?page=1`, {
              headers: {
                'Authorization': `JWT ${access_token}`
              }
          });

          dispatch(showSnackbar('Label is edited successfully'));
          dispatch(whatsappSlice.actions.stopLoading());
          dispatch(setShowCreateLabelDialog(false))
          dispatch(setEditLabel(false))
          dispatch(whatsappSlice.actions.getLabelSuccess(get_response.data));
        }
      } catch (err) {
        dispatch(showSnackbar('Something went wrong'));
        dispatch(whatsappSlice.actions.stopLoading());
        dispatch(whatsappSlice.actions.hasError(err));
      }
    }
  }

  export function filterLabels(access_token, query) {
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        var response;
        if (query !== null && query !== "" && query !== undefined) {
          response = await axios.get(`${sobotUrl}/search/label?q=${query}&page=1`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
        } else {
          response = await axios.get(`${sobotUrl}/labels?page=1`, {
              headers: {
                'Authorization': `JWT ${access_token}`
              }
          });
        }
        dispatch(whatsappSlice.actions.stopLoading());
        dispatch(whatsappSlice.actions.getLabelSuccess(response.data));
      } catch (err) {
        dispatch(showSnackbar('Something went wrong'));
        dispatch(whatsappSlice.actions.stopLoading());
        dispatch(whatsappSlice.actions.hasError(err));
      }
    }
  }


  export function getSearchedCustomers(access_token, text, page) {
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/search/customer?q=${text}&page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getCustomerSuccess(response.data));
          console.log('getCustomers search', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }


  export function getMe(access_token) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/user/me/`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          console.log('getMeSuccess', response.data)
          dispatch(whatsappSlice.actions.getMeSuccess(response.data));
          // localStorage.setItem('whatsAppUser',)
          // localStorage.setItem('whatsAppUserName',)
          // localStorage.setItem('whatsAppBusiness',)

          
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }


  export function createCustomer(access_token, mobile, name) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization",  `JWT ${access_token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobile: mobile,
      name: name
    });
    


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
   
    return async () => {

      fetch(`${sobotUrl}/user/customer/`, requestOptions)
      .then(response =>{
        console.log('createCustomer', response)
        if(response.ok === true){
          dispatch(showSnackbar('Customer Created'));
          dispatch(getCustomers(access_token, 1))
          dispatch(setCreateCustomerDialog(false))
        }else
        if(response.ok === false){
          if(response.status === 400){
            dispatch(showSnackbar('Customer already exists'));
          }
        }
      })
      .catch(error => {
        dispatch(showSnackbar(response.message));
      });


      
    };
  }

  export function editCustomer(access_token, id, body) {
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.put(`${sobotUrl}/user/customer/${id}/`, body, {
          headers: {
            'Authorization': `JWT ${access_token}`
          }
        });
        if (response.data) {
          const fetch_customer = await axios.get(`${sobotUrl}/user/customer?page=1`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getCustomerSuccess(fetch_customer.data));
          dispatch(setShowCustomerDetailDialog(false));
          dispatch(showSnackbar('Customer details updated successfully'));
          dispatch(whatsappSlice.actions.stopLoading());
        }
      } catch (error) {
        dispatch(showSnackbar('Something went wrong'));
        dispatch(whatsappSlice.actions.stopLoading());
        dispatch(whatsappSlice.actions.hasError(error));
      }
    }
  }



  export function getTeam(access_token, page) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/user/agents/?page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getTeamSuccess(response.data));
          console.log('getCustomers', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }

  export function createTeam(access_token, name, phone, email) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization",  `JWT ${access_token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      mobile: phone,
      email: email
    });
    


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
   
    return async () => {

      fetch(`${sobotUrl}/user/agents/`, requestOptions)
      .then(response =>{
        console.log('team', response)
        if(response.ok === true){
          dispatch(showSnackbar('Team Member Created'));
          dispatch(getTeam(access_token, 1))
          dispatch(setCreateTeamDialog(false))
        }else
        if(response.ok === false){
          if(response.status === 400){
            dispatch(showSnackbar('Member already exists'));
          }
        }
      })
      .catch(error => {
        dispatch(showSnackbar(response.message));
      });


      
    };
  }

  


  export function getSearchedTeam(access_token, text, page) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`${sobotUrl}/search/team-member?q=${text}&page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getTeamSuccess(response.data));
          console.log('get team search', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }


  export function editTeam(access_token, id, name, phone, email) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization",  `JWT ${access_token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      mobile: phone,
      email: email
    });
    


    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
   
    return async () => {

      fetch(`${sobotUrl}/user/agents/${id}/`, requestOptions)
      .then(response =>{
        console.log('team', response)
        if(response.ok === true){
          dispatch(showSnackbar('Team Member Updated'));
          dispatch(getTeam(access_token, 1))
          dispatch(setEditTeamDialog(false))
        }else
        if(response.ok === false){
          if(response.status === 400){
            dispatch(showSnackbar(response.message));
          }
        }
      })
      .catch(error => {
        dispatch(showSnackbar(response.message));
      });


      
    };
  }