import { Button, IconButton, Menu, MenuItem, Snackbar, TextField, Dialog, DialogTitle, Box, DialogContent, Grid, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import React, {useState, useEffect} from "react"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import "../../../Tags/style.css";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { edit_app_config } from "src/redux/userReducer";
import Iconify from "src/components/Iconify";
export const Ticket_Stages = ({ props, setProps }) => {
    const [stages, setStages] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null)
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [disabled, setDisabled] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [tagColor, setTagColor] = useState("")
    const [index, setIndex] = useState(0)
    useEffect(() => {
        if (props.stages === undefined || props.stages === null) {
            var app_details = props;
            app_details.stages = [];
            setProps(app_details)
        } else {
            setStages(props.stages)
        }
    }, [])
    const add_stages = () => {
        setStages([...stages, {name: "", color: "", edit: true }])
    }

    const delete_stages = (index) => {
        const formData = [...stages];
        formData.splice(index, 1);
        setStages(formData)
    }

    const edit_app = (body) => {
        setDisabled(true)
        const token = localStorage.getItem("redux-token");
        const config_id = props._id;
        edit_app_config(config_id, body, token).then((res) => {
            setErrorMessage("Ticket config is updated successfully");
            setShowError(true)
            setDisabled(false)
        }).catch((err) => {
            console.log("error", err);
            setErrorMessage("Ticket config is not updated");
            setShowError(true)
            setDisabled(false)
        })
    }

    const edit_stages = (index, value, input_type) => {
        const formData = [...stages];
        formData[index][input_type] = value;
        setStages(formData)
    }

    const colorList = [
        "#DEB3AD",
        "#DE847B",
        "#B95C50",
        "#D3B1C2",
        "#C197D2",
        "#E1C16E",
        "#FFFF00",
        "#F86F15",
        "#A2C4E0",
        "#52688F",
        "#39918C",
        "#D4F1F4",
        "#A3EBB1",
    ];


    return (
        <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
            {/* {stages.map((item, index) => (
                <div key={index} style={{ width: "95%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", alignSelf: "center", backgroundColor: "#E8E8E8", height: 45, marginTop: 10, borderRadius: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: 10, alignItems: "center"}}>
                        {item.edit === true ? (
                            <TextField
                            placeholder="Name"
                            id="outlined-size-small"
                            sx={{ width: "50%", height: 35, display: "flex", alignSelf: "center",}}
                            value={item.name}
                            onChange={(e) => edit_stages(index, e.target.value, "name")} 
                            size="small"
                            />
                        ): (
                            <div style={{ height: 35, width: 120, display: "flex", alignSelf: "center", alignItems: "center", justifyContent: "center"  }}>
                                <div style={{ color: "#000", fontWeight: "600", fontSize: 14, }}>
                                    {item.name}
                                </div>
                            </div>
                        )}
                        {item.edit === true ? (
                            <Button style={{ width: 140, height: 35, borderRadius: 10, border: "1px solid grey", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", marginLeft: 10 }} onClick={(event) => {
                                setTagColor(item.color)
                                setOpenDialog(true);
                                setIndex(index)
                            }}>
                            <div style={{ marginLeft: 2, }}>
                                {item.color === "" ? (
                                    <div style={{ color: "grey", fontWeight: "700", fontSize: 15, }}>
                                        Select Color
                                    </div>
                                ): (
                                    <div style={{ width: 60, height: 30, borderRadius: 10, backgroundColor: item.color }}/>
                                )}
                            </div>
                            <ArrowDropDownIcon style={{ color: "grey", marginRight: 2,  }}/>
                            </Button>
                        ): (
                            <div style={{ width: 100, height: 30, backgroundColor: item.color, borderRadius: 10, marginLeft: 10 }}>

                            </div>
                        )}
                        
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 2,}}>
                        {item.edit === true ? (
                            <IconButton variant="contained" color="primary" onClick={() => edit_stages(index, false, "edit")}>
                                <CheckCircleIcon />
                            </IconButton>
                        ): (
                            <IconButton variant="contained" color="primary" onClick={() => edit_stages(index, true, "edit")}>
                                <EditOutlinedIcon />
                            </IconButton>
                        )}
                        <IconButton variant="contained" color="primary" onClick={() => {
                            delete_stages(index)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            ))}
            <Button style={{ height: 40, borderRadius: 10, width: 100, display: "flex", marginTop: 10, flexDirection: "row", alignItems: "center", marginLeft: 10  }} onClick={() => add_stages()}>
              <div style={{ width: 20, height: 20, borderRadius: 20, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <AddIcon style={{ width: 15, height: 15, color: "#fff"}}/>
              </div>
              <div style={{ marginLeft: 10, color: "#0046BB", fontSize: 15, fontWeight: "700", marginRight: 10}}>
                Add
              </div>
            </Button>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end", flexDirection: "row", marginBottom: 50 }}>
                <Button style={{ marginRight: 20, height: 40, borderRadius: 10, backgroundColor: "#fff", }} variant="outlined" size='small'>
                    <div style={{ color: "#0046BB", marginLeft: 10, marginRight: 10, fontWeight: "600"}}>
                        Cancel
                    </div>
                </Button>
                <Button style={{ marginRight: 20, height: 40, borderRadius: 10, }} variant="contained" size='small' onClick={() => {
                    var body = {
                        "stages": stages
                    }
                    edit_app(body)
                }} disabled={disabled}>
                    <div style={{ color: "#fff", marginLeft: 10, marginRight: 10, fontWeight: "600"}}>
                        Save Changes
                    </div>
                </Button>
            </div>
            <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{ display: "flex", flexDirection: "row", alignItens: "center" ,justifyContent: "space-around" }}>
                        <h3 style={{ flexGrow: 1 }}>Stages Color</h3>
                        <IconButton onClick={() => setOpenDialog(false)}>
                            <Iconify icon="eva:close-fill" />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box style={{ padding: "20px 0 0 0" }}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>
                                <RadioGroup
                                defaultValue="female"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={tagColor}
                                onChange={(event) => {
                                    setTagColor(event.target.value);
                                    edit_stages(index, event.target.value, "color");
                                    setOpenDialog(false)
                                }}>
                                    <Grid container direction="row" spacing={1}>
                                        {colorList.map((item, index) => (
                                            <Grid item xs={3} key={index}>
                                                <FormControlLabel
                                                value={item}
                                                className={tagColor === item ? "tag-select-item active" : "tag-select-item"}
                                                style={{ background: `${item}` }}
                                                control={<Radio style={{ display: "none" }} />}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog> */}
            <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        message={errorMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => setShowError(false)}>
              OK
            </Button>

          </React.Fragment>
        }
      />
        </div>
    )
}