import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

import {getuserRolesID} from './profile/role'

// ----------------------------------------------------------------------


const initialState= {
    isLoading: false,
    error: null,
    getAuthData: {},
    getUserData: {},
}

const getAuthSlice = createSlice({
    name: 'getAuth',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        
        // GET USER DATA SSUCCESS
        getAuthSuccess(state, action) {
          state.getAuthData = action.payload;
          state.isLoading = false;
          console.log('redux user auth data', state.getAuthData)
        },

        // GET USER DATA SSUCCESS
        getUserSuccess(state, action) {
          state.getUserData = action.payload;
          state.isLoading = false;
          console.log('redux user data', state.getUserData)
        },

       

    }
})

export const selectgetAuth = (state) => state.userAuthData.value;

export default getAuthSlice.reducer

// Actions

export function getAuth() {
    return async () => {
      dispatch(getAuthSlice.actions.startLoading());
      try {
        const response = await axios.get(`/getAuth`);
          console.log('getAuth redux', response)
          dispatch(getAuthSlice.actions.getAuthSuccess(response.data));
          if(response.data.data.is_admin){

          }else{
            if(response.data.data.role !== null && response.data.data.role !== undefined){
              dispatch(getuserRolesID(response.data.data.role))
            }
            
          }
          
      } catch (error) {
        dispatch(getAuthSlice.actions.hasError(error));
      }
    };
  }


  export function getUser(id) {
    const User_Data = localStorage.getItem("user_data");
    const json_parsed_user_data = JSON.parse(User_Data);
    return async () => {
      dispatch(getAuthSlice.actions.startLoading());
      try {
        const response = await axios.get(`/users/${id}`);
          dispatch(getAuthSlice.actions.getUserSuccess(response.data));
          const user_id = response.data.data[0]._id;
          const response_data = response.data.data[0];
          if (User_Data !== null && User_Data !== undefined !== "" && User_Data !== "undefined") {
            if (user_id === json_parsed_user_data._id) {
              console.log("user_id", user_id, response_data);
              localStorage.setItem("user_data", JSON.stringify(response_data));
            }
          } else {
            localStorage.setItem("user_data", JSON.stringify(response_data));
          }
      } catch (error) {
        dispatch(getAuthSlice.actions.hasError(error));
      }
    };
  }

  export function logoutUser(email) {
    return async () => {
      dispatch(getAuthSlice.actions.startLoading());
      try {
        const data = {
          "email": email
        }
        await axios.post(`/logout` , data);
      } catch (error) {
        dispatch(getAuthSlice.actions.hasError(error));
      }
    };
  }


  