export const SET_TICKET = "SET_TICKET";
export const SET_TOTAL_TICKETS = "SET_TOTAL_TICKETS";
export const SET_OPEN_TICKETS = "SET_OPEN_TICKETS";
export const SET_CLOSED_TICKETS = "SET_CLOSED_TICKETS";
export const SET_OPEN_DRAWER = "SET_OPEN_DRAWER";
export const SET_IN_PROGRESS_TICKETS = "SET_IN_PROGRESS_TICKETS";
export const SET_IS_LOADING = "SET_IS_LOADING";
import { createSlice } from '@reduxjs/toolkit';
import {dispatch} from "./store"
import { useSelector } from 'react-redux';
import axios, { baseUrl } from 'src/utils/axios';
const initialState = {
    tickets: [],
    total_tickets: 0,
    open_tickets: 0,
    closed_tickets: 0,
    inprogress_tickets: 0,
    isLoading: false,
    open_drawer: false
}


export function Ticket(state = initialState, action) {
    switch (action.type) {
        case SET_TICKET:
            return {...state, tickets: action.payload };
            
        case SET_TOTAL_TICKETS:
            return { ...state, total_tickets: action.payload }

        case SET_OPEN_TICKETS:
            return { ...state, open_tickets: action.payload }

        case SET_CLOSED_TICKETS:
            return { ...state, closed_tickets: action.payload }

        case SET_IN_PROGRESS_TICKETS:
            return { ...state, inprogress_tickets: action.payload }

        case SET_IS_LOADING:
            return { ...state, isLoading: action.payload }

        case SET_OPEN_DRAWER:
            return { ...state, open_drawer: action.payload }
        default:
            return state;
    }
}

export function getAllTicket(limit, offset, authId) {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await axios.get(`/ticket-list?AccountSid=${authId}&limit=${limit}&offset=${offset}&sort=-_id&populate=created_by&populate=user_id`);
            resolve(response);
        } catch (err) {
            reject(err);
        }
    })
}


export function getFilterTicket(limit, offset, authId, status, startDate, endDate, assignee, custom_variable) {
    console.log('custom_variable', custom_variable)
    var url = `/ticket-list?AccountSid=${authId}&limit=${limit}&offset=${offset}&sort=-_id&populate=created_by&populate=user_id&status=${status}&startDate=${startDate}&endDate=${endDate}&user_id=${assignee}`;
    if (custom_variable !== null && custom_variable !== undefined) {
        if (Object.keys(custom_variable).length > 0) {
            url = `/ticket-list?AccountSid=${authId}&limit=${limit}&offset=${offset}&sort=-_id&populate=created_by&populate=user_id&status=${status}&startDate=${startDate}&endDate=${endDate}&user_id=${assignee}&customVariable=${JSON.stringify(custom_variable)}`
        }
    }
    return new Promise(async(resolve, reject) => {
        try {
            const response = await axios.get(url);
            resolve(response);
        } catch (err) {
            reject(err);
        }
    })
}

export function getAllTicketContactId(limit, offset, authId, contactId) {

    console.log('contacts ticket redux', contactId)

    return new Promise(async(resolve, reject) => {
        try {
            const response = await axios.get(`ticket-list?AccountSid=${authId}&limit=${limit}&offset=${offset}&sort=-_id&populate=created_by&populate=user_id&created_by=${contactId} `);
            resolve(response);
        } catch (err) {
            reject(err);
        }
    })
}