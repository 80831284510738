import React,  {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import UpdateIcon from '@mui/icons-material/Update';
import { useNavigate } from 'react-router';
import { 
    Box,
    Fab,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    ListItemSecondaryAction,
    Typography,
    Grid,
    Card,
    IconButton,
    ButtonGroup,
    Button,
    LinearProgress,
    Alert,
 } from '@mui/material';
 import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
 import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
 import CallMadeIcon from '@mui/icons-material/CallMade';
 import CallReceivedIcon from '@mui/icons-material/CallReceived';
 import axiosInstance, { REACT_APP_BASE_URL } from '../../utils/axios';

 import moment from 'moment';
 import { useSelector , useDispatch } from 'react-redux';
 import { setPhoneNumber } from '../../redux/slices/cloudPhone/phoneNumber';
 import { setFromNumber } from '../../redux/slices/cloudPhone/setFromNumber';
 import {incrementMakeCallSwitch} from '../../redux/slices/cloudPhone/makeCall'; 
import axios from 'axios';
import { SET_CALL_HISTORY, SET_CALL_OFFSET, SET_MISSED_CALL, SET_MISSED_OFFSET, SET_SHOW_ALL_LIST } from 'src/redux/userReducer';
import CallDetails from '../InboxVoice/components/CallDetails';
import { createSearchParams } from 'react-router-dom';
import Iconify from '../Iconify';
import Notes from '../InboxVoice/components/Notes';

import { setCallDetailsDrawer, getFlowById } from 'src/redux/slices/Inbox/voice';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Conversations({ setWidjetMainToggle, setMinimizeMaximize, scrollRef, minimizeMaximize }) {

  const navigate = useNavigate()
  const [notes_data, setNotesData] = useState("");
  const [open_notes, setOpenNotes] = useState(false)
  const [call_detail, setCallDetails] = useState("")

  const dispatch = useDispatch();
  const collectPhoneNumber = (item) => {
   const phone = item?.ContactNumber.replace("+", "")
   dispatch(incrementMakeCallSwitch())
     dispatch(setPhoneNumber(phone))
     dispatch(setFromNumber(item?.CloudNumber))
  }


  const {callHistory, missed_call, show_all_list, call_offset, missed_offset, app} = useSelector((state) => state.user)

  const [callHistoryState, setCallHistoryState] = useState([])

  useEffect(()=>{
    if(callHistory !== null && callHistory !== undefined){
      setCallHistoryState(callHistory)
    }
  },[callHistory])


  const [load_more_all, setLoadMoreAll] = useState(false);
  const [load_more_missed, setLoadMoreMissed] = useState(false)
  const [load_all_call, setLoadAllCall] = useState(true)
  const [load_missed, setLoadMissed] = useState(true)

  useEffect(() => {
    fetchDataForConversation()
    fetchMissedCalls()
  }, [minimizeMaximize])


  const fetchDataForConversation = () => {
    setLoadAllCall(true)

      axios({
        method: "GET",
        url: `${REACT_APP_BASE_URL}/api/voice?limit=10&fields=ParentCall&fields=contactId&fields=CallStatus&populate=contactId&fields=ContactNumber&fields=Notes&fields=createdAt&fields=CloudNumber&offset=0&sort=-_id`,
        headers: {
            authorization: localStorage.getItem('redux-token')
        }
    }).then((res) => {
      console.log('history response missed', res)
        const response = res.data.data.data;
        const filtered_calls = response.filter(value => { return value.ContactNumber !== undefined });
        dispatch({
          type: SET_CALL_HISTORY,
          payload: filtered_calls
        })
        dispatch({
          type: SET_CALL_OFFSET,
          payload: 0
        })
        setLoadAllCall(false)
    }).catch((err) => {
        setLoadAllCall(false)

    })
  
  }

  const fetchMissedCalls = () =>{
    setLoadMissed(true)
    axios({
        method: "GET",
        url: `${REACT_APP_BASE_URL}/api/voice?limit=10&fields=ParentCall&fields=contactId&fields=CallStatus&populate=contactId&fields=ContactNumber&fields=Notes&fields=createdAt&fields=CloudNumber&offset=0&sort=-_id&CallStatus=no-answer&CallStatus=failed&&CallStatus=cancelled`,
        headers: {
            authorization: localStorage.getItem('redux-token')
        }
    }).then((missed) => {
        console.log('history response missed', missed)
        const missed_response = missed.data.data.data;
        const filtered_missed_call = missed_response.filter(item => { return item.ContactNumber !== undefined });
        dispatch({
          type: SET_MISSED_CALL,
          payload: filtered_missed_call
        })
        dispatch({
          type: SET_MISSED_OFFSET,
          payload: 0
        })
        setLoadMissed(false)
    }).catch((err) => {
        setLoadMissed(false)
    })
  }

  const open_note = (item) => {
    const row_data = {
      "row": {
        "_id": item._id,
        "Notes": item.Notes === null ? [] : item.Notes,
        "ParentCallSid" : item.ParentCall.ParentCallSid
      }
    }
    setNotesData(row_data);
    setOpenNotes(true)
    setWidjetMainToggle(false)
    setMinimizeMaximize(false)
  }

  const load_call_history = () => {
    console.log('array push merge load all')
    const Offset = parseInt(call_offset) + 10;
    setLoadMoreAll(true);
    axios({
      method: "GET",
      url: `${REACT_APP_BASE_URL}/api/voice?limit=10&fields=ParentCall&fields=contactId&fields=CallStatus&populate=contactId&fields=ContactNumber&fields=Notes&fields=createdAt&fields=CloudNumber&offset=${Offset}&sort=-_id`,
      headers: {
          authorization: localStorage.getItem('redux-token')
      }
    }).then((res) => {
      const response = res.data.data.data;
      const filtered_calls = response.filter(value => { return value.ContactNumber !== undefined });
      const previous_data = callHistoryState;
      Array.prototype.push.apply(previous_data, filtered_calls);
      dispatch({
        type: SET_CALL_HISTORY,
        payload: previous_data
      })
      setLoadMoreAll(false);
      dispatch({
        type: SET_CALL_OFFSET,
        payload: Offset
      })
    }).catch((err) => {
      setLoadMoreAll(false)
    })
  }

  const load_more_missed_call = () => {
    const Offset = parseInt(missed_offset) + 10;
    setLoadMoreMissed(true);
    axios({
      method: "GET",
      url: `${REACT_APP_BASE_URL}/api/voice?limit=10&fields=ParentCall&fields=contactId&fields=CallStatus&populate=contactId&fields=ContactNumber&fields=Notes&fields=createdAt&fields=CloudNumber&offset=${Offset}&sort=-_id&CallStatus=no-answer`,
      headers: {
        authorization: localStorage.getItem('redux-token')
      }
    }).then((res) => {
      const response = res.data.data.data;
      const filtered_calls = response.filter(value => { return value.ContactNumber !== undefined });
      const previous_data = missed_call;
      Array.prototype.push.apply(previous_data, filtered_calls);
      dispatch({
        type: SET_MISSED_CALL,
        payload: previous_data
      })
      setLoadMoreMissed(false);
      dispatch({
        type: SET_MISSED_OFFSET,
        payload: Offset
      })
    }).catch((err) => {
      setLoadMoreMissed(false);
    })
  }


  const open_call_details = (item) => {
    dispatch(getFlowById(item._id))
    dispatch(setCallDetailsDrawer(true))
    setCallDetails(row_data);
    setWidjetMainToggle(true)
    setMinimizeMaximize(true)
  }


  function listData(data){
    const dataList = data;
    return(
      <>
         <List style={{width: '100%'}}>

{dataList.map((item, index) => {
  return(
    
  <ListItem divider key={index}>
  <ListItemAvatar>
 
    <Avatar
      alt={item.contactId !== null ? item.contactId.firstName : 'Unknown'}
      src={item.contactId !== null ? `${REACT_APP_BASE_URL}/api/contact/download?fileLocation=${item.contactId.photo}` : ''}
      sx={{ width: 40, height: 40 }}
      style={{border: item.CallStatus === "completed" ? "2px solid #55d6a3" : "2px solid #ff6584"}}
    />

  </ListItemAvatar>
  <ListItemText>
    <Typography
      variant="body2"
      color="text.primary"
      onClick={() => {
        if (app[0].disable_contact === undefined || app[0].disable_contact === null || app[0].disable_contact === false) {
          const contactNumber = item.ContactNumber.replace('+', '')
          const paramsData= { phone: contactNumber.slice(-10)}
          navigate({
            pathname: '/dashboard/contacts/manage/account',
            search: `?${createSearchParams(paramsData)}`,
          });
        }
      }}
      >
          {item.contactId !== null && item.contactId !== undefined && item.contactId !== "" ? (
            <>
            {item.contactId.firstName} {item.contactId.lastName}
            </>
          ) : 'Unknown'}

          <div style={{ width: 18, height: 18, textAlign: 'center', lineHeight: '18px',  backgroundColor: "#088DD8", marginLeft: 5, borderRadius: 10, display: "inline-block", }}>
            {item.ParentCall.Direction === "inbound" ? (
              <CallReceivedIcon style={{ color: "#fff", width: 10, height: 10, alignSelf: "center" }}/>
            ): (
              <CallMadeIcon style={{ color: "#fff", width: 10, height: 10, alignSelf: "center" }}/>
            )}
          </div>

      </Typography>
      <Typography
      variant="caption"
      color="text.primary">
          {app[0].hide_contact === undefined || app[0].hide_contact === null || app[0].hide_contact === false? (
            <div style={{ fontSize: 12, }}>
              {item.ContactNumber}
            </div>
          ): (
            <div style={{ fontSize: 12, }}>
               XXXXXXXX{item.ContactNumber.slice(-3)}
            </div>
          )}
      </Typography>
  </ListItemText>
  <ListItemSecondaryAction>
  <div style={{ display: "flex", flexDirection: "row", height: "100%", alignItems: "center", marginRight: 5,}}>
  <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "flex-end"}}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
      
      <IconButton size="small" color="primary"  onClick={() => collectPhoneNumber(item)}>
          <PhoneOutlinedIcon style={{ width: 18, height: 18}}/>
      </IconButton>

      {item.Notes !== null && item.Notes !== undefined && item.Notes.length > 0 ? (
        
          <IconButton size='small' color='primary' onClick={() => open_note(item)}>
            <Iconify icon='uil:clipboard-notes'  />
          </IconButton>
        
        ) : (
          
            <IconButton size='small' onClick={() => open_note(item)}>
              <Iconify icon='uil:clipboard-notes'  />
            </IconButton>
        
        )}

      <Button style={{ minWidth: 20, width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center",  borderRadius: 20 }} onClick={() => {
    open_call_details(item)
  }}>
    <InfoOutlinedIcon style={{ color: "#0046BB", width: 20, height: 20 }} />
  </Button>
  
      </div>
      {moment().diff(moment(item.createdAt), "days") < 1 ? (
      <>
 {moment().diff(moment(item.createdAt), "hours") < 1 ? (
   <>
     {moment().diff(moment(item.createdAt), "minutes") < 1 ? (
       <Typography variant="caption" className="grey-text">
         Few seconds ago
       </Typography>
     ): (
       <Typography variant="caption" className="grey-text">
         {moment().diff(moment(item.createdAt), "minutes")} minutes ago
       </Typography>
     )}
   </>
 ): (
   <Typography variant="caption" className="grey-text">
     {moment().diff(moment(item.createdAt), "hours")} hours ago
   </Typography>
 )}
</>
): (
<Typography variant="caption" className="grey-text">
 {moment().diff(moment(item.createdAt), "days")} days ago
</Typography>
)}
  </div>
  </div>
  </ListItemSecondaryAction>
  </ListItem>
   
  )
}
)}
</List>
      </>
    )
  }

  return (
    <div>
      <div style={{ display: "flex", width: "100%"}}>
      <ButtonGroup className="conversation-btn-group" aria-label="small outlined button group">
        <Button style={{ width: "40%"}} className={show_all_list === true ? "tab-active" : ""} onClick={() => {
          
          dispatch({
            type: SET_SHOW_ALL_LIST,
            payload: true
          })

        }}>All</Button >
        <Button style={{ width: "40%"}} className={show_all_list === false ? "tab-active" : ""} onClick={() => {
         dispatch({
          type: SET_SHOW_ALL_LIST,
          payload: false
        })
  
        }}>Missed</Button>
      </ButtonGroup>
     
      </div>
      <div ref={scrollRef}/>

      <div style={{ minHeight: "330px", maxHeight: "330px", overflowY: "auto", display: "flex", alignItems: "center", flexDirection: "column", padding: 5, }} className="center-col">
      {show_all_list === true ?  (
              <>
                {load_all_call === true ? (
                  <Card style={{padding: '20px', textAlign: 'center', minWidth: '300px', minHeight: '80px'}}>
                  <>
                    Fetching Data..
                    <LinearProgress />
                    </>
                </Card>
                ): (
                  <>
                  {callHistoryState.length === 0 ? (
                    <Grid container direction="row" spacing={3} style={{textAlign: "center", marginTop: "0px", marginBottom: "30px"}}>
                    <Grid item xs={12}>
                        {/* <img src={require('./../../assets/no-call-history.png')} style={{maxWidth: "600px", width: "100%"}} /> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Alert severity="warning" style={{width: '100%'}}>
                        You Don't have a Call History
                      </Alert>
                        
                    </Grid>
                    
                   </Grid>
                  ): (
                    <>

                    {listData(callHistoryState)}
                   

                     

                      {callHistoryState.length > 0 && <>
                         {load_more_all === false ? (
                           <Button style={{ marginBottom: 10, display: "flex", alignSelf: "center" }} onClick={() => load_call_history()}>
                           <div style={{ fontWeight: "bold"}}>
                              Load More
                           </div>
                           </Button>
                         ): (
                          <Card style={{padding: '20px', textAlign: 'center', minWidth: '300px', marginBottom: '50px', height: '100px', minHeight: '80px'}}>
                              <>
                              Fetching Data..
                              <LinearProgress />
                              </>
                          </Card>
                         )}
                      </>}
                    </>
                  )}
                </>
                )}
              </>
            ): (
               <>
                 {load_missed === true ? (
                  <Card style={{padding: '20px', textAlign: 'center', minWidth: '300px', minHeight: '80px'}}>
                  <>
                    Fetching Data..
                    <LinearProgress />
                    </>
                </Card>
                 ): (
                  <>
                 {missed_call.length === 0 ? (
                    <Grid container direction="row" spacing={3} style={{textAlign: "center", marginTop: "0px", marginBottom: "30px"}}>
                    <Grid item xs={12}>
                        {/* <img src={require('./../../assets/no-call-history.png')} style={{maxWidth: "600px", width: "100%"}} /> */}
                    </Grid>
                    <Grid item xs={12}>

                      <Alert severity="warning" style={{width: '100%'}}>
                        You Don't have a Call History
                      </Alert>
                       
                    </Grid>
                    
                   </Grid>
                  ): (
                    <>

                 


                  {listData(missed_call)}


                      {missed_call.length > 0 && <>
                         {load_more_missed === false ? (
                          <>
                             
                          <Button style={{ marginBottom: 10, display: "flex", alignSelf: "center" }} onClick={() => load_more_missed_call()}>
                            <div style={{ fontWeight: "bold"}}>
                               Load More
                            </div>
                          </Button>
                        </>
                         ): (
                          <Card style={{padding: '20px', textAlign: 'center', minWidth: '300px', minHeight: '80px'}}>
                            <>
                            Fetching Data..
                            <LinearProgress />
                            </>
                        </Card>
                         )}
                      </>}
                    </>
                  )}
               </>
                 )}
               </>
            )}
      </div>
      <CallDetails props={call_detail} />
      {open_notes === true && notes_data !== "" && <Notes props={notes_data} open_notes={open_notes} setOpenNotes={setOpenNotes}/>}
    </div>
  );
}